import React, { ReactElement, ReactNode } from "react";
import { View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { DEFAULTBODYPROPS, DEFAULTCAPTIONPROPS, DEFAULTFOOTERPROPS, DEFAULTHEADERPROPS, TableFrameStyles } from "./constants";
import { CaptionAlignEnum, FrameEnum } from "./enums";

import { Body, BodyProps } from "./Body";
import { Caption, CaptionProps } from "./Caption";
import { Footer, FooterProps } from "./Footer";
import { Header, HeaderProps } from "./Header";
import { RowProps, ZebraProps } from "./Row";

import { mergeStyles, setDefaultProps, ViewProps } from "../utils";

export interface TableProps extends ViewProps, ZebraProps,
  Pick<RowProps, 'borderColor' | 'borderStyle' | 'borderWidth' | 'cellPadding' | 'cols' | 'data' | 'rules'> {
  frame?: FrameEnum;
  repeatHeader?: boolean;
  children: ReactNode;
}

function getTableChildProps(props: any) {
  const childProps = { ...props };

  delete childProps.style;

  return childProps;
}

function setTableChildren(props: any) {
  const children = {
    body: null,
    caption: null,
    footer: null,
    header: null
  };
  const childProps = getTableChildProps(props);

  React.Children.forEach(props.children, (child: ReactElement) => {
    const { type } = child;

    if (type === Body) {
      children.body = child;
    }

    if (type === Caption) {
      const captionProps: CaptionProps = { ...childProps, ...child.props };

      children.caption = React.cloneElement(child,
        setDefaultProps({ defaultProps: DEFAULTCAPTIONPROPS, element: child, props: captionProps })
      );
    }

    if (type === Footer) {
      const footerProps: FooterProps = { ...childProps, ...child.props };

      children.footer = React.cloneElement(child,
        setDefaultProps({ defaultProps: DEFAULTFOOTERPROPS, element: child, props: footerProps })
      );
    }

    if (type === Header) {
      const { repeatHeader } = childProps;

      delete childProps.repeatHeader;
      const headerProps: HeaderProps = { ...childProps, ...child.props, repeat: !!repeatHeader };

      children.header = React.cloneElement(child,
        setDefaultProps({ defaultProps: DEFAULTHEADERPROPS, element: child, props: headerProps })
      );
    }
  });

  if (!children.body) {
    children.body = React.createElement(Body);
  }

  const bodyProps: BodyProps = { ...childProps, ...children.body.props };

  children.body = React.cloneElement(children.body,
    setDefaultProps({ defaultProps: DEFAULTBODYPROPS, element: children.body, props: bodyProps })
  );

  return children;
}

class Table extends React.PureComponent<TableProps> {
  render() {
    const { props } = this;
    const { body, caption, footer, header } = setTableChildren(props);
    const tableStyle: Style = {
      borderColor: props.borderColor ?? 'currentColor',
      borderStyle: props.borderStyle ?? 'solid',
      borderWidth: props.borderWidth ?? 0,
      ...TableFrameStyles[props.frame ?? FrameEnum.NONE]
    };

    return (
      <View break={props.break} debug={props.debug} style={mergeStyles(tableStyle, props.style)} wrap={props.wrap}>
        {caption?.props.align === CaptionAlignEnum.TOP ? caption : null}
        {header}
        {body}
        {footer}
        {caption?.props.align === CaptionAlignEnum.BOTTOM ? caption : null}
      </View>
    );
  }
}

export default Table;
export { Table };
