import React, { ReactNode } from "react";

import { LocationEnum } from "./enums";

import { DataCellProps } from "./DataCell";
import { Row, RowProps, ZebraProps } from './Row';

export interface BodyProps extends Omit<RowProps, 'data'>, ZebraProps {
  data?: Array<Pick<DataCellProps, 'data'>>;
  children: ReactNode;
}

export class Body extends React.PureComponent<BodyProps> {
  render() {
    const { props } = this;
    const rowCells: Array<React.ReactNode> = React.Children.toArray(props.children);
    const { data: dataRows } = props;
    const { length } = dataRows;

    return (
      dataRows.map((data, rowIndex) => (
        <Row key={`row_${rowIndex}`} {...props} data={data} location={LocationEnum.BODY} rowCount={length} rowIndex={rowIndex}>
          {rowCells}
        </Row>
      ))
    );
  }
}
