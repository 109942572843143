import React from "react";
import { View } from '@react-pdf/renderer';

import { Style } from "@react-pdf/types";

import { HAlignEnum } from '../enums';
import { LocationEnum } from "./enums";

import { rowCellMapper } from "./helpers";

import { HeaderProps } from "./Header";
import { Row } from "./Row";

import { mergeStyles } from "../utils";

export interface FooterProps extends HeaderProps {}

export class Footer extends React.PureComponent<FooterProps> {
  render() {
    const { props } = this;
    const footerStyle: Style = {};

    return props.children
      ? (<View debug={props.debug} fixed={props.repeat} style={mergeStyles(footerStyle, props.style)}>
        <Row fontWeight={'bold'} {...props} location={LocationEnum.FOOTER} rowCount={1} rowIndex={0}>
          {rowCellMapper({ props: { align: HAlignEnum.CENTER, ...props } })}
        </Row>
      </View>)
      : null;
  }
}
