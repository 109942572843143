import { ListTypeEnum, MarkerLocationEnum, MarkerTypeEnum } from "./enums";
import { VAlignEnum } from "../enums";

import { DEFAULTVIEWPROPS } from "../utils";
import { ListItemProps } from "./ListItem";
import { ListProps } from "./List";

export const DEFAULTLISTPROPS: ListProps = {
  ...DEFAULTVIEWPROPS,
  compact: false,
  glyph: null,
  icon: null,
  inline: false,
  listType: ListTypeEnum.NONE,
  markerColor: null,
  markerLocation: MarkerLocationEnum.OUTSIDE,
  markerType: MarkerTypeEnum.DECIMAL,
  markerVAlign: VAlignEnum.TOP,
  markerWeight: 'black',
  markerWidth: 0,
  reversed: false,
  start: 1
};

export const DEFAULTLISTITEMPROPS: ListItemProps = {
  ...DEFAULTVIEWPROPS,
  glyph: DEFAULTLISTPROPS.glyph,
  icon: DEFAULTLISTPROPS.icon,
  index: 0,
  listType: DEFAULTLISTPROPS.listType,
  markerColor: DEFAULTLISTPROPS.markerColor,
  markerLocation: DEFAULTLISTPROPS.markerLocation,
  markerType: DEFAULTLISTPROPS.markerType,
  markerVAlign: DEFAULTLISTPROPS.markerVAlign,
  markerWeight: DEFAULTLISTPROPS.markerWeight,
  markerWidth: DEFAULTLISTPROPS.markerWidth
};
