import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Column, Row } from '../Layout/Grid';
import { FlexStyles } from '../Layout/Flex';
import { BusinessMenuOptions } from './TopNavigation';
import CoreIcon from '../Media/Icon';
import BREAKPOINTS from '../../config/breakpoints';

const SubFooter = styled.footer`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.primaryContrast};
  padding: 1rem 0;
  font-size: 0.9rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  img {
    max-height: 1.5rem;
    max-width: 100%;
    margin-bottom: 2rem;

    @media (max-width: ${BREAKPOINTS.tablet}px) {
        margin-right: auto;
        margin-left: auto;
    }
  }
`;

const FooterLink = styled(Link)`
  color: ${props => props.theme.secondaryContrast};
  text-decoration: none;
  margin-bottom: 0.5rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.secondaryContrast};
  padding: 2rem 0;

  ${FooterLink}, a {
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        padding-left: 4rem;
    }
  }
`;

const SubFooterLinks = styled(FlexStyles.List)`
    text-align: right;
    flex-direction: row-reverse;

    @media (max-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: row;
    }

    ${FooterLink} {
        margin-left: 1rem;
    }
`

const Copyright = styled(Column)`
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        text-align: center;
        margin-bottom: 0.5rem;
    }
`

const Footer: React.FC = () => {
    const location = useLocation();

    if (location.pathname.startsWith('/standalone/') || location.pathname.startsWith('/dashboard')) return null;

    return (
        <>
            <FooterContainer>
                <FooterContent>
                    <img src='/assets/logo3.svg' alt='Dish Forager logo' />
                    <Row>
                        <Column size={4} noMarginBottom tablet={6} mobile={12}>
                            {BusinessMenuOptions.slice(0, 3).map(option => (
                                <><FooterLink to={option.to}><CoreIcon name={option.icon} /> {option.label}</FooterLink><br /></>
                            ))}
                        </Column>
                        <Column size={4} noMarginBottom tablet={6} mobile={12}>
                            {BusinessMenuOptions.slice(3, 7).map(option => (
                                <><FooterLink to={option.to}><CoreIcon name={option.icon} /> {option.label}</FooterLink><br /></>
                            ))}
                        </Column>
                        <Column size={4} noMarginBottom tablet={12}>
                            <a href='https://www.facebook.com/people/Dish-Forager/61550297942567/'><CoreIcon name='facebook' /> Facebook</a><br />
                            <a href='https://www.instagram.com/dishforager'><CoreIcon name='instagram' /> Instagram</a><br />
                            <a href='https://www.linkedin.com/company/dish-forager-ltd'><CoreIcon name='linkedin' /> LinkedIn</a><br />
                        </Column>
                    </Row>
                </FooterContent>
            </FooterContainer>
            <SubFooter>
                <FooterContent>
                    <Row>
                        <Copyright size={6} noMarginBottom mobile={12}>
                            &copy; 2024 - Dish Forager LTD, United Kingdom. All rights reserved.
                        </Copyright>
                        <Column size={6} noMarginBottom mobile={12}>
                            <SubFooterLinks>
                                <FooterLink to="/contact">Contact Us</FooterLink>
                                <FooterLink to="/terms">Terms of Service</FooterLink>
                                <FooterLink to="/privacy">Privacy Policy</FooterLink>
                            </SubFooterLinks>
                        </Column>
                    </Row>
                </FooterContent>
            </SubFooter>
        </>
    );
};

export default Footer;