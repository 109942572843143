import { Style } from "@react-pdf/types";

import { BodyProps } from "./Body";
import { CaptionProps } from "./Caption";
import { CaptionAlignEnum, FrameEnum, RulesEnum } from "./enums";
import { FooterProps } from "./Footer";
import { HeaderProps } from "./Header";

export const DEFAULTBODYPROPS: BodyProps = {
  borderColor: 'currentColor',
  borderStyle: 'solid',
  borderWidth: 0,
  cellPadding: 0,
  cols: [],
  data: [],
  rules: RulesEnum.NONE,
  style: null,
  zebra: false,
  zebraEven: null,
  zebraOdd: null,
  children: ""
};

export const DEFAULTCAPTIONPROPS: CaptionProps = {
  align: CaptionAlignEnum.TOP,
  children: ""
};

export const DEFAULTFOOTERPROPS: FooterProps = {
  borderColor: DEFAULTBODYPROPS.borderColor,
  borderStyle: DEFAULTBODYPROPS.borderStyle,
  borderWidth: DEFAULTBODYPROPS.borderWidth,
  cellPadding: DEFAULTBODYPROPS.cellPadding,
  cols: DEFAULTBODYPROPS.cols,
  rules: DEFAULTBODYPROPS.rules,
  style: DEFAULTBODYPROPS.style
};

export const DEFAULTHEADERPROPS: HeaderProps = {
  borderColor: DEFAULTBODYPROPS.borderColor,
  borderStyle: DEFAULTBODYPROPS.borderStyle,
  borderWidth: DEFAULTBODYPROPS.borderWidth,
  cellPadding: DEFAULTBODYPROPS.cellPadding,
  cols: DEFAULTBODYPROPS.cols,
  repeat: false,
  rules: DEFAULTBODYPROPS.rules,
  style: DEFAULTBODYPROPS.style
};

type TableFrameStylesType = {
  [key: string]: {
    borderWidth?: Style['borderWidth'];
    borderTopWidth?: Style['borderTopWidth'];
    borderRightWidth?: Style['borderRightWidth'];
    borderBottomWidth?: Style['borderBottomWidth'];
    borderLeftWidth?: Style['borderLeftWidth'];
  };
};

export const TableFrameStyles: TableFrameStylesType = {
  [FrameEnum.NONE]: { borderWidth: 0 },
  [FrameEnum.ABOVE]: { borderTopWidth: 1 },
  [FrameEnum.BELOW]: { borderBottomWidth: 1 },
  [FrameEnum.LHS]: { borderLeftWidth: 1 },
  [FrameEnum.RHS]: { borderRightWidth: 1 },
  [FrameEnum.HSIDES]: { borderTopWidth: 1, borderBottomWidth: 1 },
  [FrameEnum.VSIDES]: { borderLeftWidth: 1, borderRightWidth: 1 },
  [FrameEnum.BORDER]: { borderWidth: 1 },
  [FrameEnum.BOX]: { borderWidth: 1 }
};

export const TableRuleStyles: TableFrameStylesType = {
  [RulesEnum.NONE]: TableFrameStyles[FrameEnum.NONE],
  [RulesEnum.ROWS]: TableFrameStyles[FrameEnum.HSIDES],
  [RulesEnum.COLS]: TableFrameStyles[FrameEnum.VSIDES],
  [RulesEnum.GROUPS]: TableFrameStyles[FrameEnum.BELOW],
  [RulesEnum.ALL]: TableFrameStyles[FrameEnum.BOX]
};
