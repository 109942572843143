// This is generated code do not edit.

class FetchJSON {
    static fetch(url: string, method: string = 'GET', data: any = {}): Promise<any> {
      return new Promise((resolve, reject) => {
        const postData = JSON.stringify(data);
        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open(method, url, true);
        // xhr.withCredentials = true;
        xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
        xhr.send(postData);
  
        function destroy() {
          xhr.onload = null;
          xhr.onerror = null;
          xhr = null;
        }
  
        xhr.onload = () => {
          if (xhr.status === 401) {
            resolve({
              error: 'no auth',
            })
          } else if (xhr.status === 204) {
            resolve(undefined);
          } else if (xhr.status >= 400) {
            try {
              const data: any = xhr?.response !== null && xhr?.response !== undefined ? JSON.parse(xhr.response) : null;
              reject(data);
            } catch {
              reject(undefined);
            }
          } else {
            try {
              const data: any = xhr?.response !== null && xhr?.response !== undefined ? JSON.parse(xhr.response) : null;
              resolve(data);
            } catch {
              reject(undefined);
            }
          }
          destroy();
        };
  
        xhr.onerror = (error: any) => {
          reject(error);
          destroy();
        };
      });
    }
  }

  function clone(object) {
    return JSON.parse(JSON.stringify(object))
  }
  

import { LoggingService } from '../services/LoggingService';
import {
  GenericResponse,
  UserAccountDetails,
  ThirdPartyLogin,
  RegisterViewModel,
  ForgotPasswordViewModel,
  ChangePasswordViewModel,
  ResetPasswordViewModel,
  LoginViewModel,
  AddEventAddressRequest,
  OverlappingBooking,
  AdminBookingDetails,
  BaseAppUser,
  BaseBusiness,
  LightBookingHistory,
  AutoSuggestion,
  BaseBlockTimesAreaLink,
  BaseBlockTimesTableSelection,
  BaseBookingConfigurationSetting,
  BaseBookingDurationRule,
  DurationRuleInsertionResponse,
  BookingManagementResponse,
  LightBookingNotification,
  NotificationsCountParameters,
  NotificationsCountResponse,
  BaseBookingPacingRule,
  PacingRuleInsertionResponse,
  BaseBookingPayment,
  ApiMakeBookingAsBusinessRequest,
  ApiMakeAdminBookingResponse,
  BaseActiveBooking,
  BookingAmendmentResponse,
  UpdateBookingStatusRequest,
  ResponseOrError,
  BaseDepositRule,
  InternalApiBookingTimesRequest,
  HoldOrGetAvailableTablesResponse,
  PreAmendBookingResponse,
  AmendBookingRequest,
  DateAndTimeSlots,
  ApiBookingTimes,
  EventsAvailabilityAndHoldResponse,
  BookingDayOverviewResponse,
  BaseBookingStopSale,
  BaseBookingTableSetup,
  DeleteRequest,
  BaseBookingTimeBlock,
  TimeBlockAddUpdateResponse,
  TwilioUpdate,
  SendGridUpdate,
  StripeUpdate,
  LightClientForBusiness,
  FullClientModel,
  ClientModel,
  BaseBusinessOpeningTimeException,
  SaveExceptionTimeRequest,
  ExceptionTimesValidityResponse,
  BaseBusinessOpeningTimesRange,
  UpdateOpeningTimes,
  BusinessSearchResult,
  BaseBusinessServer,
  VerificationModel,
  ApiInvoice,
  ApiPaymentMethod,
  ApiSystemProduct,
  CreateReaderRequest,
  CardMachineCaptureIntentRequest,
  CardMachinePaymentIntentResponse,
  InHousePaymentRequest,
  RefundRequest,
  LightCombinationGroup,
  AddCombinationRequest,
  ApiComponent,
  ApiComponentProperty,
  DepositRulesResponse,
  BaseDish,
  ArchiveDishParameters,
  BaseDishBuzzWord,
  BaseDishIngredientLink,
  BaseDishOptionGroup,
  ApiDishRating,
  BaseDishTagLink,
  BaseExperience,
  ExtendedBaseExperience,
  LightExperienceBooking,
  BaseExperienceAreaLink,
  BaseExperienceTableSelection,
  BaseGlobalAdminPermission,
  ApiImage,
  BaseIngredient,
  BaseMembership,
  BaseMenu,
  ApiMenuListResponse,
  ApiMenu,
  BaseMenuSection,
  UpdateMenuServingTimes,
  GetPageResponse,
  PagesListResponse,
  PageRequest,
  ApiPage,
  ApiPageInteraction,
  BaseParentBusiness,
  BusinessPermissionsResponse,
  RegisterBusinessModel,
  ApiPDF,
  BasePermission,
  ApiPicture,
  UserProfile,
  ProfileBookingListResponse,
  BaseRecipe,
  LocationQueryParams,
  LocationResult,
  DishSearchRequest,
  SearchResult,
  BaseShape,
  BaseShiftNote,
  BaseSupportTicket,
  BaseSupportTicketResponse,
  BaseTableSetupArea,
  ApiTag,
  ApiUserDetail,
  ApiBookingTimesRequest,
  WebEventsAvailabilityResponse,
  DateAndTimeSlotsWidget,
  ApiBookingTimesWidget,
  ClientBookingResponse,
  ConfirmBookingViaWebRequest,
  ConfirmBookingResponse,
  CancelBookingViaWebRequest,
  PublicAmendBookingRequest,
  HeldBookingResponse,
  ApiMakeBookingRequest,
  ApiMakeBookingResponse,
  ApiPaymentRequest,
  IntentResponse,
  ApiBusinessBookingDetails,
  BaseOrder,
  CreateBlankOrderRequest,
  CreateOrderResponse,
  OrderMenuResponse,
  BaseWebsiteContactFormRequest,
} from './api-definitions';

class Account {

  async IsLoggedIn__GET(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/isLoggedIn`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UserInfo__GET(): Promise<UserAccountDetails | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/userInfo`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Logout__POST(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/logout`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UsernameAvailability__POST(username?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/usernameAvailability?username=${username}`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ConfirmEmail__GET(email?: string | string, code?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(email) == "object" && email !== null) {
       let paramData = clone(email);
       email = paramData["email"];
       code = paramData["code"];
    }
    await FetchJSON.fetch(`/api/account/confirmEmail?email=${email}&code=${code}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ThirdPartyRegister__POST(requestBody?: ThirdPartyLogin): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/thirdPartyRegister`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Register__POST(requestBody?: RegisterViewModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/register`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ForgotPassword__POST(requestBody?: ForgotPasswordViewModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/forgotPassword`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ChangePassword__POST(requestBody?: ChangePasswordViewModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/changePassword`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ResetPassword__POST(requestBody?: ResetPasswordViewModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/resetPassword`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ThirdPartyLogin__POST(requestBody?: ThirdPartyLogin): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/thirdPartyLogin`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  /**
      Logs the user in

  Sample request:
            
    POST /login
    {
       "Email": "testuser@example.com",
       "Password": "Password",
       "RememberMe": true
    }
    */
  async Login__POST(requestBody?: LoginViewModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/account/login`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Googleaddress {

  async AddEventAddress__PUT(requestBody?: AddEventAddressRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/googleaddress/addEventAddress`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(experienceId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/googleaddress/delete?experienceId=${experienceId}`, 'DELETE').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Admin {

  async List__GET(): Promise<OverlappingBooking[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/admin/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async BookingsByRef__GET(bookingReference?: string | string): Promise<AdminBookingDetails[] | null> {
    let data = null;
    if (typeof(bookingReference) == "object" && bookingReference !== null) {
       let paramData = clone(bookingReference);
       bookingReference = paramData["bookingReference"];
    }
    await FetchJSON.fetch(`/api/admin/bookingsByRef?bookingReference=${bookingReference}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Aiprompt {

  async Aiprompt__POST(requestBody?: string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/aiprompt`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class AppUser {

  async List__GET(): Promise<BaseAppUser[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/appUser/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseAppUser | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/appUser/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetBusinesses__GET(parentId?: number | string): Promise<BaseBusiness[] | null> {
    let data = null;
    if (typeof(parentId) == "object" && parentId !== null) {
       let paramData = clone(parentId);
       parentId = paramData["parentId"];
    }
    await FetchJSON.fetch(`/api/appUser/getBusinesses?parentId=${parentId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetBusinessUsers__GET(id?: number | string): Promise<UserAccountDetails[] | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/appUser/getBusinessUsers?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseAppUser): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/appUser/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseAppUser): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/appUser/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/appUser/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class AuditLog {

  async GetBookingHistory__GET(bookingId?: number | string): Promise<LightBookingHistory[] | null> {
    let data = null;
    if (typeof(bookingId) == "object" && bookingId !== null) {
       let paramData = clone(bookingId);
       bookingId = paramData["bookingId"];
    }
    await FetchJSON.fetch(`/api/auditLog/getBookingHistory?bookingId=${bookingId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class AutoSuggestionsApi {

  async List__GET(latitude?: number | string, longitude?: number | string): Promise<AutoSuggestion[] | null> {
    let data = null;
    if (typeof(latitude) == "object" && latitude !== null) {
       let paramData = clone(latitude);
       latitude = paramData["latitude"];
       longitude = paramData["longitude"];
    }
    await FetchJSON.fetch(`/api/autoSuggestionsApi/list?latitude=${latitude}&longitude=${longitude}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BlockTimesAreaLink {

  async List__GET(blockTimeId?: number | string): Promise<BaseBlockTimesAreaLink[] | null> {
    let data = null;
    if (typeof(blockTimeId) == "object" && blockTimeId !== null) {
       let paramData = clone(blockTimeId);
       blockTimeId = paramData["blockTimeId"];
    }
    await FetchJSON.fetch(`/api/blockTimesAreaLink/list?blockTimeId=${blockTimeId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseBlockTimesAreaLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/blockTimesAreaLink/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBlockTimesAreaLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/blockTimesAreaLink/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BlockTimesTableSelection {

  async Add__PUT(requestBody?: BaseBlockTimesTableSelection): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/blockTimesTableSelection/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBlockTimesTableSelection): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/blockTimesTableSelection/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingConfigurationSetting {

  async List__GET(): Promise<BaseBookingConfigurationSetting[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingConfigurationSetting/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseBookingConfigurationSetting | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/bookingConfigurationSetting/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseBookingConfigurationSetting): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingConfigurationSetting/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBookingConfigurationSetting): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingConfigurationSetting/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBookingConfigurationSetting): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingConfigurationSetting/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingDurationRules {

  async Get__GET(id?: number | string, experienceId?: number | string, activeOnly?: boolean | string): Promise<BaseBookingDurationRule[] | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       experienceId = paramData["experienceId"];
       activeOnly = paramData["activeOnly"];
    }
    await FetchJSON.fetch(`/api/bookingDurationRules/get?id=${id}&experienceId=${experienceId}&activeOnly=${activeOnly}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseBookingDurationRule): Promise<DurationRuleInsertionResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingDurationRules/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBookingDurationRule): Promise<DurationRuleInsertionResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingDurationRules/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBookingDurationRule): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingDurationRules/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingManagement {

  async Get__GET(id?: number | string, parentId?: number | string, locationId?: string | string, startDate?: string | string): Promise<BookingManagementResponse | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       parentId = paramData["parentId"];
       locationId = paramData["locationId"];
       startDate = paramData["startDate"];
    }
    await FetchJSON.fetch(`/api/BookingManagement/get?id=${id}&parentId=${parentId}&locationId=${locationId}&startDate=${startDate}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetUpdated__GET(id?: number | string, locationId?: string | string, startDate?: string | string, getForDay?: boolean | string): Promise<BookingManagementResponse | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       locationId = paramData["locationId"];
       startDate = paramData["startDate"];
       getForDay = paramData["getForDay"];
    }
    await FetchJSON.fetch(`/api/BookingManagement/getUpdated?id=${id}&locationId=${locationId}&startDate=${startDate}&getForDay=${getForDay}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingNotification {

  async List__GET(businessId?: number | string): Promise<LightBookingNotification[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/bookingNotification/list?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetCount__POST(requestBody?: NotificationsCountParameters): Promise<NotificationsCountResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingNotification/getCount`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingPacingRules {

  async Get__GET(id?: number | string, experienceId?: number | string, activeOnly?: boolean | string): Promise<BaseBookingPacingRule[] | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       experienceId = paramData["experienceId"];
       activeOnly = paramData["activeOnly"];
    }
    await FetchJSON.fetch(`/api/bookingPacingRules/get?id=${id}&experienceId=${experienceId}&activeOnly=${activeOnly}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseBookingPacingRule): Promise<PacingRuleInsertionResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPacingRules/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBookingPacingRule): Promise<PacingRuleInsertionResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPacingRules/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBookingPacingRule): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPacingRules/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingPayment {

  async List__GET(bookingId?: number | string, businessId?: number | string): Promise<BaseBookingPayment[] | null> {
    let data = null;
    if (typeof(bookingId) == "object" && bookingId !== null) {
       let paramData = clone(bookingId);
       bookingId = paramData["bookingId"];
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/bookingPayment/list?bookingId=${bookingId}&businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string, businessId?: number | string): Promise<BaseBookingPayment | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/bookingPayment/get?id=${id}&businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseBookingPayment): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPayment/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async AddBookingPayment__PUT(requestBody?: BaseBookingPayment): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPayment/addBookingPayment`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBookingPayment): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPayment/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBookingPayment): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingPayment/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Bookings {

  async Insert__PUT(requestBody?: ApiMakeBookingAsBusinessRequest): Promise<ApiMakeAdminBookingResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/insert`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async RemoveTableAllocation__POST(requestBody?: BaseActiveBooking): Promise<BookingAmendmentResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/removeTableAllocation`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateStatus__POST(requestBody?: UpdateBookingStatusRequest): Promise<ResponseOrError | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/updateStatus`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetDepositRuleForBooking__POST(requestBody?: BaseActiveBooking): Promise<BaseDepositRule | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/getDepositRuleForBooking`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateNotes__POST(requestBody?: BaseActiveBooking): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/updateNotes`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async HoldOrGetTablesToMerge__POST(requestBody?: InternalApiBookingTimesRequest): Promise<HoldOrGetAvailableTablesResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/holdOrGetTablesToMerge`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async HoldTables__POST(requestBody?: InternalApiBookingTimesRequest): Promise<HoldOrGetAvailableTablesResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/holdTables`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async AmendBookingSearch__POST(requestBody?: InternalApiBookingTimesRequest): Promise<PreAmendBookingResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/amendBookingSearch`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async AmendSave__POST(requestBody?: AmendBookingRequest): Promise<BookingAmendmentResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/amendSave`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async NextAvailableSlots__POST(requestBody?: InternalApiBookingTimesRequest): Promise<DateAndTimeSlots | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/nextAvailableSlots`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetNextAvailable__POST(requestBody?: InternalApiBookingTimesRequest): Promise<ApiBookingTimes | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/getNextAvailable`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetEventAvailabilityOrTablesToMerge__POST(requestBody?: InternalApiBookingTimesRequest): Promise<EventsAvailabilityAndHoldResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookings/getEventAvailabilityOrTablesToMerge`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Bookingsetup {

  async Get__GET(id?: number | string): Promise<BaseBusiness | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/bookingsetup/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetConfirmationTemplate__GET(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingsetup/getConfirmationTemplate`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: BaseBusiness): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingsetup/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingStatistics {

  async SevenDayOverview__GET(businessId?: number | string): Promise<BookingDayOverviewResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/bookingStatistics/sevenDayOverview?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async OverviewBetweenDates__GET(businessId?: number | string, startDate?: string | string, endDate?: string | string): Promise<BookingDayOverviewResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       startDate = paramData["startDate"];
       endDate = paramData["endDate"];
    }
    await FetchJSON.fetch(`/api/bookingStatistics/overviewBetweenDates?businessId=${businessId}&startDate=${startDate}&endDate=${endDate}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BookingStopSale {

  async Get__GET(date?: string | string, businessId?: number | string): Promise<BaseBookingStopSale | null> {
    let data = null;
    if (typeof(date) == "object" && date !== null) {
       let paramData = clone(date);
       date = paramData["date"];
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/bookingStopSale/get?date=${date}&businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: BaseBookingStopSale): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingStopSale/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Bookingtablesetup {

  async List__GET(businessId?: number | string): Promise<BaseBookingTableSetup[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/bookingtablesetup/list?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Insert__PUT(requestBody?: BaseBookingTableSetup): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingtablesetup/insert`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: BaseBookingTableSetup): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingtablesetup/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: DeleteRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingtablesetup/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Bookingtimeblock {

  async Get__GET(id?: number | string): Promise<BaseBookingTimeBlock | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/bookingtimeblock/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async List__GET(businessId?: number | string, experienceId?: number | string, activeOnly?: boolean | string): Promise<BaseBookingTimeBlock[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       experienceId = paramData["experienceId"];
       activeOnly = paramData["activeOnly"];
    }
    await FetchJSON.fetch(`/api/bookingtimeblock/list?businessId=${businessId}&experienceId=${experienceId}&activeOnly=${activeOnly}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Insert__PUT(requestBody?: BaseBookingTimeBlock): Promise<TimeBlockAddUpdateResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingtimeblock/insert`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: BaseBookingTimeBlock): Promise<TimeBlockAddUpdateResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingtimeblock/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBookingTimeBlock): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/bookingtimeblock/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Business {

  async Get__GET(id?: string | string): Promise<BaseBusiness | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/business/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetBusinessDetails__GET(id?: string | string): Promise<BaseBusiness | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/business/getBusinessDetails?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async List__GET(): Promise<BaseBusiness[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/business/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateFoodRating__GET(locationId?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(locationId) == "object" && locationId !== null) {
       let paramData = clone(locationId);
       locationId = paramData["locationId"];
    }
    await FetchJSON.fetch(`/api/business/updateFoodRating?locationId=${locationId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateTwilio__POST(requestBody?: TwilioUpdate): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/business/updateTwilio`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateSendGrid__POST(requestBody?: SendGridUpdate): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/business/updateSendGrid`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateStripe__POST(requestBody?: StripeUpdate): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/business/updateStripe`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetStripeRegisterUrl__GET(businessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/business/getStripeRegisterUrl?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CheckDNS__GET(locationId?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(locationId) == "object" && locationId !== null) {
       let paramData = clone(locationId);
       locationId = paramData["locationId"];
    }
    await FetchJSON.fetch(`/api/business/checkDNS?locationId=${locationId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBusiness): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/business/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBusiness): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/business/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BusinessClient {

  async GetDetails__GET(clientId?: number | string, parentBusinessId?: number | string): Promise<LightClientForBusiness | null> {
    let data = null;
    if (typeof(clientId) == "object" && clientId !== null) {
       let paramData = clone(clientId);
       clientId = paramData["clientId"];
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/businessClient/getDetails?clientId=${clientId}&parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetBookingsForStatus__GET(clientId?: number | string, parentBusinessId?: number | string, status?: any | string): Promise<BaseActiveBooking[] | null> {
    let data = null;
    if (typeof(clientId) == "object" && clientId !== null) {
       let paramData = clone(clientId);
       clientId = paramData["clientId"];
       parentBusinessId = paramData["parentBusinessId"];
       status = paramData["status"];
    }
    await FetchJSON.fetch(`/api/businessClient/getBookingsForStatus?clientId=${clientId}&parentBusinessId=${parentBusinessId}&status=${status}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetFullDetails__GET(clientId?: number | string, parentBusinessId?: number | string): Promise<FullClientModel | null> {
    let data = null;
    if (typeof(clientId) == "object" && clientId !== null) {
       let paramData = clone(clientId);
       clientId = paramData["clientId"];
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/businessClient/getFullDetails?clientId=${clientId}&parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateClientNotes__POST(requestBody?: FullClientModel, parentBusinessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessClient/updateClientNotes?parentBusinessId=${parentBusinessId}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async List__GET(parentBusinessId?: number | string): Promise<ClientModel[] | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/businessClient/list?parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ListLight__GET(parentBusinessId?: number | string): Promise<ClientModel[] | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/businessClient/listLight?parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BusinessOpeningTimeException {

  async List__GET(businessId?: number | string, experienceId?: number | string, activeOnly?: boolean | string): Promise<BaseBusinessOpeningTimeException[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       experienceId = paramData["experienceId"];
       activeOnly = paramData["activeOnly"];
    }
    await FetchJSON.fetch(`/api/businessOpeningTimeException/list?businessId=${businessId}&experienceId=${experienceId}&activeOnly=${activeOnly}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseBusinessOpeningTimeException | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/businessOpeningTimeException/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: SaveExceptionTimeRequest): Promise<ExceptionTimesValidityResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessOpeningTimeException/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: SaveExceptionTimeRequest): Promise<ExceptionTimesValidityResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessOpeningTimeException/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBusinessOpeningTimeException): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessOpeningTimeException/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BusinessOpeningTimesRange {

  async List__GET(businessId?: number | string, parentBusinessId?: number | string): Promise<BaseBusinessOpeningTimesRange[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/businessOpeningTimesRange/list?businessId=${businessId}&parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: UpdateOpeningTimes): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessOpeningTimesRange/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessOpeningTimesRange/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BusinessSearch {

  async List__GET(query?: string | string, category?: number | string, latitude?: number | string, longitude?: number | string, pageNumber?: number | string, radiusInMeters?: number | string): Promise<BusinessSearchResult | null> {
    let data = null;
    if (typeof(query) == "object" && query !== null) {
       let paramData = clone(query);
       query = paramData["query"];
       category = paramData["category"];
       latitude = paramData["latitude"];
       longitude = paramData["longitude"];
       pageNumber = paramData["pageNumber"];
       radiusInMeters = paramData["radiusInMeters"];
    }
    await FetchJSON.fetch(`/api/businessSearch/list?query=${query}&category=${category}&latitude=${latitude}&longitude=${longitude}&pageNumber=${pageNumber}&radiusInMeters=${radiusInMeters}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BusinessServer {

  async List__GET(id?: number | string): Promise<BaseBusinessServer[] | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/businessServer/list?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseBusinessServer | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/businessServer/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseBusinessServer): Promise<BaseBusinessServer | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessServer/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseBusinessServer): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessServer/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseBusinessServer): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessServer/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class BusinessVerification {

  async List__GET(): Promise<BaseBusiness[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessVerification/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Reject__POST(requestBody?: VerificationModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessVerification/reject`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: VerificationModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessVerification/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Marksent__POST(requestBody?: VerificationModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessVerification/marksent`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Verify__POST(requestBody?: VerificationModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/businessVerification/verify`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Checkout {

  async PaymentIntent__GET(parentBusinessId?: number | string, subscriptionId?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
       subscriptionId = paramData["subscriptionId"];
    }
    await FetchJSON.fetch(`/api/checkout/paymentIntent?parentBusinessId=${parentBusinessId}&subscriptionId=${subscriptionId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async SetupIntent__GET(parentBusinessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/checkout/setupIntent?parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetInvoices__GET(parentBusinessId?: number | string): Promise<ApiInvoice[] | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/checkout/getInvoices?parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetPaymentMethods__GET(parentBusinessId?: number | string): Promise<ApiPaymentMethod[] | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/checkout/getPaymentMethods?parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetProducts__GET(): Promise<ApiSystemProduct[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/getProducts`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetProductsWithSubscriptionStatus__GET(parentBusinessId?: number | string, bypassCache?: boolean | string): Promise<ApiSystemProduct[] | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
       bypassCache = paramData["bypassCache"];
    }
    await FetchJSON.fetch(`/api/checkout/getProductsWithSubscriptionStatus?parentBusinessId=${parentBusinessId}&bypassCache=${bypassCache}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async RemovePaymentMethod__GET(paymentMethodId?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(paymentMethodId) == "object" && paymentMethodId !== null) {
       let paramData = clone(paymentMethodId);
       paymentMethodId = paramData["paymentMethodId"];
    }
    await FetchJSON.fetch(`/api/checkout/removePaymentMethod?paymentMethodId=${paymentMethodId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Subscribe__GET(priceId?: string | string, parentBusinessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(priceId) == "object" && priceId !== null) {
       let paramData = clone(priceId);
       priceId = paramData["priceId"];
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/checkout/subscribe?priceId=${priceId}&parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Unsubscribe__GET(priceId?: string | string, parentBusinessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(priceId) == "object" && priceId !== null) {
       let paramData = clone(priceId);
       priceId = paramData["priceId"];
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/checkout/unsubscribe?priceId=${priceId}&parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ChargeNow__GET(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/chargeNow`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CreateConnectionToken__GET(businessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/checkout/createConnectionToken?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CreateReaderLocation__GET(businessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/checkout/createReaderLocation?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CreateReader__POST(requestBody?: CreateReaderRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/createReader`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CreateCardCharge__POST(requestBody?: CardMachineCaptureIntentRequest): Promise<CardMachinePaymentIntentResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/createCardCharge`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateReaderSplashscreen__GET(businessId?: number | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/checkout/updateReaderSplashscreen?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async RemoveReader__GET(businessId?: number | string, readerId?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       readerId = paramData["readerId"];
    }
    await FetchJSON.fetch(`/api/checkout/removeReader?businessId=${businessId}&readerId=${readerId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CapturePaymentIntent__GET(businessId?: number | string, paymentIntentId?: string | string): Promise<CardMachinePaymentIntentResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       paymentIntentId = paramData["paymentIntentId"];
    }
    await FetchJSON.fetch(`/api/checkout/capturePaymentIntent?businessId=${businessId}&paymentIntentId=${paymentIntentId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ConfirmPaymentIntent__GET(businessId?: number | string, paymentIntentId?: string | string): Promise<CardMachinePaymentIntentResponse | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       paymentIntentId = paramData["paymentIntentId"];
    }
    await FetchJSON.fetch(`/api/checkout/confirmPaymentIntent?businessId=${businessId}&paymentIntentId=${paymentIntentId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CreateInHousePaymentIntent__POST(requestBody?: InHousePaymentRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/createInHousePaymentIntent`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CreateInHouseSetupIntent__POST(requestBody?: InHousePaymentRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/createInHouseSetupIntent`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ChargePreAuthedPayment__POST(requestBody?: BaseBookingPayment): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/chargePreAuthedPayment`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async IssueRefund__POST(requestBody?: RefundRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/checkout/issueRefund`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class CombinationGroup {

  async GetAllForBusiness__GET(businessId?: number | string): Promise<LightCombinationGroup[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/combinationGroup/getAllForBusiness?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: AddCombinationRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/combinationGroup/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: LightCombinationGroup): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/combinationGroup/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: DeleteRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/combinationGroup/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class ComponentBuilderApi {

  async Get__GET(partitionKey?: string | string): Promise<ApiComponent[] | null> {
    let data = null;
    if (typeof(partitionKey) == "object" && partitionKey !== null) {
       let paramData = clone(partitionKey);
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/ComponentBuilderApi/get?partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: ApiComponent[], partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ComponentBuilderApi/save?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateComponent__POST(requestBody?: ApiComponent, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ComponentBuilderApi/updateComponent?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateProperty__POST(requestBody?: ApiComponentProperty, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ComponentBuilderApi/updateProperty?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__POST(requestBody?: ApiComponent, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ComponentBuilderApi/delete?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async DeleteProperty__POST(requestBody?: ApiComponentProperty, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ComponentBuilderApi/deleteProperty?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class DepositRule {

  async List__GET(businessId?: number | string, experienceId?: number | string, activeOnly?: boolean | string): Promise<BaseDepositRule[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       experienceId = paramData["experienceId"];
       activeOnly = paramData["activeOnly"];
    }
    await FetchJSON.fetch(`/api/depositRule/list?businessId=${businessId}&experienceId=${experienceId}&activeOnly=${activeOnly}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string, businessId?: number | string): Promise<BaseDepositRule | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/depositRule/get?id=${id}&businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseDepositRule): Promise<DepositRulesResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/depositRule/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseDepositRule): Promise<DepositRulesResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/depositRule/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseDepositRule): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/depositRule/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Devtest {

  async List__GET(query?: string | string): Promise<BusinessSearchResult | null> {
    let data = null;
    if (typeof(query) == "object" && query !== null) {
       let paramData = clone(query);
       query = paramData["query"];
    }
    await FetchJSON.fetch(`/api/devtest/list?query=${query}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetMarkup__POST(requestBody?: GenericResponse): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/devtest/GetMarkup`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Dish {

  async List__GET(businessId?: number | string, zeroOut?: boolean | string, isParentBusiness?: boolean | string): Promise<BaseDish[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       zeroOut = paramData["zeroOut"];
       isParentBusiness = paramData["isParentBusiness"];
    }
    await FetchJSON.fetch(`/api/dish/list?businessId=${businessId}&zeroOut=${zeroOut}&isParentBusiness=${isParentBusiness}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseDish | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/dish/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseDish): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dish/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async AddMany__PUT(requestBody?: BaseDish[]): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dish/addMany`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseDish): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dish/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async DishAction__POST(requestBody?: ArchiveDishParameters): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dish/dishAction`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dish/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class DishBuzzWord {

  async List__GET(): Promise<BaseDishBuzzWord[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishBuzzWord/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseDishBuzzWord | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/dishBuzzWord/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseDishBuzzWord): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishBuzzWord/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Addmany__PUT(requestBody?: BaseDishBuzzWord[]): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishBuzzWord/addmany`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseDishBuzzWord): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishBuzzWord/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseDishBuzzWord): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishBuzzWord/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class DishIngredientLink {

  async List__GET(): Promise<BaseDishIngredientLink[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishIngredientLink/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseDishIngredientLink | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/dishIngredientLink/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseDishIngredientLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishIngredientLink/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Addmany__PUT(requestBody?: BaseDishIngredientLink[]): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishIngredientLink/addmany`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseDishIngredientLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishIngredientLink/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseDishIngredientLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishIngredientLink/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class DishOptionGroup {

  async Add__PUT(requestBody?: BaseDishOptionGroup): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishOptionGroup/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseDishOptionGroup): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishOptionGroup/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseDishOptionGroup): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishOptionGroup/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class DishRating {

  async List__GET(): Promise<ApiDishRating[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishRating/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(rowKey?: string | string): Promise<ApiDishRating | null> {
    let data = null;
    if (typeof(rowKey) == "object" && rowKey !== null) {
       let paramData = clone(rowKey);
       rowKey = paramData["rowKey"];
    }
    await FetchJSON.fetch(`/api/dishRating/get?rowKey=${rowKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: ApiDishRating): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishRating/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: ApiDishRating): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishRating/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: ApiDishRating): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishRating/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class DishTagLink {

  async List__GET(): Promise<BaseDishTagLink[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishTagLink/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseDishTagLink | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/dishTagLink/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseDishTagLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishTagLink/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Addmany__PUT(requestBody?: BaseDishTagLink[]): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishTagLink/addmany`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseDishTagLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishTagLink/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseDishTagLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/dishTagLink/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Experience {

  async List__GET(businessId?: number | string, includeArchived?: boolean | string): Promise<BaseExperience[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       includeArchived = paramData["includeArchived"];
    }
    await FetchJSON.fetch(`/api/experience/list?businessId=${businessId}&includeArchived=${includeArchived}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<ExtendedBaseExperience | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/experience/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetBookings__GET(experienceId?: number | string, businessId?: number | string): Promise<LightExperienceBooking[] | null> {
    let data = null;
    if (typeof(experienceId) == "object" && experienceId !== null) {
       let paramData = clone(experienceId);
       experienceId = paramData["experienceId"];
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/experience/getBookings?experienceId=${experienceId}&businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseExperience): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experience/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseExperience): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experience/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(id?: number | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experience/delete?id=${id}`, 'DELETE').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class ExperienceAreaLink {

  async List__GET(experienceId?: number | string): Promise<BaseExperienceAreaLink[] | null> {
    let data = null;
    if (typeof(experienceId) == "object" && experienceId !== null) {
       let paramData = clone(experienceId);
       experienceId = paramData["experienceId"];
    }
    await FetchJSON.fetch(`/api/experienceAreaLink/list?experienceId=${experienceId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseExperienceAreaLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experienceAreaLink/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseExperienceAreaLink): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experienceAreaLink/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class ExperienceTableSelection {

  async Add__PUT(requestBody?: BaseExperienceTableSelection): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experienceTableSelection/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseExperienceTableSelection): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/experienceTableSelection/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class GlobalAdminPermission {

  async Add__PUT(requestBody?: BaseGlobalAdminPermission): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/globalAdminPermission/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseGlobalAdminPermission): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/globalAdminPermission/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Image {

  async Get__GET(): Promise<ApiImage[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/image/get`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetForSite__GET(partitionKey?: string | string): Promise<ApiImage[] | null> {
    let data = null;
    if (typeof(partitionKey) == "object" && partitionKey !== null) {
       let paramData = clone(partitionKey);
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/image/getForSite?partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: ApiImage): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/image/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__POST(requestBody?: ApiImage): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/image/delete`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Image_get__GET(imageName: string, maxWidth?: number | string, maxHeight?: number | string): Promise<undefined | null> {
    let data = null;
    if (typeof(imageName) == "object" && imageName !== null) {
       let paramData = clone(imageName);
       imageName = paramData["imageName"];
       maxWidth = paramData["maxWidth"];
       maxHeight = paramData["maxHeight"];
    }
    await FetchJSON.fetch(`/api/image/${imageName}?maxWidth=${maxWidth}&maxHeight=${maxHeight}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Upload__POST(partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/image/upload?partitionKey=${partitionKey}`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Ingredient {

  async List__GET(): Promise<BaseIngredient[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ingredient/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseIngredient | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/ingredient/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseIngredient): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ingredient/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Addmany__PUT(requestBody?: BaseIngredient[]): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ingredient/addmany`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseIngredient): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ingredient/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseIngredient): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/ingredient/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Membership {

  async List__GET(): Promise<BaseMembership[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/membership/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseMembership | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/membership/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseMembership): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/membership/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseMembership): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/membership/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/membership/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Menu {

  async List__GET(businessId?: number | string, parentBusinessId?: number | string): Promise<BaseMenu[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/menu/list?businessId=${businessId}&parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseMenu | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/menu/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseMenu): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menu/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Publish__POST(requestBody?: BaseMenu): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menu/publish`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseMenu): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menu/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseMenu): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menu/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class MenuBuilderApi {

  async List__GET(partitionKey?: string | string): Promise<ApiMenuListResponse | null> {
    let data = null;
    if (typeof(partitionKey) == "object" && partitionKey !== null) {
       let paramData = clone(partitionKey);
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/MenuBuilderApi/list?partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async New__POST(requestBody?: ApiMenu, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/MenuBuilderApi/new?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: ApiMenu, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/MenuBuilderApi/save?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__POST(requestBody?: ApiMenu, partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/MenuBuilderApi/delete?partitionKey=${partitionKey}`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class MenuSection {

  async List__GET(businessId?: number | string, zeroOut?: boolean | string): Promise<BaseMenuSection[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       zeroOut = paramData["zeroOut"];
    }
    await FetchJSON.fetch(`/api/menuSection/list?businessId=${businessId}&zeroOut=${zeroOut}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseMenuSection | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/menuSection/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseMenuSection): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menuSection/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async AddMany__PUT(requestBody?: BaseMenuSection[]): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menuSection/addMany`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseMenuSection): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menuSection/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menuSection/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class MenuServingTimeRange {

  async Save__POST(requestBody?: UpdateMenuServingTimes): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menuServingTimeRange/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/menuServingTimeRange/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Page {

  async Get__GET(id?: string | string, partitionKey?: string | string): Promise<GetPageResponse | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/Page/get?id=${id}&partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async List__GET(partitionKey?: string | string): Promise<PagesListResponse | null> {
    let data = null;
    if (typeof(partitionKey) == "object" && partitionKey !== null) {
       let paramData = clone(partitionKey);
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/Page/list?partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async New__POST(requestBody?: PageRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/Page/new`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async SaveLayout__POST(requestBody?: ApiPage): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/Page/saveLayout`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__POST(requestBody?: ApiPage): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/Page/delete`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: ApiPage): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/Page/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Gethtml__GET(partitionKey?: string | string): Promise<string | null> {
    let data = null;
    if (typeof(partitionKey) == "object" && partitionKey !== null) {
       let paramData = clone(partitionKey);
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/Page/gethtml?partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async SetupTest__GET(partitionKey?: string | string): Promise<boolean | null> {
    let data = null;
    if (typeof(partitionKey) == "object" && partitionKey !== null) {
       let paramData = clone(partitionKey);
       partitionKey = paramData["partitionKey"];
    }
    await FetchJSON.fetch(`/api/Page/setupTest?partitionKey=${partitionKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class PageInteraction {

  async List__GET(location?: string | string): Promise<ApiPageInteraction[] | null> {
    let data = null;
    if (typeof(location) == "object" && location !== null) {
       let paramData = clone(location);
       location = paramData["location"];
    }
    await FetchJSON.fetch(`/api/pageInteraction/list?location=${location}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class ParentBusiness {

  async List__GET(): Promise<BaseParentBusiness[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/parentBusiness/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseParentBusiness | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/parentBusiness/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetPermissions__GET(id?: number | string): Promise<BusinessPermissionsResponse | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/parentBusiness/getPermissions?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: RegisterBusinessModel): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/parentBusiness/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseParentBusiness): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/parentBusiness/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: number): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/parentBusiness/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Pdf {

  async Delete__POST(requestBody?: ApiPDF): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/pdf/delete`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: ApiPDF): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/pdf/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async PDFUpload_get__GET(name: string): Promise<undefined | null> {
    let data = null;
    if (typeof(name) == "object" && name !== null) {
       let paramData = clone(name);
       name = paramData["name"];
    }
    await FetchJSON.fetch(`/api/pdf/${name}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Upload__POST(partitionKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/pdf/upload?partitionKey=${partitionKey}`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Permission {

  async List__GET(): Promise<BasePermission[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/permission/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BasePermission | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/permission/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BasePermission): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/permission/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BasePermission): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/permission/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BasePermission): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/permission/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Picture {

  async List__GET(): Promise<ApiPicture[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/picture/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(rowKey?: string | string): Promise<ApiPicture | null> {
    let data = null;
    if (typeof(rowKey) == "object" && rowKey !== null) {
       let paramData = clone(rowKey);
       rowKey = paramData["rowKey"];
    }
    await FetchJSON.fetch(`/api/picture/get?rowKey=${rowKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: ApiPicture): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/picture/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: ApiPicture): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/picture/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: ApiPicture): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/picture/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Profile {

  async UserInfo__GET(): Promise<UserProfile | null> {
    let data = null;
    await FetchJSON.fetch(`/api/profile/userInfo`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: UserProfile): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/profile/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async UpdateImage__POST(requestBody?: UserProfile): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/profile/updateImage`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Bookings__GET(page?: number | string): Promise<ProfileBookingListResponse | null> {
    let data = null;
    if (typeof(page) == "object" && page !== null) {
       let paramData = clone(page);
       page = paramData["page"];
    }
    await FetchJSON.fetch(`/api/profile/bookings?page=${page}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Recipe {

  async List__GET(): Promise<BaseRecipe[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/recipe/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseRecipe | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/recipe/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseRecipe): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/recipe/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseRecipe): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/recipe/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseRecipe): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/recipe/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Restaurants {

  async List__GET(requestBody?: LocationQueryParams): Promise<LocationResult[] | null> {
    let data = null;
    if (typeof(requestBody) == "object" && requestBody !== null) {
       let paramData = clone(requestBody);
       requestBody = paramData["requestBody"];
    }
    await FetchJSON.fetch(`/api/restaurants/list`, 'GET', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class ScheduledBookingTasks {

  async OverlappingBookingsCheck__POST(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/scheduledBookingTasks/overlappingBookingsCheck`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ArchiveOldBookings__POST(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/scheduledBookingTasks/archiveOldBookings`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async SendBookingReminders__POST(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/scheduledBookingTasks/sendBookingReminders`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async DeleteHeldBookings__POST(): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/scheduledBookingTasks/deleteHeldBookings`, 'POST').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Search {

  async Search__POST(requestBody?: DishSearchRequest): Promise<SearchResult | null> {
    let data = null;
    await FetchJSON.fetch(`/api/search/search`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Shapes {

  async Get__GET(id?: number | string): Promise<BaseShape | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/shapes/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async List__GET(businessId?: number | string): Promise<BaseShape[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/shapes/list?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Insert__PUT(requestBody?: BaseShape): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/shapes/insert`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: BaseShape): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/shapes/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseShape): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/shapes/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class ShiftNotes {

  async List__GET(businessId?: number | string, bookingDate?: string | string): Promise<BaseShiftNote[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
       bookingDate = paramData["bookingDate"];
    }
    await FetchJSON.fetch(`/api/shiftNotes/list?businessId=${businessId}&bookingDate=${bookingDate}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseShiftNote): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/shiftNotes/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseShiftNote): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/shiftNotes/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseShiftNote): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/shiftNotes/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class SupportTicket {

  async List__GET(): Promise<BaseSupportTicket[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicket/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ListForParentBusiness__GET(parentBusinessId?: number | string): Promise<BaseSupportTicket[] | null> {
    let data = null;
    if (typeof(parentBusinessId) == "object" && parentBusinessId !== null) {
       let paramData = clone(parentBusinessId);
       parentBusinessId = paramData["parentBusinessId"];
    }
    await FetchJSON.fetch(`/api/supportTicket/listForParentBusiness?parentBusinessId=${parentBusinessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ListForBusiness__GET(businessId?: number | string): Promise<BaseSupportTicket[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/supportTicket/listForBusiness?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseSupportTicket | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/supportTicket/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseSupportTicket): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicket/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseSupportTicket): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicket/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseSupportTicket): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicket/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class SupportTicketResponse {

  async List__GET(): Promise<BaseSupportTicketResponse[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicketResponse/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(id?: number | string): Promise<BaseSupportTicketResponse | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/supportTicketResponse/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: BaseSupportTicketResponse): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicketResponse/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseSupportTicketResponse): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicketResponse/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: BaseSupportTicketResponse): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/supportTicketResponse/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Tablesetupareas {

  async Get__GET(id?: number | string): Promise<BaseTableSetupArea | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
    }
    await FetchJSON.fetch(`/api/tablesetupareas/get?id=${id}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async List__GET(businessId?: number | string): Promise<BaseTableSetupArea[] | null> {
    let data = null;
    if (typeof(businessId) == "object" && businessId !== null) {
       let paramData = clone(businessId);
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/tablesetupareas/list?businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Insert__PUT(requestBody?: BaseTableSetupArea): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/tablesetupareas/insert`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Update__POST(requestBody?: BaseTableSetupArea): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/tablesetupareas/update`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: DeleteRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/tablesetupareas/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class TagApi {

  async List__GET(type?: string | string): Promise<ApiTag[] | null> {
    let data = null;
    if (typeof(type) == "object" && type !== null) {
       let paramData = clone(type);
       type = paramData["type"];
    }
    await FetchJSON.fetch(`/api/TagApi/list?type=${type}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__GET(name?: string | string, type?: string | string, rowKey?: string | string): Promise<GenericResponse | null> {
    let data = null;
    if (typeof(name) == "object" && name !== null) {
       let paramData = clone(name);
       name = paramData["name"];
       type = paramData["type"];
       rowKey = paramData["rowKey"];
    }
    await FetchJSON.fetch(`/api/TagApi/add?name=${name}&type=${type}&rowKey=${rowKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__POST(requestBody?: ApiTag): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/TagApi/delete`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class UserDetail {

  async List__GET(): Promise<ApiUserDetail[] | null> {
    let data = null;
    await FetchJSON.fetch(`/api/userDetail/list`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Get__GET(rowKey?: string | string): Promise<ApiUserDetail | null> {
    let data = null;
    if (typeof(rowKey) == "object" && rowKey !== null) {
       let paramData = clone(rowKey);
       rowKey = paramData["rowKey"];
    }
    await FetchJSON.fetch(`/api/userDetail/get?rowKey=${rowKey}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: ApiUserDetail): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/userDetail/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: ApiUserDetail): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/userDetail/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Delete__DELETE(requestBody?: ApiUserDetail): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/userDetail/delete`, 'DELETE', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Makebooking {

  async GetEventAvailability__POST(requestBody?: ApiBookingTimesRequest): Promise<WebEventsAvailabilityResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/getEventAvailability`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetForDate__POST(requestBody?: ApiBookingTimesRequest): Promise<DateAndTimeSlotsWidget | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/getForDate`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetNextAvailable__POST(requestBody?: ApiBookingTimesRequest): Promise<ApiBookingTimesWidget | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/getNextAvailable`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetBooking__GET(hash?: string | string): Promise<ClientBookingResponse | null> {
    let data = null;
    if (typeof(hash) == "object" && hash !== null) {
       let paramData = clone(hash);
       hash = paramData["hash"];
    }
    await FetchJSON.fetch(`/api/makebooking/getBooking?hash=${hash}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ConfirmBooking__POST(requestBody?: ConfirmBookingViaWebRequest): Promise<ConfirmBookingResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/confirmBooking`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async CancelBooking__POST(requestBody?: CancelBookingViaWebRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/cancelBooking`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async AmendBooking__POST(requestBody?: PublicAmendBookingRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/amendBooking`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Hold__POST(requestBody?: ApiBookingTimesRequest): Promise<HeldBookingResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/hold`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Book__POST(requestBody?: ApiMakeBookingRequest): Promise<ApiMakeBookingResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/book`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async ReleaseHold__POST(requestBody?: string): Promise<boolean | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/releaseHold`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetClientSetupIntent__POST(requestBody?: ApiPaymentRequest): Promise<IntentResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/getClientSetupIntent`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async GetClientPaymentIntent__POST(requestBody?: ApiPaymentRequest): Promise<IntentResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/makebooking/getClientPaymentIntent`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Weblocation {

  async Getlocation__GET(location?: string | string, withoutStyles?: boolean | string): Promise<ApiBusinessBookingDetails | null> {
    let data = null;
    if (typeof(location) == "object" && location !== null) {
       let paramData = clone(location);
       location = paramData["location"];
       withoutStyles = paramData["withoutStyles"];
    }
    await FetchJSON.fetch(`/api/weblocation/getlocation?location=${location}&withoutStyles=${withoutStyles}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Weborder {

  async Get__GET(id?: number | string, businessId?: number | string): Promise<BaseOrder | null> {
    let data = null;
    if (typeof(id) == "object" && id !== null) {
       let paramData = clone(id);
       id = paramData["id"];
       businessId = paramData["businessId"];
    }
    await FetchJSON.fetch(`/api/weborder/get?id=${id}&businessId=${businessId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Add__PUT(requestBody?: CreateBlankOrderRequest): Promise<CreateOrderResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/weborder/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }


  async Save__POST(requestBody?: BaseOrder): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/weborder/save`, 'POST', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class Webordermenu {

  async GetMenu__GET(locationId?: string | string): Promise<OrderMenuResponse | null> {
    let data = null;
    if (typeof(locationId) == "object" && locationId !== null) {
       let paramData = clone(locationId);
       locationId = paramData["locationId"];
    }
    await FetchJSON.fetch(`/api/webordermenu/getMenu?locationId=${locationId}`, 'GET').then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

class WebsiteContactFormRequest {

  async Add__PUT(requestBody?: BaseWebsiteContactFormRequest): Promise<GenericResponse | null> {
    let data = null;
    await FetchJSON.fetch(`/api/websiteContactFormRequest/add`, 'PUT', requestBody).then(response => data = response).catch(response => LoggingService.Log(JSON.parse(response)))
    return data;
  }

}

export class ApiService {
  constructor(){}

  private static AccountApi: Account = null;
  static get account(): Account {
    if (!this.AccountApi) this.AccountApi = new Account();
    return this.AccountApi;
  }

  private static GoogleaddressApi: Googleaddress = null;
  static get googleaddress(): Googleaddress {
    if (!this.GoogleaddressApi) this.GoogleaddressApi = new Googleaddress();
    return this.GoogleaddressApi;
  }

  private static AdminApi: Admin = null;
  static get admin(): Admin {
    if (!this.AdminApi) this.AdminApi = new Admin();
    return this.AdminApi;
  }

  private static AipromptApi: Aiprompt = null;
  static get aiprompt(): Aiprompt {
    if (!this.AipromptApi) this.AipromptApi = new Aiprompt();
    return this.AipromptApi;
  }

  private static AppUserApi: AppUser = null;
  static get appUser(): AppUser {
    if (!this.AppUserApi) this.AppUserApi = new AppUser();
    return this.AppUserApi;
  }

  private static AuditLogApi: AuditLog = null;
  static get auditLog(): AuditLog {
    if (!this.AuditLogApi) this.AuditLogApi = new AuditLog();
    return this.AuditLogApi;
  }

  private static AutoSuggestionsApiApi: AutoSuggestionsApi = null;
  static get autoSuggestionsApi(): AutoSuggestionsApi {
    if (!this.AutoSuggestionsApiApi) this.AutoSuggestionsApiApi = new AutoSuggestionsApi();
    return this.AutoSuggestionsApiApi;
  }

  private static BlockTimesAreaLinkApi: BlockTimesAreaLink = null;
  static get blockTimesAreaLink(): BlockTimesAreaLink {
    if (!this.BlockTimesAreaLinkApi) this.BlockTimesAreaLinkApi = new BlockTimesAreaLink();
    return this.BlockTimesAreaLinkApi;
  }

  private static BlockTimesTableSelectionApi: BlockTimesTableSelection = null;
  static get blockTimesTableSelection(): BlockTimesTableSelection {
    if (!this.BlockTimesTableSelectionApi) this.BlockTimesTableSelectionApi = new BlockTimesTableSelection();
    return this.BlockTimesTableSelectionApi;
  }

  private static BookingConfigurationSettingApi: BookingConfigurationSetting = null;
  static get bookingConfigurationSetting(): BookingConfigurationSetting {
    if (!this.BookingConfigurationSettingApi) this.BookingConfigurationSettingApi = new BookingConfigurationSetting();
    return this.BookingConfigurationSettingApi;
  }

  private static BookingDurationRulesApi: BookingDurationRules = null;
  static get bookingDurationRules(): BookingDurationRules {
    if (!this.BookingDurationRulesApi) this.BookingDurationRulesApi = new BookingDurationRules();
    return this.BookingDurationRulesApi;
  }

  private static BookingManagementApi: BookingManagement = null;
  static get bookingManagement(): BookingManagement {
    if (!this.BookingManagementApi) this.BookingManagementApi = new BookingManagement();
    return this.BookingManagementApi;
  }

  private static BookingNotificationApi: BookingNotification = null;
  static get bookingNotification(): BookingNotification {
    if (!this.BookingNotificationApi) this.BookingNotificationApi = new BookingNotification();
    return this.BookingNotificationApi;
  }

  private static BookingPacingRulesApi: BookingPacingRules = null;
  static get bookingPacingRules(): BookingPacingRules {
    if (!this.BookingPacingRulesApi) this.BookingPacingRulesApi = new BookingPacingRules();
    return this.BookingPacingRulesApi;
  }

  private static BookingPaymentApi: BookingPayment = null;
  static get bookingPayment(): BookingPayment {
    if (!this.BookingPaymentApi) this.BookingPaymentApi = new BookingPayment();
    return this.BookingPaymentApi;
  }

  private static BookingsApi: Bookings = null;
  static get bookings(): Bookings {
    if (!this.BookingsApi) this.BookingsApi = new Bookings();
    return this.BookingsApi;
  }

  private static BookingsetupApi: Bookingsetup = null;
  static get bookingsetup(): Bookingsetup {
    if (!this.BookingsetupApi) this.BookingsetupApi = new Bookingsetup();
    return this.BookingsetupApi;
  }

  private static BookingStatisticsApi: BookingStatistics = null;
  static get bookingStatistics(): BookingStatistics {
    if (!this.BookingStatisticsApi) this.BookingStatisticsApi = new BookingStatistics();
    return this.BookingStatisticsApi;
  }

  private static BookingStopSaleApi: BookingStopSale = null;
  static get bookingStopSale(): BookingStopSale {
    if (!this.BookingStopSaleApi) this.BookingStopSaleApi = new BookingStopSale();
    return this.BookingStopSaleApi;
  }

  private static BookingtablesetupApi: Bookingtablesetup = null;
  static get bookingtablesetup(): Bookingtablesetup {
    if (!this.BookingtablesetupApi) this.BookingtablesetupApi = new Bookingtablesetup();
    return this.BookingtablesetupApi;
  }

  private static BookingtimeblockApi: Bookingtimeblock = null;
  static get bookingtimeblock(): Bookingtimeblock {
    if (!this.BookingtimeblockApi) this.BookingtimeblockApi = new Bookingtimeblock();
    return this.BookingtimeblockApi;
  }

  private static BusinessApi: Business = null;
  static get business(): Business {
    if (!this.BusinessApi) this.BusinessApi = new Business();
    return this.BusinessApi;
  }

  private static BusinessClientApi: BusinessClient = null;
  static get businessClient(): BusinessClient {
    if (!this.BusinessClientApi) this.BusinessClientApi = new BusinessClient();
    return this.BusinessClientApi;
  }

  private static BusinessOpeningTimeExceptionApi: BusinessOpeningTimeException = null;
  static get businessOpeningTimeException(): BusinessOpeningTimeException {
    if (!this.BusinessOpeningTimeExceptionApi) this.BusinessOpeningTimeExceptionApi = new BusinessOpeningTimeException();
    return this.BusinessOpeningTimeExceptionApi;
  }

  private static BusinessOpeningTimesRangeApi: BusinessOpeningTimesRange = null;
  static get businessOpeningTimesRange(): BusinessOpeningTimesRange {
    if (!this.BusinessOpeningTimesRangeApi) this.BusinessOpeningTimesRangeApi = new BusinessOpeningTimesRange();
    return this.BusinessOpeningTimesRangeApi;
  }

  private static BusinessSearchApi: BusinessSearch = null;
  static get businessSearch(): BusinessSearch {
    if (!this.BusinessSearchApi) this.BusinessSearchApi = new BusinessSearch();
    return this.BusinessSearchApi;
  }

  private static BusinessServerApi: BusinessServer = null;
  static get businessServer(): BusinessServer {
    if (!this.BusinessServerApi) this.BusinessServerApi = new BusinessServer();
    return this.BusinessServerApi;
  }

  private static BusinessVerificationApi: BusinessVerification = null;
  static get businessVerification(): BusinessVerification {
    if (!this.BusinessVerificationApi) this.BusinessVerificationApi = new BusinessVerification();
    return this.BusinessVerificationApi;
  }

  private static CheckoutApi: Checkout = null;
  static get checkout(): Checkout {
    if (!this.CheckoutApi) this.CheckoutApi = new Checkout();
    return this.CheckoutApi;
  }

  private static CombinationGroupApi: CombinationGroup = null;
  static get combinationGroup(): CombinationGroup {
    if (!this.CombinationGroupApi) this.CombinationGroupApi = new CombinationGroup();
    return this.CombinationGroupApi;
  }

  private static ComponentBuilderApiApi: ComponentBuilderApi = null;
  static get componentBuilderApi(): ComponentBuilderApi {
    if (!this.ComponentBuilderApiApi) this.ComponentBuilderApiApi = new ComponentBuilderApi();
    return this.ComponentBuilderApiApi;
  }

  private static DepositRuleApi: DepositRule = null;
  static get depositRule(): DepositRule {
    if (!this.DepositRuleApi) this.DepositRuleApi = new DepositRule();
    return this.DepositRuleApi;
  }

  private static DevtestApi: Devtest = null;
  static get devtest(): Devtest {
    if (!this.DevtestApi) this.DevtestApi = new Devtest();
    return this.DevtestApi;
  }

  private static DishApi: Dish = null;
  static get dish(): Dish {
    if (!this.DishApi) this.DishApi = new Dish();
    return this.DishApi;
  }

  private static DishBuzzWordApi: DishBuzzWord = null;
  static get dishBuzzWord(): DishBuzzWord {
    if (!this.DishBuzzWordApi) this.DishBuzzWordApi = new DishBuzzWord();
    return this.DishBuzzWordApi;
  }

  private static DishIngredientLinkApi: DishIngredientLink = null;
  static get dishIngredientLink(): DishIngredientLink {
    if (!this.DishIngredientLinkApi) this.DishIngredientLinkApi = new DishIngredientLink();
    return this.DishIngredientLinkApi;
  }

  private static DishOptionGroupApi: DishOptionGroup = null;
  static get dishOptionGroup(): DishOptionGroup {
    if (!this.DishOptionGroupApi) this.DishOptionGroupApi = new DishOptionGroup();
    return this.DishOptionGroupApi;
  }

  private static DishRatingApi: DishRating = null;
  static get dishRating(): DishRating {
    if (!this.DishRatingApi) this.DishRatingApi = new DishRating();
    return this.DishRatingApi;
  }

  private static DishTagLinkApi: DishTagLink = null;
  static get dishTagLink(): DishTagLink {
    if (!this.DishTagLinkApi) this.DishTagLinkApi = new DishTagLink();
    return this.DishTagLinkApi;
  }

  private static ExperienceApi: Experience = null;
  static get experience(): Experience {
    if (!this.ExperienceApi) this.ExperienceApi = new Experience();
    return this.ExperienceApi;
  }

  private static ExperienceAreaLinkApi: ExperienceAreaLink = null;
  static get experienceAreaLink(): ExperienceAreaLink {
    if (!this.ExperienceAreaLinkApi) this.ExperienceAreaLinkApi = new ExperienceAreaLink();
    return this.ExperienceAreaLinkApi;
  }

  private static ExperienceTableSelectionApi: ExperienceTableSelection = null;
  static get experienceTableSelection(): ExperienceTableSelection {
    if (!this.ExperienceTableSelectionApi) this.ExperienceTableSelectionApi = new ExperienceTableSelection();
    return this.ExperienceTableSelectionApi;
  }

  private static GlobalAdminPermissionApi: GlobalAdminPermission = null;
  static get globalAdminPermission(): GlobalAdminPermission {
    if (!this.GlobalAdminPermissionApi) this.GlobalAdminPermissionApi = new GlobalAdminPermission();
    return this.GlobalAdminPermissionApi;
  }

  private static ImageApi: Image = null;
  static get image(): Image {
    if (!this.ImageApi) this.ImageApi = new Image();
    return this.ImageApi;
  }

  private static IngredientApi: Ingredient = null;
  static get ingredient(): Ingredient {
    if (!this.IngredientApi) this.IngredientApi = new Ingredient();
    return this.IngredientApi;
  }

  private static MembershipApi: Membership = null;
  static get membership(): Membership {
    if (!this.MembershipApi) this.MembershipApi = new Membership();
    return this.MembershipApi;
  }

  private static MenuApi: Menu = null;
  static get menu(): Menu {
    if (!this.MenuApi) this.MenuApi = new Menu();
    return this.MenuApi;
  }

  private static MenuBuilderApiApi: MenuBuilderApi = null;
  static get menuBuilderApi(): MenuBuilderApi {
    if (!this.MenuBuilderApiApi) this.MenuBuilderApiApi = new MenuBuilderApi();
    return this.MenuBuilderApiApi;
  }

  private static MenuSectionApi: MenuSection = null;
  static get menuSection(): MenuSection {
    if (!this.MenuSectionApi) this.MenuSectionApi = new MenuSection();
    return this.MenuSectionApi;
  }

  private static MenuServingTimeRangeApi: MenuServingTimeRange = null;
  static get menuServingTimeRange(): MenuServingTimeRange {
    if (!this.MenuServingTimeRangeApi) this.MenuServingTimeRangeApi = new MenuServingTimeRange();
    return this.MenuServingTimeRangeApi;
  }

  private static PageApi: Page = null;
  static get page(): Page {
    if (!this.PageApi) this.PageApi = new Page();
    return this.PageApi;
  }

  private static PageInteractionApi: PageInteraction = null;
  static get pageInteraction(): PageInteraction {
    if (!this.PageInteractionApi) this.PageInteractionApi = new PageInteraction();
    return this.PageInteractionApi;
  }

  private static ParentBusinessApi: ParentBusiness = null;
  static get parentBusiness(): ParentBusiness {
    if (!this.ParentBusinessApi) this.ParentBusinessApi = new ParentBusiness();
    return this.ParentBusinessApi;
  }

  private static PdfApi: Pdf = null;
  static get pdf(): Pdf {
    if (!this.PdfApi) this.PdfApi = new Pdf();
    return this.PdfApi;
  }

  private static PermissionApi: Permission = null;
  static get permission(): Permission {
    if (!this.PermissionApi) this.PermissionApi = new Permission();
    return this.PermissionApi;
  }

  private static PictureApi: Picture = null;
  static get picture(): Picture {
    if (!this.PictureApi) this.PictureApi = new Picture();
    return this.PictureApi;
  }

  private static ProfileApi: Profile = null;
  static get profile(): Profile {
    if (!this.ProfileApi) this.ProfileApi = new Profile();
    return this.ProfileApi;
  }

  private static RecipeApi: Recipe = null;
  static get recipe(): Recipe {
    if (!this.RecipeApi) this.RecipeApi = new Recipe();
    return this.RecipeApi;
  }

  private static RestaurantsApi: Restaurants = null;
  static get restaurants(): Restaurants {
    if (!this.RestaurantsApi) this.RestaurantsApi = new Restaurants();
    return this.RestaurantsApi;
  }

  private static ScheduledBookingTasksApi: ScheduledBookingTasks = null;
  static get scheduledBookingTasks(): ScheduledBookingTasks {
    if (!this.ScheduledBookingTasksApi) this.ScheduledBookingTasksApi = new ScheduledBookingTasks();
    return this.ScheduledBookingTasksApi;
  }

  private static SearchApi: Search = null;
  static get search(): Search {
    if (!this.SearchApi) this.SearchApi = new Search();
    return this.SearchApi;
  }

  private static ShapesApi: Shapes = null;
  static get shapes(): Shapes {
    if (!this.ShapesApi) this.ShapesApi = new Shapes();
    return this.ShapesApi;
  }

  private static ShiftNotesApi: ShiftNotes = null;
  static get shiftNotes(): ShiftNotes {
    if (!this.ShiftNotesApi) this.ShiftNotesApi = new ShiftNotes();
    return this.ShiftNotesApi;
  }

  private static SupportTicketApi: SupportTicket = null;
  static get supportTicket(): SupportTicket {
    if (!this.SupportTicketApi) this.SupportTicketApi = new SupportTicket();
    return this.SupportTicketApi;
  }

  private static SupportTicketResponseApi: SupportTicketResponse = null;
  static get supportTicketResponse(): SupportTicketResponse {
    if (!this.SupportTicketResponseApi) this.SupportTicketResponseApi = new SupportTicketResponse();
    return this.SupportTicketResponseApi;
  }

  private static TablesetupareasApi: Tablesetupareas = null;
  static get tablesetupareas(): Tablesetupareas {
    if (!this.TablesetupareasApi) this.TablesetupareasApi = new Tablesetupareas();
    return this.TablesetupareasApi;
  }

  private static TagApiApi: TagApi = null;
  static get tagApi(): TagApi {
    if (!this.TagApiApi) this.TagApiApi = new TagApi();
    return this.TagApiApi;
  }

  private static UserDetailApi: UserDetail = null;
  static get userDetail(): UserDetail {
    if (!this.UserDetailApi) this.UserDetailApi = new UserDetail();
    return this.UserDetailApi;
  }

  private static MakebookingApi: Makebooking = null;
  static get makebooking(): Makebooking {
    if (!this.MakebookingApi) this.MakebookingApi = new Makebooking();
    return this.MakebookingApi;
  }

  private static WeblocationApi: Weblocation = null;
  static get weblocation(): Weblocation {
    if (!this.WeblocationApi) this.WeblocationApi = new Weblocation();
    return this.WeblocationApi;
  }

  private static WeborderApi: Weborder = null;
  static get weborder(): Weborder {
    if (!this.WeborderApi) this.WeborderApi = new Weborder();
    return this.WeborderApi;
  }

  private static WebordermenuApi: Webordermenu = null;
  static get webordermenu(): Webordermenu {
    if (!this.WebordermenuApi) this.WebordermenuApi = new Webordermenu();
    return this.WebordermenuApi;
  }

  private static WebsiteContactFormRequestApi: WebsiteContactFormRequest = null;
  static get websiteContactFormRequest(): WebsiteContactFormRequest {
    if (!this.WebsiteContactFormRequestApi) this.WebsiteContactFormRequestApi = new WebsiteContactFormRequest();
    return this.WebsiteContactFormRequestApi;
  }
}
