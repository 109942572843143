import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { Style } from "@react-pdf/types";

import { IconProps } from './Icon';

import { mergeStyles } from '../utils';
import { FontAwesomeGlyphs } from './enums';

interface FontIconProps extends Omit<IconProps, 'size' | 'src'> {
  codepoint?: string;
  fontFamily?: string;
  fontWeight?: Style['fontWeight'];
  name?: string;
}

class FontIcon extends React.PureComponent<FontIconProps> {
  render() {
    const { props } = this;
    const { codepoint = FontAwesomeGlyphs[props.name] } = props;

    if (!codepoint) {
      return null;
    }

    const iconStyle: Style = {
      width: 16,
      marginTop: 1,
      fontFamily: props.fontFamily || 'FontAwesome',
      fontWeight: props.fontWeight || 'black'
    };

    return (
      <View debug={props.debug} style={mergeStyles(iconStyle, props.style)}>
        <Text>{codepoint}</Text>
      </View>
    );
  }
}

export { FontIcon };
