export enum HAlignEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}

export enum VAlignEnum {
  TOP = 'flex-start',
  MIDDLE = 'center',
  BOTTOM = 'flex-end'
}
