import React from "react";

import { Style } from "@react-pdf/types";

import { DEFAULTLISTITEMPROPS, DEFAULTLISTPROPS } from "./constants";

import { VAlignEnum } from "../enums";
import { ListTypeEnum, MarkerLocationEnum, MarkerTypeEnum } from "./enums";

import { mergeStyles, setDefaultProps } from "../utils";

import BaseComponent, { BaseComponentProps } from "../Base/BaseComponent";

import ListItem, { ListItemProps } from "./ListItem";

export interface ListProps extends BaseComponentProps {
  compact?: boolean;
  glyph?: string;
  icon?: string;
  inline?: boolean;
  listType?: ListTypeEnum;
  markerColor?: string;
  markerLocation?: MarkerLocationEnum;
  markerType?: MarkerTypeEnum;
  markerVAlign?: VAlignEnum;
  markerWeight?: Style['fontWeight'];
  markerWidth?: number;
  reversed?: boolean;
  start?: number;
}

class List extends BaseComponent<ListProps> {
  defaultStyle: Style = {
    flexDirection: 'column',
    lineHeight: 1.4
  };

  getCursor(start: number, index: number, length: number, reversed: boolean) {
    return reversed ? (start || length) - index : (start || 1) + index;
  }

  get listItemStyle() {
    const self = this;
    const { props } = self;
    const style: Style = {};

    if (props.compact) {
      style.marginTop = 0;
      style.marginBottom = 2;
    }

    if (props.inline) {
      style.marginRight = 4;
    }

    return style;
  }

  listItem(item: any, props: ListItemProps) {
    const defaultProps = { ...DEFAULTLISTITEMPROPS };

    if (item.type === ListItem) {
      Object.assign(props, item.props);

      return React.cloneElement(
        item,
        setDefaultProps({ defaultProps, element: item, props })
      );
    }

    return (
      <ListItem {...defaultProps} {...props}>
        {item}
      </ListItem>
    );
  }

  renderChildren(): JSX.Element | JSX.Element[] {
    const self = this;
    const props = { ...self.props };
    delete props.debugChildren;

    const propsStyle: Style = mergeStyles(props.style, self.listItemStyle);
    delete props.style;
    delete propsStyle.width;
    Object.keys(propsStyle).forEach(key => {
      if (key.startsWith('flex')) {
        delete propsStyle[key];
      }
    });

    const { reversed, start = 0 } = props;

    return React.Children
      .toArray(props.children)
      .map((child: React.ReactElement, index: number, { length }) => {
        const cursor = self.getCursor(start, index, length, reversed);
        const listItemProps: ListItemProps = {
          ...props,
          debug: self.props.debugChildren,
          index: cursor,
          key: `listitem_${index}`,
          style: mergeStyles(propsStyle, child.props.style)
        };

        if (child.type === React.Fragment) {
          console.warn('React.Fragment found wrapping list item content: be sure that you need to use this');
        }

        return self.listItem(child, listItemProps);
      });
  }

  render() {
    const self = this;
    const { compact, inline } = self.props;

    if (compact) {
      self.style = { lineHeight: '80%' };
    }

    if (inline) {
      self.style = {
        flexDirection: 'row',
        flexWrap: 'wrap'
      };
    }

    return super.render(DEFAULTLISTPROPS);
  }
}

export default List;
export { List };
