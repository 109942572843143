// This is generated code do not edit.

export interface GenericResponse {
  success?: boolean;
  info?: string;
}

export interface UserAccountDetails {
  userName?: string;
  firstName?: string;
  surname?: string;
  email?: string;
  imageUrl?: string;
  isMe?: boolean;
  isSuperAdmin?: boolean;
  isBusinessUser?: boolean;
  needsOnboarding?: boolean;
  isVerified?: boolean;
}

export enum AuthType {
  Unknown = 'Unknown',
  Google = 'Google',
  Facebook = 'Facebook',
}

export interface ThirdPartyLogin {
  email: string;
  authToken: string;
  authType: AuthType;
  username?: string;
  firstName?: string;
  surname?: string;
  isBusiness?: boolean;
}

export interface RegisterViewModel {
  email: string;
  username: string;
  firstName?: string;
  surname?: string;
  isBusiness?: boolean;
  password: string;
  confirmPassword: string;
}

export interface ForgotPasswordViewModel {
  email: string;
}

export interface ChangePasswordViewModel {
  oldPassword: string;
  password: string;
  confirmPassword?: string;
}

export interface ResetPasswordViewModel {
  id: string;
  password: string;
  confirmPassword: string;
  code: string;
}

export interface LoginViewModel {
  email: string;
  password: string;
  rememberMe?: boolean;
  googleAuthToken?: string;
}

export interface BaseEventAddress {
  id: number;
  formattedName?: string;
  formattedAddress?: string;
  road?: string;
  city?: string;
  googleMapsUrl?: string;
  streetNumber?: string;
  subPremiseAddress?: string;
  county?: string;
  country?: string;
  countryCode?: string;
  postCode?: string;
  timeZone?: string;
  timeZoneId?: string;
  longitude: number;
  latitude: number;
  experienceId?: number;
}

export interface AddEventAddressRequest {
  placeId?: string;
  eventId?: number;
  manualAddress?: BaseEventAddress;
}

export interface OverlappingBooking {
  businessName?: string;
  slotDate?: string;
  startDateTime?: string;
  endDateTime?: string;
  locationId?: string;
  tableName?: string;
  bookingReference?: string;
}

export interface AdminBookingDetails {
  bookingReference?: string;
  businessName?: string;
  amendmentLink?: string;
  businessLocationId?: string;
  descriptiveStatus?: string;
  bookingWidgetLink?: string;
  confirmationLink?: string;
  confirmationStatus?: string;
}

export interface BaseAppUser {
  userName: string;
  firstName?: string;
  surname?: string;
  needsOnboarding?: boolean;
  fullName?: string;
  imageUrl?: string;
  customerPaymentId?: string;
}

export enum MembershipType {
  Free = 'Free',
  BusinessBasic = 'BusinessBasic',
  BusinessStandard = 'BusinessStandard',
  BusinessPremium = 'BusinessPremium',
  UserBasic = 'UserBasic',
  UserStandard = 'UserStandard',
  UserPremium = 'UserPremium',
}

export interface BaseMembership {
  id?: number;
  name?: string;
  description?: string;
  type?: MembershipType;
  price?: number;
  users?: BaseAppUser[];
  businesses?: BaseBusiness[];
}

export enum BusinessType {
  Unknown = 'Unknown',
  RestaurantOrCafe = 'RestaurantOrCafe',
  EscapeRoom = 'EscapeRoom',
  Hairdresser = 'Hairdresser',
  Gym = 'Gym',
  Spa = 'Spa',
  Entertainment = 'Entertainment',
}

export enum MenuType {
  Other = 'Other',
  Specials = 'Specials',
  Starters = 'Starters',
  ALaCarte = 'ALaCarte',
  Childrens = 'Childrens',
  FixedPrice = 'FixedPrice',
  TastingMenu = 'TastingMenu',
  AfternoonTea = 'AfternoonTea',
  Breakfast = 'Breakfast',
  Lunch = 'Lunch',
  Brunch = 'Brunch',
  Dessert = 'Dessert',
  Christmas = 'Christmas',
  IceCream = 'IceCream',
  Vegetarian = 'Vegetarian',
  Vegan = 'Vegan',
  GlutenFree = 'GlutenFree',
  DairyFree = 'DairyFree',
  Drinks = 'Drinks',
  Buffet = 'Buffet',
  WineList = 'WineList',
  Easter = 'Easter',
}

export enum SectionType {
  NotSet = 'NotSet',
  Food = 'Food',
  Drink = 'Drink',
}

export enum AllergenStatus {
  NotSet = 'NotSet',
  MayContain = 'MayContain',
  NoAllergens = 'NoAllergens',
  HasAllergens = 'HasAllergens',
}

export enum SpiceLevel {
  NotSpicy = 'NotSpicy',
  Mild = 'Mild',
  Medium = 'Medium',
  Hot = 'Hot',
  ExtraHot = 'ExtraHot',
  ExtremelyHot = 'ExtremelyHot',
}

export interface BaseIngredient {
  id?: number;
  name: string;
}

export interface BaseDishIngredientLink {
  id?: number;
  dishId: number;
  ingredientId: number;
  ingredient?: BaseIngredient;
}

export enum TagType {
  Vegan = 'Vegan',
  Vegetarian = 'Vegetarian',
  Pescatarian = 'Pescatarian',
  Halal = 'Halal',
  Kosher = 'Kosher',
  Organic = 'Organic',
  ContainsAlcohol = 'ContainsAlcohol',
  DairyFree = 'DairyFree',
  GlutenFree = 'GlutenFree',
  Celery = 'Celery',
  Gluten = 'Gluten',
  Crustaceans = 'Crustaceans',
  Eggs = 'Eggs',
  Fish = 'Fish',
  Lupin = 'Lupin',
  Milk = 'Milk',
  Molluscs = 'Molluscs',
  Mustard = 'Mustard',
  Nuts = 'Nuts',
  Peanuts = 'Peanuts',
  SesameSeeds = 'SesameSeeds',
  Soya = 'Soya',
  SulphurDioxide = 'SulphurDioxide',
}

export enum ObjectStates {
  Unchanged = 'Unchanged',
  Modified = 'Modified',
  Added = 'Added',
  Deleted = 'Deleted',
}

export interface BaseDishTagLink {
  id?: number;
  dishId: number;
  tagType: TagType;
  isAllergen?: boolean;
  tagDescription?: string;
  state?: ObjectStates;
}

export interface BaseDishBuzzWord {
  id?: number;
  dishId: number;
  word: string;
}

export enum DishOptionGroupType {
  NotSet = 'NotSet',
  DishExtra = 'DishExtra',
  DishVariation = 'DishVariation',
}

export interface BaseDishOption {
  id: number;
  name: string;
  calories?: number;
  price?: number;
  toBeDeleted?: boolean;
  dishOptionGroupId: number;
}

export interface BaseDishOptionGroup {
  id: number;
  groupName: string;
  isRequired: boolean;
  minimumSelectableItems: number;
  maximumSelectableItems?: number;
  dishId: number;
  groupType?: DishOptionGroupType;
  dishOptions?: BaseDishOption[];
  toBeDeleted?: boolean;
}

export interface BaseDish {
  id?: number;
  name: string;
  businessId?: number;
  menuId: number;
  menuSectionId: number;
  description?: string;
  imageUrl?: string;
  hidden?: boolean;
  archived?: boolean;
  canBeGlutenFree?: boolean;
  canBeDairyFree?: boolean;
  allergenDetailsProvided?: boolean;
  allergenStatus: AllergenStatus;
  calorieCount?: number;
  price?: number;
  isNew?: boolean;
  isDrink?: boolean;
  spiceLevel: SpiceLevel;
  dishIngredients?: BaseDishIngredientLink[];
  dishTags?: BaseDishTagLink[];
  dishBuzzWords?: BaseDishBuzzWord[];
  dishOptionGroups?: BaseDishOptionGroup[];
  grabImage?: string;
  parentBusinessId: number;
}

export interface BaseMenuSection {
  id?: number;
  name: string;
  description?: string;
  menuId: number;
  sortOrder: number;
  businessId?: number;
  sectionType?: SectionType;
  isNew?: boolean;
  dishes?: BaseDish[];
  parentBusinessId: number;
}

export enum DayOfWeek {
  NotSet = 'NotSet',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export interface BaseMenuServingTimeRange {
  id: number;
  dayOfWeek: DayOfWeek;
  timeFrom?: string;
  timeTo?: string;
  notRunning: boolean;
  menuId: number;
  menu?: BaseMenu;
  parentBusinessId?: number;
  parentBusiness?: BaseParentBusiness;
  businessId?: number;
  business?: BaseBusiness;
}

export interface BaseMenu {
  id?: number;
  name: string;
  description?: string;
  type: MenuType;
  isSeasonal?: boolean;
  isActive: boolean;
  validFrom?: string;
  validTo?: string;
  isNew?: boolean;
  businessId?: number;
  parentBusinessId: number;
  dailySpecialsMenu?: boolean;
  menuSections?: BaseMenuSection[];
  servingTimes?: BaseMenuServingTimeRange[];
}

export interface BaseBusinessTag {
  id: number;
  parentBusinessId: number;
  tagDescription?: string;
  deleted?: boolean;
}

export interface BaseClientParentBusinessLink {
  id: number;
  parentBusinessId: number;
  clientId: number;
  notes?: string;
  marketing?: boolean;
  textUpdates?: boolean;
}

export interface BaseParentBusiness {
  id?: number;
  name: string;
  type: BusinessType;
  stripeEnabled?: boolean;
  typeDescription?: string;
  menus?: BaseMenu[];
  tags?: BaseBusinessTag[];
  clientDetails?: BaseClientParentBusinessLink[];
  isDisabled?: boolean;
  customerPaymentId?: string;
}

export enum TablePrioritisation {
  BestFitFirst = 'BestFitFirst',
  HighestPriorityFirst = 'HighestPriorityFirst',
}

export enum AreaPrioritisation {
  SearchInPriorityAreasFirst = 'SearchInPriorityAreasFirst',
  SearchInAllAreas = 'SearchInAllAreas',
}

export enum WidgetImageLayout {
  NotSet = 'NotSet',
  Top = 'Top',
  Left = 'Left',
}

export interface BaseBookingConfigurationSetting {
  id: number;
  defaultChangeoverTimeInMinutes: number;
  defaultTableDurationInMinutes: number;
  maximumBookableParty?: number;
  specialOccasions?: string;
  primaryColour?: string;
  primaryContrastColour?: string;
  backgroundColour?: string;
  textColour?: string;
  buttonColour?: string;
  buttonTextColour?: string;
  headerBackgroundColour?: string;
  reserveButtonTextStepOne?: string;
  reserveButtonTextStepTwo?: string;
  businessId?: number;
  bookingsEmailAddress?: string;
  bannerImageUrl?: string;
  headerLogoUrl?: string;
  receiptLogoUrl?: string;
  vatNumber?: string;
  showTimesAsBlocks?: boolean;
  tablePrioritisation?: TablePrioritisation;
  areaPrioritisation?: AreaPrioritisation;
  autoCombineTablesWhenBooking?: boolean;
  preferCombinedTablesIfBetterFit?: boolean;
  receiveClientConfirmationEmails?: boolean;
  maximumNumberOfFutureDaysShownOnWebCalendar?: number;
  bookingConfirmationEmailText?: string;
  standardBookingDescription?: string;
  standardBookingImageUrl?: string;
  standardBookingName?: string;
  standardBookingImageLayout?: WidgetImageLayout;
}

export interface BaseLocationAddress {
  id: number;
  formattedName?: string;
  formattedAddress?: string;
  road?: string;
  city?: string;
  googleMapsUrl?: string;
  streetNumber?: string;
  subPremiseAddress?: string;
  county?: string;
  country?: string;
  countryCode?: string;
  postCode?: string;
  timeZone?: string;
  timeZoneId?: string;
  longitude: number;
  latitude: number;
  businessId?: number;
}

export enum OperationalStatus {
  Unknown = 'Unknown',
  Operational = 'Operational',
  TemporarilyClosed = 'TemporarilyClosed',
  PermanentlyClosed = 'PermanentlyClosed',
}

export enum VerificationStatus {
  Pending = 'Pending',
  Verified = 'Verified',
  Rejected = 'Rejected',
  IncorrectCodeEntered = 'IncorrectCodeEntered',
}

export interface BasePermission {
  id: number;
  isAdminPermission: boolean;
  userId: number;
  businessId: number;
}

export interface BaseBusinessVerification {
  id: number;
  failCount: number;
  code: string;
  sent: boolean;
  businessId: number;
}

export enum BookingSourceToBlock {
  OnlineOnly = 'OnlineOnly',
  OnlineAndInHouse = 'OnlineAndInHouse',
}

export interface BaseBlockTimesTableSelection {
  id: number;
  setupTableId: number;
  timeBlockId: number;
  areaLinkId: number;
}

export interface BaseBlockTimesAreaLink {
  id: number;
  setupAreaId: number;
  wholeAreaSelected: boolean;
  timeBlockId: number;
  tableSelections?: BaseBlockTimesTableSelection[];
}

export interface BaseBookingTimeBlock {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  experienceId?: number;
  dateFrom?: string;
  dateTo?: string;
  timeFrom?: string;
  timeTo?: string;
  id?: number;
  bookingSourceToBlock?: BookingSourceToBlock;
  timeToIsNextDay?: boolean;
  deleted?: boolean;
  businessId?: number;
  appliedToAreas?: boolean;
  areaLinks?: BaseBlockTimesAreaLink[];
}

export enum EnumWidgetFieldType {
  Unset = 'Unset',
  Text = 'Text',
  Number = 'Number',
  Dropdown = 'Dropdown',
}

export interface BaseCustomWidgetField {
  id?: number;
  label?: string;
  type?: EnumWidgetFieldType;
  values?: string;
  required?: boolean;
  businessId?: number;
  deleted?: boolean;
}

export interface BaseExperienceTableSelection {
  id: number;
  setupTableId: number;
  experienceId: number;
  areaLinkId: number;
}

export interface BaseOrder {
  id: number;
  orderNumber: number;
  tableName: string;
  tableId: number;
  businessId: number;
  bookingId?: number;
  clientId?: number;
  createdDateTime: string;
  createdDate: string;
  offlinePayment?: boolean;
  totalExcludingServiceCharge?: number;
  totalIncludingServiceCharge?: number;
  serviceCharge?: number;
  vatAmount?: number;
}

export interface BaseBookingTableSetup {
  id?: number;
  seats: number;
  minimumOccupancy?: number;
  shape: string;
  x: number;
  y: number;
  rotation: number;
  areaId?: number;
  tableName?: string;
  bookableOnline?: boolean;
  isTableExtension?: boolean;
  priority?: number;
  businessId?: number;
  experienceTableSelections?: BaseExperienceTableSelection[];
  timeBlocksTableSelections?: BaseBlockTimesTableSelection[];
  orders?: BaseOrder[];
}

export interface BaseShape {
  id?: number;
  width: number;
  height: number;
  shape: string;
  x: number;
  y: number;
  rotation: number;
  fill?: string;
  text?: string;
  areaId?: number;
  businessId?: number;
}

export interface BaseTableSetupArea {
  id?: number;
  name: string;
  scale?: number;
  businessId?: number;
  experienceId?: number;
  bookableOnline?: boolean;
  active?: boolean;
  priority?: number;
}

export enum SpecialOccasion {
  NotSet = 'NotSet',
  Birthday = 'Birthday',
  Anniversary = 'Anniversary',
  DateNight = 'DateNight',
  SpecialOccasion = 'SpecialOccasion',
  BusinessMeal = 'BusinessMeal',
  NewJob = 'NewJob',
  Graduation = 'Graduation',
}

export enum ExperienceType {
  NotSet = 'NotSet',
  Event = 'Event',
  Experience = 'Experience',
}

export enum BookingStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  Seated = 'Seated',
  Cancelled = 'Cancelled',
  NoShow = 'NoShow',
  PartiallySeated = 'PartiallySeated',
  Arrived = 'Arrived',
}

export enum BookingSource {
  Unknown = 'Unknown',
  InHouse = 'InHouse',
  Web = 'Web',
}

export interface BaseClient {
  id?: number;
  businessId?: number;
  parentBusinessId?: number;
  firstName: string;
  surname?: string;
  fullName?: string;
  email?: string;
  telephone?: string;
  telephoneWithInternationalDialingCode?: string;
  telephoneCountryCode?: string;
  isGlobalWalkInRecord?: boolean;
  marketingUpdates?: boolean;
  platformMarketingUpdates?: boolean;
  orders?: BaseOrder[];
}

export interface BaseBookedTable {
  id: number;
  allocatedGuests: number;
  setupTableId: number;
  bookingId: number;
  tableName?: string;
}

export interface BaseBookingPayment {
  id: number;
  businessId: number;
  bookingId: number;
  clientId: number;
  intentId: string;
  setupIntent: boolean;
  amount: number;
  currency: string;
  fufilled: boolean;
  terminalPayment?: boolean;
  depositRuleId?: number;
  refundedAmount?: number;
  transationDate?: string;
}

export interface BaseActiveBooking {
  id?: number;
  guests: number;
  slotDate?: string;
  startDateTime: string;
  endDateTime: string;
  endDateTimeWithoutChangeover?: string;
  modifiedDateTimeUTC?: string;
  cancelledDateUtc?: string;
  bookedAtUtc: string;
  bookedAtInBusinessTimezone?: string;
  confirmationEmailSentCount?: number;
  isWalkIn?: boolean;
  confirmed?: boolean;
  specialRequests?: string;
  dietaryRequirements?: string;
  customFields?: string;
  specialOccasion?: SpecialOccasion;
  notes?: string;
  businessId?: number;
  experienceType?: ExperienceType;
  parentBusinessId?: number;
  status?: BookingStatus;
  bookingSource?: BookingSource;
  bookingDurationInMinutes?: number;
  bookingChangeoverDurationInMinutes?: number;
  totalDurationIncludingChangeover?: number;
  bookingReference?: string;
  clientId?: number;
  experienceId?: number;
  clientParentBusinessLinkId?: number;
  bookingUniqueId?: string;
  shiftSortOrder?: number;
  fallsOutsideValidShifts?: boolean;
  hasOustandingPayments?: boolean;
  hasFufilledPayments?: boolean;
  client?: BaseClient;
  clientParentBusinessLink?: BaseClientParentBusinessLink;
  isHeld?: boolean;
  tables?: number[];
  bookedTables?: BaseBookedTable[];
  bookingPayments?: BaseBookingPayment[];
  orders?: BaseOrder[];
  cancelledDateInBusinessTimezone?: string;
}

export interface BaseBusinessServer {
  id: number;
  businessId: number;
  name: string;
  colour: string;
  code: string;
}

export interface BaseExceptionTime {
  timeFrom?: string;
  timeTo?: string;
  shiftLabel: string;
  sortOrder: number;
  id: number;
  businessOpeningTimeExceptionId: number;
}

export interface BaseBusinessOpeningTimeException {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  experienceId?: number;
  id: number;
  dateFrom?: string;
  dateTo?: string;
  closed: boolean;
  recurringYearly?: boolean;
  bookableForEvent?: boolean;
  businessId: number;
  exceptionTimes?: BaseExceptionTime[];
}

export interface BaseBookingDurationRule {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  experienceId?: number;
  dateFrom?: string;
  dateTo?: string;
  timeFrom?: string;
  timeTo?: string;
  partySizeFrom?: number;
  partySizeTo?: number;
  id: number;
  businessId: number;
  durationInMinutes: number;
}

export interface BaseBookingPacingRule {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  experienceId?: number;
  dateFrom?: string;
  dateTo?: string;
  timeFrom?: string;
  timeTo?: string;
  id: number;
  businessId: number;
  pacingInMinutes?: number;
  covers?: number;
}

export enum DepositRuleType {
  Unknown = 'Unknown',
  PerPerson = 'PerPerson',
  PerBooking = 'PerBooking',
}

export enum DepositPaymentType {
  Unknown = 'Unknown',
  TakeNow = 'TakeNow',
  TakeOnCancelAndNoShow = 'TakeOnCancelAndNoShow',
  TakeOnNoShow = 'TakeOnNoShow',
}

export enum DepositCancellationDurationType {
  Unknown = 'Unknown',
  Days = 'Days',
  Hours = 'Hours',
}

export interface BaseDepositRule {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  experienceId?: number;
  dateFrom?: string;
  dateTo?: string;
  timeFrom?: string;
  timeTo?: string;
  partySizeFrom?: number;
  partySizeTo?: number;
  id: number;
  businessId: number;
  enabled: boolean;
  type: DepositRuleType;
  paymentType: DepositPaymentType;
  amount: number;
  bookingFee?: number;
  cancellationDuration?: number;
  cancellationDurationType?: DepositCancellationDurationType;
}

export interface BaseExperience {
  id: number;
  businessId: number;
  name: string;
  displayName: string;
  location?: string;
  pdfMenu?: string;
  experienceGuid?: string;
  description?: string;
  archived?: boolean;
  imageUrl?: string;
  pricePerGuest?: number;
  minGuests?: number;
  maxGuests?: number;
  published?: boolean;
  placeId?: string;
  type: ExperienceType;
  widgetLayout?: WidgetImageLayout;
  eventAddress?: BaseEventAddress;
}

export interface BaseStopSaleShift {
  id: number;
  shiftSortOrder: number;
  bookingStopSaleId: number;
}

export interface BaseBookingStopSale {
  id: number;
  businessId: number;
  bookingDate: string;
  dateAddedUtc?: string;
  stopSaleShifts?: BaseStopSaleShift[];
}

export interface BaseShiftNote {
  id: number;
  bookingDate: string;
  note: string;
  businessId?: number;
}

export interface BaseBusiness {
  id?: number;
  name: string;
  placeApiName?: string;
  websiteUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  bookingUrl?: string;
  tripAdvisorId?: string;
  membershipPlanId?: number;
  membership?: BaseMembership;
  parentBusinessId: number;
  stripeAccountKey?: string;
  stripeCustomerId?: string;
  stripeReaderLocationId?: string;
  locationId?: string;
  stripeEnabled?: boolean;
  isPopUp?: boolean;
  disableScreensaver?: boolean;
  screenSaverTimeoutSeconds?: number;
  isNew?: boolean;
  parentBusiness?: BaseParentBusiness;
  bookingConfigurationSettings?: BaseBookingConfigurationSetting;
  locationAddress?: BaseLocationAddress;
  hygieneRating?: number;
  hygieneRatingId?: string;
  description?: string;
  imageUrl?: string;
  phoneNumber?: string;
  currencyCode: string;
  operationalStatus: OperationalStatus;
  placeId?: string;
  twilioEnabled?: boolean;
  sendGridEnabled?: boolean;
  verificationStatus?: VerificationStatus;
  menus?: BaseMenu[];
  permissions?: BasePermission[];
  verificationDetails?: BaseBusinessVerification;
  timeBlocks?: BaseBookingTimeBlock[];
  customWidgetFields?: BaseCustomWidgetField[];
  tableSetups?: BaseBookingTableSetup[];
  shapes?: BaseShape[];
  tableSetupAreas?: BaseTableSetupArea[];
  bookings?: BaseActiveBooking[];
  servers?: BaseBusinessServer[];
  openingTimeExceptions?: BaseBusinessOpeningTimeException[];
  bookingsDurationRules?: BaseBookingDurationRule[];
  bookingsPacingRules?: BaseBookingPacingRule[];
  depositRules?: BaseDepositRule[];
  experiences?: BaseExperience[];
  bookingStopSales?: BaseBookingStopSale[];
  shiftNotes?: BaseShiftNote[];
  bookingPayments?: BaseBookingPayment[];
  orders?: BaseOrder[];
}

export enum UpdatedByUser {
  NotSet = 'NotSet',
  Business = 'Business',
  GuestUser = 'GuestUser',
  RegisteredUser = 'RegisteredUser',
}

export interface LightBookingHistory {
  description?: string;
  updatedBy?: UpdatedByUser;
  updatedByUserName?: string;
  dateHappened?: string;
}

export enum AutoSuggestionType {
  DishName = 'DishName',
  DishIngredientName = 'DishIngredientName',
  MenuName = 'MenuName',
  BusinessName = 'BusinessName',
  BusinessTag = 'BusinessTag',
  MenuSectionName = 'MenuSectionName',
  DrinkName = 'DrinkName',
}

export interface AutoSuggestion {
  name?: string;
  identifier?: string;
  type?: AutoSuggestionType;
  typeDescription?: string;
}

export enum InsertionErrorType {
  Unknown = 'Unknown',
  OverlappingItem = 'OverlappingItem',
}

export interface DurationRuleInsertionResponse {
  insertedId?: number;
  idsOfOverlappingRecords?: number[];
  errorMessage?: string;
  type?: InsertionErrorType;
}

export interface AreaAndTablesForTimeline {
  areaName?: string;
  areaId?: number;
  businessId?: number;
  experienceId?: number;
  areaScale?: number;
  areaBookableOnline?: boolean;
  tables?: {[key: string | number]: BaseBookingTableSetup};
}

export interface BookingIds {
  bookingIdentifiers?: number[];
}

export interface BaseBusinessOpeningTimesRange {
  id: number;
  dayOfWeek: DayOfWeek;
  dayOfWeekSortOrder?: number;
  sortOrder?: number;
  shiftLabel?: string;
  timeFrom?: string;
  timeTo?: string;
  timeToDayOfWeek?: DayOfWeek;
  timeToIsNextDay?: boolean;
  timeFromAfterTimeTo?: boolean;
  closed: boolean;
  parentBusinessId: number;
  businessId?: number;
}

export interface TableAvailability {
  available?: boolean;
  currentOrUpcomingBooking?: BaseActiveBooking;
  tableLabel?: string;
  availableForDurationIncludingChangeover?: number;
  maximumOccupancy?: number;
  tableDurationWithoutChangeover?: number;
  maximumDurationTableCanBeBookedFor?: number;
}

export interface TableAvailabilityForDate {
  timeSlot?: string;
  tableIdAndAvailability?: {[key: string | number]: TableAvailability};
  goesBeyondPacingLimit?: boolean;
}

export interface EventAreaSelection {
  wholeAreaSelected?: boolean;
  selectedTableIds?: {[key: string | number]: boolean};
}

export interface ExperienceOrEventSlot {
  timeFrom?: string;
  timeTo?: string;
  timeToIsNextDay?: boolean;
}

export interface EventInformation {
  displayName?: string;
  name?: string;
  areaIdsAndTables?: {[key: string | number]: EventAreaSelection};
  experienceOrEventSlots?: ExperienceOrEventSlot[];
  location?: string;
}

export interface ExperienceInformation {
  displayName?: string;
  name?: string;
  areaIdsAndTables?: {[key: string | number]: EventAreaSelection};
  experienceOrEventSlots?: ExperienceOrEventSlot[];
}

export interface ExperienceData {
  name?: string;
  displayName?: string;
  location?: string;
  description?: string;
  type?: ExperienceType;
}

export interface UpcomingExperiencesForTimelineResponse {
  eventIdAndInfo?: {[key: string | number]: ExperienceData};
}

export interface BlockInfoForTimeline {
  hardBlocked?: boolean;
}

export interface ExperienceValidRange {
  dateFrom?: string;
  dateTo?: string;
  daysOfWeek?: {[key: string | number]: boolean};
}

export interface BookingManagementResponse {
  areaAndTables?: {[key: string | number]: AreaAndTablesForTimeline};
  tableAndBookings?: {[key: string | number]: BookingIds};
  bookingIdAndBookingDetails?: {[key: string | number]: BaseActiveBooking};
  shapes?: BaseShape[];
  openingTimes?: BaseBusinessOpeningTimesRange[];
  customFields?: BaseCustomWidgetField[];
  specialOccasions?: string;
  availability?: TableAvailabilityForDate;
  isOpenNow?: boolean;
  exceptionTimeForDate?: BaseBusinessOpeningTimeException;
  exceptionDays?: {[key: string | number]: boolean};
  currentShiftSortOrder?: number;
  closedToday?: boolean;
  eventsForDay?: {[key: string | number]: EventInformation};
  experiencesForDay?: {[key: string | number]: ExperienceInformation};
  upcomingExperiencesAndEvents?: UpcomingExperiencesForTimelineResponse;
  timeSlotsAndBlockedTables?: {[key: string | number]: {[key: string | number]: BlockInfoForTimeline}};
  tableIdsBlockedOnAllTimeSlots?: {[key: string | number]: BlockInfoForTimeline};
  experienceIdsAndValidity?: {[key: string | number]: ExperienceValidRange[]};
}

export interface LightBookingNotification {
  text?: string;
  seen?: boolean;
  dateAdded?: string;
  id?: number;
  supportTicketId?: number;
}

export interface NotificationsCountParameters {
  businessId?: number;
  timezone?: string;
}

export interface NotificationsCountResponse {
  newNotificationsCount?: number;
}

export enum PacingInsertionErrorType {
  Unknown = 'Unknown',
  OverlappingItem = 'OverlappingItem',
}

export interface PacingRuleInsertionResponse {
  insertedId?: number;
  idsOfOverlappingRecords?: number[];
  errorMessage?: string;
  type?: PacingInsertionErrorType;
}

export interface ApiMakeBookingAsBusinessRequest {
  booking?: BaseActiveBooking;
  allowBookingBeyondClosingTime?: boolean;
  tableIdsToMerge?: number[];
  holdReference?: string;
  sendClientEmail?: boolean;
}

export enum BookingErrorType {
  Unknown = 'Unknown',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
  RequestedTimeDuringClosedTime = 'RequestedTimeDuringClosedTime',
  RequestedTimeIsBlocked = 'RequestedTimeIsBlocked',
  RequestedTableIsBlocked = 'RequestedTableIsBlocked',
  SlotUnavailable = 'SlotUnavailable',
  NoMoreAvailability = 'NoMoreAvailability',
  OneOfTablesToMergeIsNotAvailable = 'OneOfTablesToMergeIsNotAvailable',
  IssueLocatingBusiness = 'IssueLocatingBusiness',
  AlreadyBookedForSameDate = 'AlreadyBookedForSameDate',
  BookedAFewAtOnce = 'BookedAFewAtOnce',
  ClientAlreadyBookedForSameDate = 'ClientAlreadyBookedForSameDate',
  ClientAmendingWhenBookedThisVenueAlready = 'ClientAmendingWhenBookedThisVenueAlready',
  ClientAmendingWhenBookedOtherVenue = 'ClientAmendingWhenBookedOtherVenue',
  ClientAlsoBookedOtherLocation = 'ClientAlsoBookedOtherLocation',
}

export interface BookingError {
  message?: string;
  type?: BookingErrorType;
}

export interface ApiMakeAdminBookingResponse {
  bookingReference?: string;
  bookingUniqueId?: string;
  success?: boolean;
  guests?: number;
  bookingDateAndTime?: string;
  bookedName?: string;
  errors?: BookingError[];
  experienceGuid?: string;
  bookingId?: number;
  bookedTableNames?: string;
  booking?: BaseActiveBooking;
}

export enum AmendmentErrorType {
  None = 'None',
  NotEnoughSeats = 'NotEnoughSeats',
  MinimumOccupancyNotSatisfied = 'MinimumOccupancyNotSatisfied',
  OverlapsWithOther = 'OverlapsWithOther',
  TimeIsBlocked = 'TimeIsBlocked',
  BusinessIsClosed = 'BusinessIsClosed',
  TableOrAreaIsBlocked = 'TableOrAreaIsBlocked',
  ArchivedTableOrArea = 'ArchivedTableOrArea',
  TooManyTablesSelectedForThisParty = 'TooManyTablesSelectedForThisParty',
  AnotherBookingOnThisDate = 'AnotherBookingOnThisDate',
  AnotherBookingOnThisDateAtOtherVenue = 'AnotherBookingOnThisDateAtOtherVenue',
  TableOrAreaNotApplicableForExperience = 'TableOrAreaNotApplicableForExperience',
  ExperienceOutsideValidity = 'ExperienceOutsideValidity',
  ExtensionSeatNotValidWithChosenTables = 'ExtensionSeatNotValidWithChosenTables',
}

export interface BookingAmendmentResponse {
  success?: boolean;
  errorType?: AmendmentErrorType;
  errorMessage?: string;
}

export interface UpdateBookingStatusRequest {
  bookingId?: number;
  status?: BookingStatus;
  preventCardCharge?: boolean;
}

export interface ResponseOrError {
  success?: boolean;
  error?: string;
}

export interface TableAvailabilitySearchParameters {
  allowImpreciseRequestedTime?: boolean;
}

export enum BookingTypeSearchType {
  Specific = 'Specific',
  All = 'All',
}

export interface InternalApiBookingTimesRequest {
  requestedTime?: string;
  guests?: number;
  businessId?: number;
  locationId?: string;
  holdReference?: string;
  bookingReference?: string;
  suppressHold?: boolean;
  desiredDuration?: number;
  specificTableId?: number;
  specificTableIdsToMerge?: number[];
  experienceGuid?: string;
  searchParameters?: TableAvailabilitySearchParameters;
  bookingTypeSearchType?: BookingTypeSearchType;
  selectedExperienceId?: number;
}

export enum TablesToMergeErrorType {
  NoError = 'NoError',
  NoTablesAvailable = 'NoTablesAvailable',
  NotEnoughSeatsOnAvailableTables = 'NotEnoughSeatsOnAvailableTables',
  SelectedTableHasNoAvailability = 'SelectedTableHasNoAvailability',
}

export interface TablesToMergeError {
  message?: string;
  type?: TablesToMergeErrorType;
}

export interface HoldOrGetAvailableTablesResponse {
  holdSuccess?: boolean;
  holdReference?: string;
  heldTableIds?: number[];
  heldTableExpiryInUtc?: string;
  heldTableNames?: string[];
  heldForDurationInMinutes?: number;
  heldForShorterTime?: boolean;
  tablesForMerging?: TableAvailabilityForDate;
  errors?: TablesToMergeError[];
  suggestedTableIds?: number[];
  defaultDurationExcludingChangeover?: number;
  heldForDurationInMinutesWithoutChangeover?: number;
  suggestedTablesDuration?: number;
  goesBeyondPacingLimit?: boolean;
  depositRule?: BaseDepositRule;
}

export interface PreAmendBookingResponse {
  availableTables?: TableAvailabilityForDate;
  suggestedTableIds?: number[];
  defaultDurationExcludingChangeover?: number;
  depositRule?: BaseDepositRule;
  paymentDifference?: number;
  totalPaid?: number;
  refundable?: boolean;
}

export interface AmendBookingRequest {
  bookingId?: number;
  experienceId?: number;
  bookingReference?: string;
  guests?: number;
  startDateTime?: string;
  desiredDurationInMinutesWithoutChangeover?: number;
  tableIds?: number[];
  applyRefund?: boolean;
}

export interface TimeSlot {
  slot?: string;
  eventGuids?: string[];
  experienceGuids?: string[];
  eventOnly?: boolean;
  standardUnavailable?: boolean;
  requiresMerging?: boolean;
  isInThePast?: boolean;
  goesBeyondPacingLimit?: boolean;
  eventIds?: number[];
}

export interface TimeSlots {
  slots?: TimeSlot[];
}

export interface DateAndTimeSlots {
  date?: string;
  shiftLabelAndTimeSlots?: {[key: string | number]: TimeSlots};
}

export interface BookingAvailabilityTimes {
  available?: boolean;
  time?: string;
  requiresMerging?: boolean;
  availableForDurationIncludingChangeover?: number;
  availableForLimitedDuration?: boolean;
  timeSlotInThePast?: boolean;
  availableForDurationWithoutChangeover?: number;
  goesBeyondPacingLimit?: boolean;
}

export interface BookingAvailability {
  date?: string;
  times?: BookingAvailabilityTimes[];
  experienceGuid?: string;
  experienceType?: ExperienceType;
}

export interface ApiBookingTimes {
  availability?: BookingAvailability[];
  dateAndExperienceAvailability?: {[key: string | number]: BookingAvailability[]};
}

export interface ExperienceNameAndType {
  experienceName?: string;
  type?: ExperienceType;
}

export interface EventsAvailabilityAndHoldResponse {
  canGoOnWaitingList?: boolean;
  availableIds?: {[key: string | number]: ExperienceNameAndType};
  standardAvailable?: boolean;
  holdResponse?: HoldOrGetAvailableTablesResponse;
  holdResponseSet?: boolean;
}

export interface BookingDayOverview {
  date?: string;
  guests?: number;
  upcoming?: number;
  cancelled?: number;
  walkIns?: number;
  inHouse?: number;
  web?: number;
  noShowed?: number;
  noShowedGuests?: number;
  cancelledGuests?: number;
  closed?: boolean;
}

export interface BookingDayOverviewResponse {
  days?: {[key: string | number]: BookingDayOverview};
}

export interface DeleteRequest {
  id?: number;
}

export interface TimeBlockAddUpdateResponse {
  idsOfOverlappingRecords?: number[];
  success?: boolean;
  errorMessage?: string;
}

export interface TwilioUpdate {
  username?: string;
  authToken?: string;
  locationId?: string;
}

export interface SendGridUpdate {
  apiKey?: string;
  locationId?: string;
}

export interface StripeUpdate {
  enabled?: boolean;
  businessId?: number;
  locationId?: string;
}

export interface LightClientForBusiness {
  id?: number;
  telephone?: string;
  email?: string;
  notes?: string;
}

export interface FullClientModel {
  firstName?: string;
  surname?: string;
  businessId?: number;
  email?: string;
  telephone?: string;
  telephoneCountryCode?: string;
  futureBookings?: number;
  bookingCount?: number;
  noShowCount?: number;
  clientId?: number;
  cancelledCount?: number;
  notes?: string;
  marketing?: boolean;
  textUpdates?: boolean;
}

export interface ClientModel {
  firstName?: string;
  surname?: string;
  businessId?: number;
  email?: string;
  telephone?: string;
  telephoneCountryCode?: string;
  futureBookings?: number;
  bookingCount?: number;
  noShowCount?: number;
  clientId?: number;
  cancelledCount?: number;
}

export interface SaveExceptionTimeRequest {
  validOverlapConfirmedByUser?: boolean;
  openingException?: BaseBusinessOpeningTimeException;
}

export enum InvalidOpeningTimeType {
  Overlap = 'Overlap',
  Unset = 'Unset',
  TimeFromAfterTimeTo = 'TimeFromAfterTimeTo',
  SpansMoreThanTwentyFourHours = 'SpansMoreThanTwentyFourHours',
  TimeFromStartsAtMidnight = 'TimeFromStartsAtMidnight',
  OpenWithNoTimes = 'OpenWithNoTimes',
  OverlapsWithExistingRecord = 'OverlapsWithExistingRecord',
  ClosedWithSpecifiedTimes = 'ClosedWithSpecifiedTimes',
  ShiftLabelMissing = 'ShiftLabelMissing',
  OverlapsInsideWithExistingRecord = 'OverlapsInsideWithExistingRecord',
}

export interface ExceptionTimesValidityResponse {
  insertedOrUpdatedId?: number;
  success?: boolean;
  idsOfOverlappingRecords?: number[];
  idsOfOverlappingInsideRecords?: number[];
  errorMessage?: string;
  errorType?: InvalidOpeningTimeType;
}

export interface UpdateOpeningTimes {
  parentBusinessId?: number;
  businessId?: number;
  openingTimeRanges?: BaseBusinessOpeningTimesRange[];
  deletedIds?: number[];
}

export interface SearchedBusiness {
  businessName?: string;
  openStatusDescription?: string;
  openTableLink?: string;
  instagramHandle?: string;
  tripAdvisorId?: string;
  locationId?: string;
  hygieneRating?: number;
  phoneNumber?: string;
  currencyCode?: string;
  metersAway?: number;
  milesAway?: number;
  kilometersAway?: number;
  latitude?: number;
  longitude?: number;
  city?: string;
  businessIsNew?: boolean;
  googleMapsUrl?: string;
  businessTags?: string[];
  images?: string[];
}

export interface BusinessSearchResult {
  businesses?: SearchedBusiness[];
}

export interface VerificationModel {
  code?: string;
  businessId?: number;
}

export interface ApiInvoice {
  id?: string;
  url?: string;
  totalPrice?: number;
  invoiceNumber?: string;
  dateDue?: string;
  dateIssued?: string;
  paid?: boolean;
}

export interface ApiPaymentMethod {
  id?: string;
  lastFourDidgets?: string;
  month?: string;
  year?: string;
  brand?: string;
}

export interface ApiSystemProduct {
  id?: string;
  name?: string;
  description?: string;
  priceId?: string;
  price?: number;
  isCoreProduct?: boolean;
  iSubscribed?: boolean;
  featureSet?: string;
  icon?: string;
}

export interface CreateReaderRequest {
  readerSecret?: string;
  label?: string;
  businessId?: number;
}

export interface CardMachineCaptureIntentRequest {
  businessId?: number;
  amount?: number;
}

export interface CardMachinePaymentIntentResponse {
  paymentIntentId?: string;
  clientSecret?: string;
}

export interface InHousePaymentRequest {
  amount?: number;
  businessId?: number;
  clientId?: number;
}

export interface RefundRequest {
  id: number;
  amount?: number;
}

export interface LightCombinationGroup {
  combinationId?: number;
  businessId?: number;
  priority?: number;
  tableIds?: number[];
  joinedByDefault?: boolean;
  minimumSeats?: number;
  maximumSeats?: number;
}

export interface AddCombinationRequest {
  businessId?: number;
  tableMergeLinks?: number[];
  minimumSeats?: number;
  maximumSeats?: number;
}

export interface ApiComponentValue {
  rowKey?: string;
  componentId?: string;
  propertyId?: string;
  value?: string;
}

export interface ApiComponentProperty {
  rowKey?: string;
  componentId?: string;
  name?: string;
  type?: string;
  variableName?: string;
}

export interface ApiPageComponent {
  rowKey?: string;
  pageId?: string;
  componentId?: string;
  order?: number;
  parent?: string;
  values?: ApiComponentValue[];
  properties?: ApiComponentProperty[];
  filePath?: string;
}

export interface ApiComponent {
  rowKey?: string;
  name?: string;
  order?: number;
  markup?: string;
  category?: string;
  filePath?: string;
  template?: ApiPageComponent;
  properties?: ApiComponentProperty[];
}

export enum DepositInsertionErrorType {
  Unknown = 'Unknown',
  OverlappingItem = 'OverlappingItem',
}

export interface DepositRulesResponse {
  insertedId?: number;
  idsOfOverlappingRecords?: number[];
  errorMessage?: string;
  type?: DepositInsertionErrorType;
}

export enum DishAction {
  NotSet = 'NotSet',
  Archive = 'Archive',
  Hide = 'Hide',
}

export interface ArchiveDishParameters {
  dishId?: number;
  value?: boolean;
  action?: DishAction;
}

export interface ApiDishRating {
  rowKey?: string;
  dishId?: number;
  rating?: number;
  comment?: string;
}

export interface ExtendedBaseExperience {
  id: number;
  businessId: number;
  name: string;
  displayName: string;
  location?: string;
  pdfMenu?: string;
  experienceGuid?: string;
  description?: string;
  archived?: boolean;
  imageUrl?: string;
  pricePerGuest?: number;
  minGuests?: number;
  maxGuests?: number;
  published?: boolean;
  placeId?: string;
  type: ExperienceType;
  widgetLayout?: WidgetImageLayout;
  eventAddress?: BaseEventAddress;
  hasBookings?: boolean;
  futureBookingsCount?: number;
  displayLocation?: string;
}

export interface LightExperienceBooking {
  id?: number;
  bookingReference?: string;
  guests?: number;
  bookingDateTime?: string;
  clientName?: string;
  clientSurname?: string;
  clientTelephone?: string;
  clientEmail?: string;
}

export interface BaseExperienceAreaLink {
  id: number;
  setupAreaId: number;
  wholeAreaSelected: boolean;
  experienceId: number;
  experienceTableSelections?: BaseExperienceTableSelection[];
}

export interface BaseGlobalAdminPermission {
  id: number;
  userId: number;
  parentBusinessId: number;
}

export interface ApiImage {
  rowKey?: string;
  title?: string;
  alt?: string;
  caption?: string;
  folder?: string;
  blobId?: string;
  partitionKey?: string;
}

export interface ApiMenu {
  rowKey?: string;
  name?: string;
  parentKey?: string;
  url?: string;
  order?: string;
  menuKey?: string;
}

export interface ApiMenuListResponse {
  menus?: ApiMenu[];
  subItems?: {[key: string | number]: ApiMenu[]};
}

export interface ServingTimesRange {
  dayOfWeek?: DayOfWeek;
  timeFrom?: string;
  timeTo?: string;
  notRunning?: boolean;
}

export interface UpdateMenuServingTimes {
  parentBusinessId?: number;
  menuId?: number;
  businessId?: number;
  servingTimes?: ServingTimesRange[];
}

export interface ApiPage {
  rowKey?: string;
  name?: string;
  url?: string;
  blog?: boolean;
  parent?: string;
  metaTitle?: string;
  metaDescription?: string;
  categories?: string;
  tags?: string;
  published?: boolean;
  updated?: string;
  created?: string;
  components?: ApiPageComponent[];
  partitionKey?: string;
}

export interface ApiTag {
  rowKey?: string;
  name?: string;
  type?: string;
}

export interface GetPageResponse {
  pageInfo?: ApiPage;
  activeComponents?: ApiPageComponent[];
  availableComponents?: ApiComponent[];
  menus?: string[];
  categories?: ApiTag[];
  tags?: ApiTag[];
  layouts?: ApiPage[];
}

export interface PagesListResponse {
  pages?: ApiPage[];
  layouts?: ApiPage[];
}

export interface PageRequest {
  url?: string;
  name?: string;
  parent?: string;
  metaTitle?: string;
  metaDescription?: string;
  published?: boolean;
  useMaster?: boolean;
  partitionKey?: string;
}

export enum InteractionType {
  PageView = 'PageView',
  Search = 'Search',
}

export interface ApiPageInteraction {
  id?: number;
  rowKey?: string;
  locationId: string;
  type: InteractionType;
  ranking: number;
  interactionDateTime: string;
  device: string;
  country: string;
  region: string;
  city: string;
  ipAddress: string;
}

export interface BusinessPermissionsResponse {
  bookings?: boolean;
  qrCodeMenu?: boolean;
}

export interface RegisterBusinessModel {
  name?: string;
  branchName?: string;
  isChain?: boolean;
  currency?: string;
  road?: string;
  city?: string;
  county?: string;
  country?: string;
  postCode?: string;
  placeId?: string;
  longitude?: number;
  latitude?: number;
  parentBusinessId?: number;
  businessType?: BusinessType;
}

export interface ApiPDF {
  rowKey?: string;
  title?: string;
  blobId?: string;
  partitionKey?: string;
}

export interface ApiPicture {
  rowKey?: string;
  dishId?: number;
  uploadedUserId?: number;
  blobId?: number;
}

export interface UserProfile {
  firstName?: string;
  surname?: string;
  username?: string;
  email?: string;
  phoneNumber?: string;
  imageUrl?: string;
}

export interface ProfileBooking {
  reference?: string;
  hash?: string;
  guests?: number;
  location?: string;
  locationImage?: string;
  bookingDateTime?: string;
  noShow?: boolean;
  cancelled?: boolean;
  completed?: boolean;
}

export interface ProfileBookingListResponse {
  upcoming?: ProfileBooking[];
  past?: ProfileBooking[];
}

export interface BaseRecipeIngredient {
  recipeIngredientId: number;
  name: string;
  quantity: string;
}

export interface BaseRecipeStep {
  recipeStepId: number;
  description?: string;
}

export interface BaseRecipe {
  recipeId: number;
  userId: number;
  dateCreated: string;
  dateUpdated: string;
  name: string;
  description?: string;
  folder?: string;
  ingredients?: BaseRecipeIngredient[];
  steps?: BaseRecipeStep[];
}

export interface Pagination {
  paginate?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

export interface LocationQueryParams {
  pagination?: Pagination;
  radiusInMiles?: number;
  userLatitude?: number;
  userLongitude?: number;
}

export interface Location {
  name?: string;
  locationId?: string;
  metersAway?: number;
  milesAway?: number;
  kilometersAway?: number;
}

export interface LocationResult {
  location?: Location;
  description?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  businessPicture?: string;
  dishPictures?: string[];
}

export interface DishSearchRequest {
  query?: string;
  latitude?: number;
  longitude?: number;
  pageNumber?: number;
  radiusInMeters?: number;
  showClosed?: boolean;
  tags?: string[];
  foodRatings?: number[];
}

export interface DishDetails {
  tags?: TagType[];
  pictures?: string[];
}

export interface MatchingDish {
  businessName?: string;
  openStatusDescription?: string;
  openTableLink?: string;
  instagramHandle?: string;
  tripAdvisorId?: string;
  locationId?: string;
  hygieneRating?: number;
  phoneNumber?: string;
  currencyCode?: string;
  metersAway?: number;
  milesAway?: number;
  kilometersAway?: number;
  latitude?: number;
  longitude?: number;
  city?: string;
  businessIsNew?: boolean;
  googleMapsUrl?: string;
  dishName?: string;
  dishDescription?: string;
  allergenDetailsProvided?: boolean;
  allergenStatus?: AllergenStatus;
  price?: number;
  menuName?: string;
  isDailySpecial?: boolean;
  isSeasonalMenu?: boolean;
  menuType?: MenuType;
  menuSection?: string;
  dishDetails?: DishDetails;
  menuIsNew?: boolean;
  menuSectionIsNew?: boolean;
  dishIsNew?: boolean;
}

export interface SearchResult {
  dishes?: MatchingDish[];
  isAlternativeSearchResult?: boolean;
}

export enum SupportTicketArea {
  Bookings = 'Bookings',
  MenuManagement = 'MenuManagement',
  LocationCreation = 'LocationCreation',
  BusinessCreation = 'BusinessCreation',
  OpeningTimes = 'OpeningTimes',
  TableSetup = 'TableSetup',
  BookingConfiguration = 'BookingConfiguration',
}

export enum SupportTicketPriority {
  NotApplicable = 'NotApplicable',
  Urgent = 'Urgent',
  Medium = 'Medium',
  Minor = 'Minor',
}

export enum SupportTicketType {
  NotApplicable = 'NotApplicable',
  Bug = 'Bug',
  Question = 'Question',
  Suggestion = 'Suggestion',
}

export enum SupportTicketStatus {
  WithUs = 'WithUs',
  WithCustomer = 'WithCustomer',
  WontDo = 'WontDo',
  Resolved = 'Resolved',
}

export interface BaseSupportTicketResponse {
  id: number;
  supportTicketId: number;
  userId: number;
  dateAddedUtc: string;
  description: string;
  username?: string;
  isAdmin?: boolean;
}

export interface BaseSupportTicket {
  id?: number;
  parentBusinessId: number;
  userId?: number;
  username?: string;
  ticketReference?: string;
  businessId?: number;
  systemArea?: SupportTicketArea;
  dateRaisedUtc: string;
  dateUpdatedUtc?: string;
  dateResolvedUtc?: string;
  summary?: string;
  description?: string;
  businessName?: string;
  parentBusinessName?: string;
  devLogs?: string;
  priority: SupportTicketPriority;
  ticketType?: SupportTicketType;
  status: SupportTicketStatus;
  responses?: BaseSupportTicketResponse[];
}

export interface ApiUserDetail {
  rowKey?: string;
  username?: string;
  firstName?: string;
  surname?: string;
  isBusiness?: boolean;
  membershipPlanId?: string;
}

export interface ApiBookingTimesRequest {
  requestedTime?: string;
  guests?: number;
  businessId?: number;
  locationId?: string;
  holdReference?: string;
  bookingReference?: string;
  suppressHold?: boolean;
  desiredDuration?: number;
  specificTableId?: number;
  specificTableIdsToMerge?: number[];
  experienceGuid?: string;
  searchParameters?: TableAvailabilitySearchParameters;
  bookingTypeSearchType?: BookingTypeSearchType;
}

export interface WebEventsAvailabilityResponse {
  availableIds?: {[key: string | number]: boolean};
  standardAvailable?: boolean;
}

export interface TimeSlotWidget {
  slot?: string;
  eventGuids?: string[];
  experienceGuids?: string[];
  eventOnly?: boolean;
  standardUnavailable?: boolean;
}

export interface TimeSlotsWidget {
  slots?: TimeSlotWidget[];
}

export interface DateAndTimeSlotsWidget {
  date?: string;
  shiftLabelAndTimeSlots?: {[key: string | number]: TimeSlotsWidget};
}

export interface ApiBookingTimesWidget {
  availability?: DateAndTimeSlotsWidget[];
}

export interface WebLightExperience {
  experiencePublicName?: string;
  experienceType?: ExperienceType;
  experienceTypeFriendlyName?: string;
  experienceGuid?: string;
  description?: string;
  imageUrl?: string;
  layout?: WidgetImageLayout;
  location?: string;
}

export interface ClientBookingResponse {
  reference?: string;
  isCancelled?: boolean;
  firstName?: string;
  lastName?: string;
  bookingTime?: string;
  guests?: number;
  isInThePast?: boolean;
  specialRequests?: string;
  dietaryRequirements?: string;
  specialOccasion?: SpecialOccasion;
  phoneNumber?: string;
  currencyCode?: string;
  businessName?: string;
  startingTooSoon?: boolean;
  experience?: WebLightExperience;
  paidDeposit?: number;
  cancellationFee?: number;
  refundable?: boolean;
}

export interface ConfirmBookingViaWebRequest {
  bookingReference?: string;
  hash?: string;
}

export interface ConfirmBookingResponse {
  businessName?: string;
  success?: boolean;
  alreadyConfirmed?: boolean;
}

export interface CancelBookingViaWebRequest {
  bookingReference?: string;
  hash?: string;
}

export interface PublicAmendBookingRequest {
  newSpecialRequests?: string;
  newDietaryRequirements?: string;
  newSpecialOccasion?: SpecialOccasion;
  locationId?: string;
  holdReference?: string;
  bookingReference?: string;
  hash?: string;
  chargedCard?: boolean;
  cardIntent?: string;
}

export interface HeldBookingResponse {
  success?: boolean;
  holdReference?: string;
  heldTableExpiryInUtc?: string;
  accountId?: string;
  depositRequired?: boolean;
  depositAmount?: number;
  bookingFee?: number;
  businessCurrencyCode?: string;
  takeDepositNow?: boolean;
  error?: BookingError;
  paymentType?: DepositPaymentType;
  cancellationDuration?: number;
  cancellationDurationType?: DepositCancellationDurationType;
}

export interface ApiMakeBookingRequest {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  telephone?: string;
  telephoneCountryCode?: string;
  specialRequests?: string;
  dietaryRequirements?: string;
  customFields?: string;
  specialOccasion?: SpecialOccasion;
  updates?: boolean;
  platformUpdates?: boolean;
  selectedSlotInformation?: ApiBookingTimesRequest;
  chargedCard?: boolean;
  cardIntent?: string;
}

export interface ApiMakeBookingResponse {
  bookingReference?: string;
  bookingUniqueId?: string;
  success?: boolean;
  guests?: number;
  bookingDateAndTime?: string;
  bookedName?: string;
  errors?: BookingError[];
  experienceGuid?: string;
}

export interface HoldClientInfoRequest {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  telephone?: string;
  telephoneCountryCode?: string;
  specialRequests?: string;
  dietaryRequirements?: string;
  customFields?: string;
  specialOccasion?: SpecialOccasion;
  updates?: boolean;
  platformUpdates?: boolean;
  holdReference?: string;
  bookingHash?: string;
}

export interface ApiPaymentRequest {
  holdClientInfoRequest?: HoldClientInfoRequest;
  apiBookingTimesRequest?: ApiBookingTimesRequest;
}

export enum IntentError {
  Unknown = 'Unknown',
  InvalidPhoneNumber = 'InvalidPhoneNumber',
}

export interface IntentResponse {
  success?: boolean;
  errorType?: IntentError;
  info?: string;
}

export interface ThemeOptions {
  primaryColour?: string;
  primaryContrastColour?: string;
  backgroundColour?: string;
  textColour?: string;
  buttonTextColour?: string;
  buttonColour?: string;
  headerBackgroundColour?: string;
}

export interface BookingTimeSlots {
  timeSlots?: {[key: string | number]: boolean};
}

export interface ExperienceInfo {
  name?: string;
  shortName?: string;
  location?: string;
  description?: string;
  imageUrl?: string;
  menuUrl?: string;
  type?: ExperienceType;
  layout?: WidgetImageLayout;
  minimumPeople?: number;
  maximumPeople?: number;
  price?: number;
}

export interface ApiBusinessBookingDetails {
  name?: string;
  specialOccasions?: string;
  theme?: ThemeOptions;
  reserveButtonTextStepOne?: string;
  reserveButtonTextStepTwo?: string;
  customFields?: BaseCustomWidgetField[];
  timeZone?: string;
  imageUrl?: string;
  maximumBookableParty?: number;
  maximumFutureDaysForCalendar?: number;
  timeSlotsPerDay?: BookingTimeSlots;
  bookingConfirmationEmailText?: string;
  phoneNumber?: string;
  exceptionDays?: {[key: string | number]: boolean};
  specialEventDates?: {[key: string | number]: boolean};
  firstAvailableDate?: string;
  specialEvents?: {[key: string | number]: ExperienceInfo};
  experiences?: {[key: string | number]: ExperienceInfo};
  standardBookingDescription?: string;
  standardBookingImageUrl?: string;
  standardBookingName?: string;
  standardBookingImageLayout?: WidgetImageLayout;
  headerLogoUrl?: string;
  bannerImageUrl?: string;
  showTimesAsBlocks?: boolean;
  countryCode?: string;
  experienceAndValidityDates?: {[key: string | number]: ExperienceValidRange[]};
  currencyCode?: string;
}

export interface CreateBlankOrderRequest {
  tableName?: string;
  locationId?: string;
}

export interface CreateOrderResponse {
  orderNumber?: number;
  errorMessage?: string;
}

export interface OrderMenuSection {
  id?: number;
  title?: string;
  dishes?: number[];
}

export interface OrderMenu {
  id?: number;
  title?: string;
  sections?: OrderMenuSection[];
}

export interface OrderMenuResponse {
  menus?: OrderMenu[];
  dishes?: {[key: string | number]: BaseDish};
}

export interface BaseWebsiteContactFormRequest {
  id: number;
  type: string;
  from: string;
  message: string;
  sent?: string;
}

