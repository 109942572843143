import React from "react";
import { Text, View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { ListProps } from "./List";

import { ListTypeEnum, MarkerLocationEnum, MarkerTypeEnum } from "./enums";

import { mergeStyles } from "../utils";

import BaseComponent from "../Base/BaseComponent";

import Icon, { FontIcon } from "../Icon";
import { HAlignEnum } from "../enums";

function alphaIndex(index = 0, upper = false) {
  const START = upper ? 64 : 96;

  return index.toString(26).split('').reduce((result, unit) => {
    return result + String.fromCharCode(START + parseInt(unit, 26));
  }, '');
}

function roman(num: number) {
  const rom = ['M', 'CM', 'D', 'CD', 'C', 'XC', 'L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];
  const ara = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];

  return rom.reduce((result, letter, index) => {
    const value = ara[index];

    while (num >= value) {
      result.push(letter);
      num -= value;
    }

    return result;
  }, []).join('');
}

function markerString(index: number, markerType: MarkerTypeEnum) {
  let string: string;
  let width: number;

  switch (markerType) {
    case MarkerTypeEnum.LOWERALPHA:
      string = alphaIndex(index, false);
      width = 14;
      break;

    case MarkerTypeEnum.LOWERROMAN:
      string = roman(index).toLowerCase();
      width = 32;
      break;

    case MarkerTypeEnum.UPPERALPHA:
      string = alphaIndex(index, true);
      width = 18;
      break;

    case MarkerTypeEnum.UPPERROMAN:
      string = roman(index).toUpperCase();
      width = 32;
      break;

    case MarkerTypeEnum.DECIMAL:
    default:
      string = index.toString();
      width = 20;
  }

  return [`${string}. `, width];
}

export interface ListItemProps extends Omit<ListProps,
  'compact' | 'debugChildren' | 'inline' | 'reversed' | 'start'
> {
  index?: number;
  key?: any;
}

class ListItem extends BaseComponent<ListItemProps> {
  defaultStyle: Style = {
    flexDirection: 'row',
    marginVertical: 4,
    lineHeight: 1.4
  };

  get marker() {
    const { props } = this;
    const { glyph, icon } = props;
    const fontIconStyle: Style = {
      width: props.markerWidth || 16,
      paddingRight: 6,
      color: props.markerColor
    };

    if (glyph) {
      return (
        <>
          <FontIcon
            codepoint={glyph}
            debug={props.debug}
            fontWeight={props.markerWeight}
            style={fontIconStyle}
          />
        </>);
    }

    if (icon) {
      return (<Icon debug={props.debug} src={icon} style={{ width: 20, marginRight: 6 }} />);
    }

    if (props.listType === ListTypeEnum.NONE) {
      return null;
    }

    const [content, width] = props.listType === ListTypeEnum.ORDERED
      ? markerString(props.index, props.markerType)
      : ['\u2022 ', 8];
    const markerStyle: Style = props.markerLocation === MarkerLocationEnum.OUTSIDE
      ? {
        flexDirection: 'column',
        justifyContent: props.markerVAlign,
        width: props.markerWidth || width,
        minWidth: props.markerWidth || width,
        marginRight: 6,
        textAlign: HAlignEnum.RIGHT
      } : {
        paddingRight: 6
      };

    if (props.markerColor) {
      markerStyle.color = props.markerColor;
    }

    return (<Text debug={props.debugChildren} style={markerStyle}>{content}</Text>);
  }

  render() {
    const self = this;
    const props = { ...self.props };
    const { markerLocation = MarkerLocationEnum.OUTSIDE } = props;

    if (markerLocation === MarkerLocationEnum.INSIDE) {
      self.style = {
        flexWrap: 'wrap'
      };
    }

    delete props.debugChildren;
    props.debug = self.props.debugChildren;

    const content = (
      <>
        {self.marker}
        {super.renderChildren()}
      </>
    );

    return markerLocation === MarkerLocationEnum.INSIDE
      ? (<Text {...props} style={mergeStyles(self.style, props.style)}>
        {content}
      </Text>)
      : (<View {...props} style={mergeStyles(self.style, props.style)}>
        {content}
      </View>);
  }
}

export default ListItem;
export { ListItem };
