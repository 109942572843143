import { Logtail } from "@logtail/browser";
import { captureException, withScope } from "@sentry/react";

export class LoggingService {
    private static _logger: Logtail = null;

    static Log(message: any, additionalData: { key: string, data: any }[] = []) {
        if (process.env.NODE_ENV !== 'development') {
            if (!this._logger) {
                this._logger = new Logtail("sQ6GbSHakkopXCz5j27J3kVz");
            }
            withScope(scope => {
                additionalData.forEach(item => {
                    scope.setExtra(item.key, item.data)
                });
                captureException(message)
            });
            //captureException(message);
            this._logger.error(JSON.stringify({
                message,
                additionalData
            }));
            this._logger.flush();
        }
    }
}