import React, { Fragment } from "react";
import { View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { mergeStyles, renderContent, ViewProps } from "../utils";

interface BaseComponentProps extends ViewProps {
  defaultStyle?: Style;
}

abstract class BaseComponent<P = {}, S = {}, SS = any> extends React.Component<P & BaseComponentProps, S, SS> {
  abstract defaultStyle: Style;

  get style(): Style {
    return this.defaultStyle;
  }

  set style(style: Style) {
    const self = this;

    self.defaultStyle = style ? mergeStyles(self.defaultStyle, style) : {};
  }

  constructor(props: P & BaseComponentProps) {
    super(props);

    this.style = props.defaultStyle;
  }

  renderChildren(): JSX.Element | Array<JSX.Element> {
    const self = this;
    const { debugChildren: debug } = self.props;

    return React.Children
      .toArray(self.props.children)
      .map((child: any, index) =>
        <React.Fragment key={`child_${index}_${Math.floor(Math.random() * 1e3)}`}>
          {child.type
            ? React.cloneElement(child, {
              debug,
              ...child.props,
            })
            : renderContent(child, debug)}
        </React.Fragment>
      );
  }

  render(extendedProps: any = {}) {
    const self = this;
    const componentProps = Object.assign({}, self.props, extendedProps);

    return (
      <View {...componentProps} style={mergeStyles(self.style, componentProps.style)}>
        {self.renderChildren()}
      </View>
    );
  }
}

export default BaseComponent;
export type { BaseComponentProps };
