import React, { useState } from 'react';
import { ApiBusinessBookingDetails, BaseDish, ThemeOptions } from '../../../../api/api-definitions';
import CoreModal from '../../../../components/Layout/CoreModal';
import styled, { useTheme } from 'styled-components';
import { Constants } from '../../../../constants';
import CoreButton from '../../../../components/Forms/Button';
import { StyledNumberInput, StyledTextarea } from '../../../../theme/input.styles';
import { useSetRecoilState } from 'recoil';
import { BasketState, FoodItemState, IBasketItemValues } from '..';
import { cloneDeep } from 'lodash';
import BREAKPOINTS from '../../../../config/breakpoints';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';

interface ComponentProps {
    selectedItem: BaseDish;
    onClose: () => void;
    business: ApiBusinessBookingDetails;
    currentValues?: IBasketItemValues;
}

const demoOptions = [
    { label: 'Spice level 1', value: 1, price: 0, kal: 10 },
    { label: 'Spice level 2', value: 2, price: 0, kal: 10 },
    { label: 'Spice level 3', value: 3, price: 0, kal: 20 },
    { label: 'Spice level 4', value: 4, price: 1, kal: 20 },
    { label: 'Spice level 5', value: 5, price: 1.5, kal: 30 },
]

const demoOptions2 = [
    { label: 'Pork', value: 1, price: 4, kal: 40 },
    { label: 'Beef', value: 2, price: 3.2, kal: 50 },
    { label: 'Lamb', value: 3, price: 4.5, kal: 80 },
    { label: 'Chicken', value: 4, price: 2.5, kal: 20 },
]

const AddToBasketModal = ({ selectedItem, onClose, business, currentValues }: ComponentProps) => {
    const theme = useTheme();
    const [quantity, setQuantity] = useState(currentValues?.quantity || 1);
    const setBasketState = useSetRecoilState(BasketState);
    const setValue = useSetRecoilState(FoodItemState([selectedItem.id, { quantity: 0 }]));
    const [selectedOption, setSelectedOption] = useState(2);
    const [checkedOptions, setCheckedOptions] = useState<any>({});

    const addToBasket = () => {
        let newValue: number = 0;
        setBasketState(basket => {
            const current = cloneDeep(basket);
            if (current[selectedItem.id]) {
                current[selectedItem.id].quantity += quantity;
            } else {
                current[selectedItem.id] = {
                    quantity: quantity
                }
            }
            if (current[selectedItem.id].quantity == 0) {
                delete current[selectedItem.id];
            }
            newValue = current[selectedItem.id].quantity;
            return current;
        })
        setValue({ quantity: newValue })
        onClose()
    }

    return (
        <CoreModal
            title={<>{currentValues && <strong>Edit: </strong>}{selectedItem.name}</>}
            onClose={onClose}
            hasCancel={false}
            small
            customWidth='35rem'
            noFooterFlex
            actionBar={
                <ActionBar>
                    <AddToBasketQuantity>
                        <StyledNumberInput value={quantity} hasPlusMinus min={1} onChange={(e) => setQuantity(+e.target.value)} />
                    </AddToBasketQuantity>
                    <AddToBasketButton>
                        <CoreButton
                            full
                            onClick={addToBasket}
                            secondaryConstrastOverride={business?.theme?.primaryContrastColour || theme.secondaryContrast}
                            secondaryOverride={business?.theme?.primaryColour || theme.secondary}
                        >{currentValues ? 'Update basket' : 'Add to basket'}</CoreButton>
                    </AddToBasketButton>
                </ActionBar>
            }
        >
            {!currentValues && !isNullOrWhitespace(selectedItem.imageUrl) &&
                <ModalImage style={{ backgroundImage: `url(${Constants.baseImageUrl}${selectedItem.imageUrl})` }} />
            }
            {!currentValues && <Description dangerouslySetInnerHTML={{ __html: selectedItem.description.replaceAll('\\n', '<br />') }} />}
            <OptionContainer>
                <OptionConatinerTitle>Choose spice level</OptionConatinerTitle>
                <OptionContainerDescription>Please choose 1 option</OptionContainerDescription>
                {demoOptions.map((option) => (
                    <Option widgetTheme={business.theme} selected={option.value == selectedOption} htmlFor={'spice' + option.value}>
                        <OptionInput>
                            <input type="radio" name="spice" id={'spice' + option.value} value={option.value} checked={option.value == selectedOption} onChange={() => setSelectedOption(option.value)} />
                        </OptionInput>
                        <OptionTitle>
                            {option.label}
                            {option.kal != 0 && <OptionCalories>{option.kal} kal</OptionCalories>}
                        </OptionTitle>
                        {option.price != 0 &&
                            <OptionPrice>
                                {option.price > 0 ? '+' : '-'} {option.price.toFixed(2)}
                            </OptionPrice>
                        }
                    </Option>
                ))}
            </OptionContainer>
            <OptionContainer>
                <OptionConatinerTitle>Choose additional ingredients</OptionConatinerTitle>
                <OptionContainerDescription></OptionContainerDescription>
                {demoOptions2.map((option) => (
                    <Option widgetTheme={business.theme} selected={checkedOptions[option.value]} htmlFor={'addition' + option.value}>
                        <OptionInput>
                            <input type="checkbox" name="addition" id={'addition' + option.value} value={option.value} checked={checkedOptions[option.value]} onChange={() => setCheckedOptions({ ...checkedOptions, [option.value]: !checkedOptions[option.value] })} />
                        </OptionInput>
                        <OptionTitle>
                            {option.label}
                            {option.kal != 0 && <OptionCalories>{option.kal} kal</OptionCalories>}
                        </OptionTitle>
                        {option.price != 0 &&
                            <OptionPrice>
                                {option.price > 0 ? '+' : '-'} {option.price.toFixed(2)}
                            </OptionPrice>
                        }
                    </Option>
                ))}
            </OptionContainer>
            <OptionContainer>
                <OptionConatinerTitle>Special requests</OptionConatinerTitle>
                <StyledTextarea />
            </OptionContainer>
        </CoreModal>
    );
};

const OptionContainer = styled.div`
    margin-top: 1rem;
`

const OptionConatinerTitle = styled.div`
    font-weight: bold;
`

const OptionInput = styled.div`
    width: 2rem;
`

const OptionContainerDescription = styled.div`
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
`

const Option = styled.label<{ selected?: boolean, widgetTheme?: ThemeOptions }>`
    display: flex;
    border: 1px solid ${props => props.theme.borderColor};
    padding: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;

    ${props => props.selected && `
        border: 2px solid ${props.widgetTheme?.primaryColour || props.theme.secondary};
    `}

    &:hover {
        background-color: ${props => props.theme.dashboardBackground};
    }
`

const OptionTitle = styled.div`
    flex: 1 1 auto;
`

const OptionCalories = styled.span`
    font-size: 0.8rem;
    color: color: rgb(74 74 74);
    padding-left: 1rem;
    font-style: italic;
`

const OptionPrice = styled.div`
    width: 3rem;
`

const Description = styled.div`
    white-space: pre-wrap;
`

const ActionBar = styled.div`
    display: flex;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
`

const AddToBasketQuantity = styled.div`
  flex: none;
  width: 50%;
  padding-right: 1rem;
`

const AddToBasketButton = styled.div`
  width: 100%;
  padding-top: 0.2rem;
`

const ModalImage = styled.div`
  width: 100%;
  height: 15rem;
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 1rem;
`

export default AddToBasketModal;