export enum CaptionAlignEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right'
}

export enum FrameEnum {
  NONE = 'none',
  ABOVE = 'above',
  BELOW = 'below',
  LHS = 'lhs',
  RHS = 'rhs',
  HSIDES = 'hsides',
  VSIDES = 'vsides',
  BORDER = 'border',
  BOX = 'box'
}

export enum LocationEnum {
  BODY = 'body',
  FOOTER = 'footer',
  HEADER = 'header'
}

export enum RulesEnum {
  NONE = 'none',
  ROWS = 'rows',
  COLS = 'cols',
  GROUPS = 'groups',
  ALL = 'all'
}

export enum ZebraColours {
  EVEN = '#e0e0e0',
  ODD = ''
}
