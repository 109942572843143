import { DiscoverResult, ErrorResponse, ISdkManagedPaymentIntent, Reader, Terminal, loadStripeTerminal } from '@stripe/terminal-js';
import React, { useEffect, useRef, useState } from 'react';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { ApiService } from '../../../../../api/api-connectors';
import { Column, Row } from '../../../../../components/Layout/Grid';
import { Badge } from '@chakra-ui/react';
import styled from 'styled-components';
import { BoxShadowStyle } from '../../../../../theme';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import ActionBox from '../../../../../components/Forms/Messaging';
import CoreButton from '../../../../../components/Forms/Button';
import Loader from '../../../../../components/Layout/Loader';
import { NotificationService } from '../../../../../services/NotificationService';
import { Constants } from '../../../../../constants';
import CoreModal from '../../../../../components/Layout/CoreModal';
import { StyledTextInput } from '../../../../../theme/input.styles';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import DashboardAddButton from '../../../../../components/Dashboard/AddButton';

interface ComponentProps {
}

const TerminalSetup = ({ }: ComponentProps) => {
    const [businessLoaded, business, setBusiness] = useBusiness();
    const terminal = useRef<Terminal>();
    const [readers, setReaders] = useState<Reader[]>();
    const [loading, setLoading] = useState(true);
    const [registerData, setRegisterData] = useState<{ label: string; readerSecret: string; }>();
    const loaded = useRef(false);

    useEffect(() => {
        if (!loaded.current && business) {
            if (business.stripeReaderLocationId) setupStripeTerminal();
            setLoading(false)
            loaded.current = true;
        }
    }, [businessLoaded])

    const fetchConnectionToken = async (): Promise<string> => {
        let token = '';
        await ApiService.checkout.CreateConnectionToken__GET(business.id).then((response) => {
            token = response.info;
        });
        return token;
    }

    const setupStripeTerminal = async () => {
        const StripeTerminal = await loadStripeTerminal();
        const response = StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: unexpectedDisconnect,
        });
        terminal.current = response;
        const config = {
            location: business.stripeReaderLocationId,
            // simulated: true
        };
        terminal.current.discoverReaders(config).then(discoverResult => {
            if ((discoverResult as ErrorResponse).error) {
                console.log('Failed to discover: ', (discoverResult as ErrorResponse).error);
            } else if ((discoverResult as DiscoverResult).discoveredReaders.length === 0) {
                console.log('No available readers.');
            } else {
                let discoveredReaders = (discoverResult as DiscoverResult).discoveredReaders;
                setReaders(discoveredReaders)
            }
        });
    }

    const unexpectedDisconnect = () => {

    }

    const registerLocation = () => {
        ApiService.checkout.CreateReaderLocation__GET(business.id).then((response) => {
            if (response.success) {
                NotificationService.Confirm('Location registered')
                setBusiness({ ...business, stripeReaderLocationId: response.info })
                setupStripeTerminal()
            } else {
                NotificationService.Error('Could not register location')
            }
        })
    }

    const confirmRegisterReader = () => {
        ApiService.checkout.CreateReader__POST({
            label: registerData.label,
            readerSecret: registerData.readerSecret,
            businessId: business.id
        }).then(() => {
            setRegisterData(null)
            setupStripeTerminal()
        })
    }
    
    const updateSplashscreen = () => {
        ApiService.checkout.UpdateReaderSplashscreen__GET(business.id)
    }

    const removeReader = (reader: Reader) => {
        ApiService.checkout.RemoveReader__GET(business.id, reader.id).then(() => {
            setupStripeTerminal()
        })
    }

    return (
        <>
            <DashboardHeader title='Card readers' icon='credit-card' />
            {/* <CoreButton onClick={updateSplashscreen}>Update splashscreen</CoreButton> */}
            {loading && <Loader />}
            {!loading &&
                <>
                    {registerData &&
                        <CoreModal
                            small
                            onClose={() => setRegisterData(null)}
                            title='Register Card Reader'
                            actionBar={
                                <CoreButton
                                    disabled={isNullOrWhitespace(registerData.label) || isNullOrWhitespace(registerData.readerSecret)}
                                    onClick={() => confirmRegisterReader()}
                                >
                                    Confirm
                                </CoreButton>
                            }
                        >
                            <Row>
                                <Column size={4}>
                                    <StyledTextInput label='Machine Name' value={registerData.label} onChange={(e) => setRegisterData({...registerData, label: e.target.value })} />
                                </Column>
                                <Column size={4}>
                                    <StyledTextInput label='Machine Secret' value={registerData.readerSecret} onChange={(e) => setRegisterData({...registerData, readerSecret: e.target.value })} />
                                </Column>
                            </Row>
                        </CoreModal>
                    }
                    {!business?.stripeReaderLocationId &&
                        <ActionBox title='Welcome to card reader setup'>
                            To start accepting card payments in person with a card reader supplied by {Constants.businessName}, click the button below.
                            <br />
                            <br />
                            <CoreButton onClick={registerLocation}>Register your location</CoreButton>
                        </ActionBox>
                    }
                    {business?.stripeReaderLocationId &&
                        <Row>
                            {readers && readers.map((reader) => (
                                <Column size={4}>
                                    <CardSelectionContainer>
                                        <img src='/assets/graphics/WisePOS-E.png' />
                                        <div>
                                            <MachineName>{reader.label}</MachineName>

                                            <CardReaderStatus>
                                                {reader.status == 'online' ? <Badge colorPalette='green'>Online</Badge> : <Badge colorPalette='red'>Offline</Badge>}
                                            </CardReaderStatus>
                                            <br />
                                            <CoreButton onClick={() => removeReader(reader)}>Remove reader</CoreButton>
                                        </div>
                                    </CardSelectionContainer>
                                </Column>
                            ))}
                            <Column size={4}>
                                <DashboardAddButton onClick={() => setRegisterData({ label: '', readerSecret: '' })}>
                                    Register reader
                                </DashboardAddButton>
                            </Column>
                        </Row>
                    }
                </>
            }
        </>
    );
};

const MachineName = styled.div`
    padding: 0 1rem;
    line-height: 3rem;
`;

const CardSelectionContainer = styled(BoxShadowStyle)`
    padding: 1rem;
    background-color: white;
    text-align: center;

    img {
        display: block;
        max-height: 10rem;
        margin: 0rem auto;
    }
`

const CardReaderStatus = styled.div`
    padding-top: 0.6rem;
`

const CardReaderIcon = styled.div`
    width: 3rem;
    line-height: 3rem;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primaryContrast};
    border-radius: 0.2rem;
    text-align: center;
    font-size: 1.5rem;
`

export default TerminalSetup;