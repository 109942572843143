import { cloneDeep } from 'lodash';
import IdleJs from 'idle-js';
import moment, { Moment } from 'moment-timezone';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ApiService } from '../../../../../api/api-connectors';
import { BaseActiveBooking, BaseBookingTableSetup, BaseBusinessOpeningTimesRange, BookingStatus, AreaAndTablesForTimeline, BookingManagementResponse, BookingAmendmentResponse, AmendBookingRequest, SpecialOccasion, ExperienceType, EventAreaSelection } from '../../../../../api/api-definitions';
import DashboardHeader from '../../../../../components/Dashboard/Header';
import DashboardPanel from '../../../../../components/Dashboard/Panel';
import { Column, Row } from '../../../../../components/Layout/Grid';
import Loader from '../../../../../components/Layout/Loader';
import { TabBar, TabButton } from '../../../../../components/Layout/Tabs';
import Icon from '../../../../../components/Media/Icon';
import { useBusiness } from '../../../../../hooks/useBusiness';
import { BoxShadowStyle } from '../../../../../theme';
import { StyledCalendar } from '../../../../../theme/input.styles';
import { businessNowTime, createMomentFromValue, DATABASE_TIME_FORMAT, dateIsToday, DATEONLYFORMAT, formatDate, RoundTo15, TIMEFORMAT } from '../../../../../utils/date-helpers';
import BookingInfoModal from './BookingModals/BookingInfoModal';
import NewBookingModal from './BookingModals/NewBookingModal';
import MainStage from './TableLayout/MainStage';
import { ExtendedTable } from './TableLayout';
import { TABLEWIDTH } from './TableLayout/tableLayoutUtils';
import { isNullOrWhitespace } from '../../../../../utils/text-helpers';
import BookingList from './modules/BookingList';
import { NotificationService } from '../../../../../services/NotificationService';
import BookingListPanel from './modules/BookingListPanel';
import ActionBox, { ErrorMessage, InfoMessage, WarningMessage } from '../../../../../components/Forms/Messaging';
import { SupportTicketService } from '../../../../../services/SupportTicketService';
import BugButton from '../../Shared/bugButton';
import ShiftNotes from './BookingModals/ShiftNotes';
import { Center, createToaster, Portal } from '@chakra-ui/react';
import { pushEvent } from '../../../../../utils/data-helpers';
import StopSaleModule from './modules/StopSaleModule';
import Timeline, { TimelineRow, TimelineTimeRange } from './Timeline';
import BREAKPOINTS from '../../../../../config/breakpoints';
import { GetStatusColour, GetStatusTextColour, calculateTimes, getBookingTooltip, shouldShowArea } from './bookingUtils';
import { isAppleSafari } from '../../../../../utils/dom-helpers';
import { ModalServiceParams } from '../../../../../services/ModalService';
import CoreButton from '../../../../../components/Forms/Button';
import bookingModuleTour from '../../../../../utils/tours/booking-module';
import CoreModal, { ModalContext } from '../../../../../components/Layout/CoreModal';
import { BookingSpecialOccasions } from '../../../../../constants';
import BookingCacheService from '../../../../../services/BookingCacheService';
import useOffline from '../../../../../hooks/useOffline';
import InfoModule from './modules/InfoModule';
import WaitList, { WaitlistItem } from './modules/Waitlist';
import NotificationsModal from './BookingModals/NotificationsModal';
import Tag from '../../../../../components/Forms/Tags/Tag';
import PointOfSaleWrapper from './PointOfSaleWrapper';
import BookingService from './bookingService';
import { Toaster } from '../../../../../components/ui/toaster';
import CoreBadge from '../../../../../components/CoreBadge';
import ExperienceInfoBadge from './Experiences/experienceInfoBadge';

export const TIMELINE_INTERVAL = 15;

const toaster = createToaster({
    placement: 'top',
    duration: 3000,
})

interface BookingTableSetup {
    [key: string]: {
        bookings: BaseActiveBooking[];
        table: BaseBookingTableSetup;
    }
}

export interface ExtendedBookingManagementResponse extends BookingManagementResponse {
    tables: ExtendedTable[];
    bookings: BaseActiveBooking[];
}

interface DisplayAreas {
    showOnlySectionsAndTables?: { [key: string | number]: EventAreaSelection };
    hideSectionsAndTables?: { [key: string | number]: EventAreaSelection };
}

const BookingTimeline = () => {
    const storedTabIndex = sessionStorage.getItem('tabIndex');
    const [businessLoaded, businessData] = useBusiness();
    const offline = useOffline();
    const [data, setData] = useState<BookingTableSetup>();
    const [modalTable, setModalTable] = useState<BaseBookingTableSetup>();
    const [selectedTable, selectTable] = useState<number>();
    const [selectedArea, selectArea] = useState<AreaAndTablesForTimeline>();
    const [times, setTimes] = useState<TimelineTimeRange[]>([]);
    const [bookingManagementData, setBookingManagementData] = useState<ExtendedBookingManagementResponse>();
    const [newBookingDate, setNewBookingDate] = useState<string>();
    const [viewBooking, setViewBooking] = useState<BaseActiveBooking>();
    const [bookings, setBookings] = useState<BaseActiveBooking[]>();
    const [renderer, forceUpdate] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [stageScale, setStageScale] = useState(1);
    const [sideBarWidth, setSideBarWidth] = useState<number>();
    const [newBookingOpen, setNewBookingOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [tabIndex, setTabIndex] = useState<number>(storedTabIndex ? +storedTabIndex : 0);
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [offlineError, setOfflineError] = useState<boolean>(false);
    const [selectedExperience, setSelectedExperience] = useState<number>();
    const selectedExperienceRef = useRef<number>();
    const bookingManagementDataRef = useRef<ExtendedBookingManagementResponse>()
    const loaded = useRef(false)
    const timelineRows = useRef<TimelineRow[]>([]);
    const timelineCounts = useRef<{ [key: string]: number }>({});
    const selectedDateRef = useRef<Date>(new Date())
    const scrolled = useRef(false)
    const timelineWrapper = useRef<HTMLDivElement>();
    const scaleWrapper = useRef<HTMLDivElement>();
    const selectedTimeIsNow = useRef(false);
    const isFullScreen = useRef(false);
    const mounted = useRef(false);
    const preventRefresh = useRef(false);
    const waitListItem = useRef<WaitlistItem>();
    const pushedUnallocatedRow = useRef(false);
    const [scaling, setScaling] = useState<boolean>(false);
    const [showLock, setShowLock] = useState<boolean>(false);
    const [modalData, setModalData] = useState<ModalServiceParams>();
    const [timelineLoading, setTimelineLoading] = useState<boolean>(false);
    const [stageWidth, setStageWidth] = useState<number>();
    const [stageHeight, setStageHeight] = useState<number>();
    const lockRef = useRef<boolean>(false);
    const timer = useRef<IdleJs>();
    const selectedIsToday = dateIsToday(selectedDateRef.current);
    const today = createMomentFromValue(date).format('dddd');
    const todaysTimes = bookingManagementData?.openingTimes?.filter(x => x.dayOfWeek.toString() === today) || [];
    const todaysExceptionTimes = bookingManagementData?.exceptionTimeForDate;
    const [selectedShift, setSelectedShift] = useState<number>(0);
    const selectedShiftRef = useRef(0);

    const onStageRefChange = useCallback(value => {
        scaleWrapper.current = value;
        setStandardScaling();
     }, []);

    useEffect(() => {
        mounted.current = true;
        onExitFullScreen()
        NotificationService._toastCmd = toaster.create;

        return () => {
            NotificationService._toastCmd = null;
            mounted.current = false;
            if (timer.current) timer.current.stop();
            unmountFullScreen();
        }
    }, [])

    useEffect(() => {
        if (tabIndex == 0 && fullscreen && scaleWrapper.current && window.innerWidth >= BREAKPOINTS.mobileLarge) {
            setScaling(true)
            setTimeout(() => {
                const distanceToBottom = window.innerHeight - scaleWrapper.current.offsetTop;
                const percentage = distanceToBottom / 650;
                setStageScale(percentage);
                setTimeout(() => {
                    setSideBarWidth(window.innerWidth - scaleWrapper.current.getBoundingClientRect().width);
                    setScaling(false)
                }, 100);
            }, 100);
        } else {
            setStandardScaling();
        }
    }, [fullscreen, tabIndex])

    useEffect(() => {
        sessionStorage.setItem('tabIndex', tabIndex.toString())
    }, [tabIndex])

    useEffect(() => {
        if (businessData && !loaded.current) {
            loaded.current = true;
            moment.tz.setDefault(businessData.locationAddress.timeZone);
            const dateToUse = businessNowTime().toDate();
            load(dateToUse);
            refreshBookings()
            if (!businessData.disableScreensaver) {
                const timerData = new IdleJs({
                    idle: businessData.screenSaverTimeoutSeconds ? businessData.screenSaverTimeoutSeconds * 1000 : 30000, // idle time in ms
                    events: ['mousemove', 'keydown', 'scroll'], // events that will trigger the idle resetter
                    onIdle: () => { setShowLock(true); lockRef.current = true; }, // callback function to be executed after idle time
                    onActive: () => {
                        if (lockRef.current) {
                            setShowLock(false);
                            lockRef.current = false;
                        }
                    }, // callback function to be executed after back form idleness
                    onHide: function () { }, // callback function to be executed when window become hidden
                    onShow: function () { }, // callback function to be executed when window become visible
                    keepTracking: true, // set it to false if you want to be notified only on the first idleness change
                    startAtIdle: false // set it to true if you want to start in the idle state
                })
                timer.current = timerData;
                timer.current.start();
            }
            setTimeout(() => {
                forceUpdate(!renderer)
            }, 2 * 60 * 1000);
        }
    }, [businessLoaded])

    const setStandardScaling = () => {
        if (window.innerWidth < BREAKPOINTS.mobileLarge) {
            setStageScale(0.49)
        } else if (window.innerWidth <= BREAKPOINTS.tablet) {
            setStageScale(0.6)
        } else if (window.innerWidth < BREAKPOINTS.desktopMid) {
            setStageScale(0.80)
        } else {
            setStageScale(1)
        }
        setSideBarWidth(undefined)
        if (window.innerWidth >= BREAKPOINTS.mobileLarge) {
            setStageWidth(scaleWrapper.current?.getBoundingClientRect()?.width)
            setStageHeight(scaleWrapper.current?.getBoundingClientRect()?.height)
        }
    }

    const exitFullScreen = () => {
        if (isFullScreen.current) {
            toggleFullScreen(false, timelineWrapper.current)
        }
    }

    const onExitFullScreen = () => {
        document.addEventListener('fullscreenchange', exitFullScreen, false);
        document.addEventListener('mozfullscreenchange', exitFullScreen, false);
        document.addEventListener('MSFullscreenChange', exitFullScreen, false);
        document.addEventListener('webkitfullscreenchange', exitFullScreen, false);
    }

    const unmountFullScreen = () => {
        document.removeEventListener('fullscreenchange', exitFullScreen, false);
        document.removeEventListener('mozfullscreenchange', exitFullScreen, false);
        document.removeEventListener('MSFullscreenChange', exitFullScreen, false);
        document.removeEventListener('webkitfullscreenchange', exitFullScreen, false);
    }

    const toggleFullScreen = (value: boolean, element: HTMLDivElement) => {
        setFullscreen(value)
        setTimeout(() => {
            isFullScreen.current = value;
        }, 1000);

        if (value) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                //@ts-ignore
            } else if (element.mozRequestFullScreen) {
                /* Firefox */
                //@ts-ignore
                element.mozRequestFullScreen();
                //@ts-ignore
            } else if (element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                //@ts-ignore
                element.webkitRequestFullscreen();
                //@ts-ignore
            } else if (element.msRequestFullscreen) {
                /* IE/Edge */
                //@ts-ignore
                element.msRequestFullscreen();
            }
        } else {
            if (window.document.exitFullscreen) {
                window.document.exitFullscreen();
                //@ts-ignore
            } else if (window.document.mozCancelFullScreen) {
                /* Firefox */
                //@ts-ignore
                window.document.mozCancelFullScreen();
                //@ts-ignore
            } else if (window.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                //@ts-ignore
                window.document.webkitExitFullscreen();
                //@ts-ignore
            } else if (window.document.msExitFullscreen) {
                /* IE/Edge */
                //@ts-ignore
                window.document.msExitFullscreen();
            }
        }
    }

    const refreshBookings = () => {
        if (offline) return;
        if (mounted.current) setTimeout(() => {
            if (!preventRefresh.current) refreshData(selectedDateRef.current)
            refreshBookings()
        }, 30000)
    }

    const load = (searchDate: Date, keepData?: boolean) => {
        if (!mounted.current) return;
        if (!keepData && !offline) setData(undefined)
        scrolled.current = false;
        selectedDateRef.current = searchDate;
        const date = createMomentFromValue(searchDate);

        //(offline ? BookingCacheService.GetBookingsForDate(date) : ApiService.bookingManagement.Get__GET(businessData.id, businessData.parentBusinessId, businessData.locationId, formatDate(date, DATABASE_TIME_FORMAT))).then(response => {
        ApiService.bookingManagement.Get__GET(businessData.id, businessData.parentBusinessId, businessData.locationId, formatDate(date, DATABASE_TIME_FORMAT)).then(response => {
            if (response) {
                if (offlineError) setOfflineError(false);
            } else {
                setOfflineError(true);
                return;
            }
            const newSetup: BookingTableSetup = {};
            const tables: ExtendedTable[] = [];
            Object.keys(response.areaAndTables).forEach(areaId => {
                Object.keys(response.areaAndTables[areaId].tables).forEach(tableId => {
                    tables.push({ ...response.areaAndTables[areaId].tables[tableId], width: TABLEWIDTH, height: 0 });
                })
            })
            const managementData: ExtendedBookingManagementResponse = {
                ...response,
                tables,
                bookings: Object.keys(response.bookingIdAndBookingDetails).map(bookingId => ({ ...response.bookingIdAndBookingDetails[bookingId] }))
            }
            tables.forEach(table => {
                newSetup[table.id] = {
                    bookings: managementData.tableAndBookings[table.id]?.bookingIdentifiers.filter(x => response.bookingIdAndBookingDetails[x].status === BookingStatus.Completed || response.bookingIdAndBookingDetails[x].status === BookingStatus.Pending || response.bookingIdAndBookingDetails[x].status === BookingStatus.Seated).map(x => response.bookingIdAndBookingDetails[x]),
                    table
                }
            });
            const dayOfWeek = createMomentFromValue(searchDate).format('dddd').toLowerCase();
            const openingTimesForDay = managementData.openingTimes.filter(x => x.dayOfWeek.toLowerCase() == dayOfWeek.toLowerCase());
            let shift = 0;
            if (dateIsToday(searchDate)) {
                let selectedOpeningTimeSlot = response.currentShiftSortOrder;
                if (selectedOpeningTimeSlot > -1) {
                    shift = selectedOpeningTimeSlot;
                    setSelectedShift(selectedOpeningTimeSlot)
                    selectedShiftRef.current = selectedOpeningTimeSlot;
                } else {
                    setSelectedShift(0)
                    selectedShiftRef.current = 0;
                }
            } else {
                setSelectedShift(0)
                selectedShiftRef.current = 0;
            }
            setData(newSetup);
            setBookings(managementData.bookings);
            const storedSelectedArea = sessionStorage.getItem('selectedArea');
            if (storedSelectedArea) {
                const area = JSON.parse(storedSelectedArea) as AreaAndTablesForTimeline;
                if (area.businessId === businessData.id) {
                    selectArea(area);
                } else {
                    selectArea(Object.values(managementData.areaAndTables)[0])
                }
            } else {
                selectArea(Object.values(managementData.areaAndTables)[0])
            }
            buildTimelineData(managementData, searchDate, openingTimesForDay[shift]);
            setBookingManagementData(managementData);
            BookingService.BookingManagementData = managementData;
            // BookingCacheService.GetBookingsForDate(date)
            // BookingCacheService.UpsertBookingsForDate(date, managementData);
            bookingManagementDataRef.current = managementData;
            getDateBands(searchDate);
        })
    }

    const refreshData = (searchDate: Date, setRefreshSpinner?: boolean, initialShift: number = 0) => {
        if (!mounted.current) return;
        if (offline) {
            load(searchDate);
            return;
        }
        if (setRefreshSpinner) setRefreshing(true);
        scrolled.current = false;
        const differentDate = formatDate(selectedDateRef.current, DATEONLYFORMAT) != formatDate(searchDate, DATEONLYFORMAT);
        if (differentDate) {
            selectedExperienceRef.current = undefined;
            setSelectedExperience(undefined)
        }
        selectedDateRef.current = searchDate;
        const date = createMomentFromValue(searchDate);

        ApiService.bookingManagement.GetUpdated__GET(businessData.id, businessData.locationId, formatDate(date, DATABASE_TIME_FORMAT), differentDate).then(response => {
            const newSetup: BookingTableSetup = {};
            const managementData: ExtendedBookingManagementResponse = {
                ...bookingManagementDataRef.current,
                bookings: Object.keys(response.bookingIdAndBookingDetails).map(bookingId => ({ ...response.bookingIdAndBookingDetails[bookingId] })),
                availability: response.availability,
                isOpenNow: response.isOpenNow,
                exceptionTimeForDate: response.exceptionTimeForDate,
                tableAndBookings: response.tableAndBookings,
                bookingIdAndBookingDetails: response.bookingIdAndBookingDetails,
                closedToday: response.closedToday
            }

            if (differentDate) {
                managementData.eventsForDay = response.eventsForDay;
                managementData.experiencesForDay = response.experiencesForDay;
                managementData.tableIdsBlockedOnAllTimeSlots = response.tableIdsBlockedOnAllTimeSlots;
                managementData.timeSlotsAndBlockedTables = response.timeSlotsAndBlockedTables;
            }
            bookingManagementDataRef.current.tables.forEach(table => {
                newSetup[table.id] = {
                    bookings: managementData.tableAndBookings[table.id]?.bookingIdentifiers.filter(x =>
                        response.bookingIdAndBookingDetails[x].status === BookingStatus.Completed ||
                        response.bookingIdAndBookingDetails[x].status === BookingStatus.Pending ||
                        response.bookingIdAndBookingDetails[x].status === BookingStatus.Seated)
                        .map(x => response.bookingIdAndBookingDetails[x]),
                    table
                }
            });
            const dayOfWeek = createMomentFromValue(searchDate).format('dddd').toLowerCase();
            const openingTimesForDay = managementData.openingTimes.filter(x => x.dayOfWeek.toLowerCase() == dayOfWeek.toLowerCase());
            let shift = differentDate ? initialShift : selectedShiftRef.current;
            if (differentDate) {
                let selectedOpeningTimeSlot = dateIsToday(searchDate) ? response.currentShiftSortOrder : 1; // future dates - default to first shift
                if (selectedOpeningTimeSlot > -1) {
                    shift = selectedOpeningTimeSlot;
                    setSelectedShift(selectedOpeningTimeSlot)
                    selectedShiftRef.current = selectedOpeningTimeSlot;
                } else {
                    setSelectedShift(0)
                    selectedShiftRef.current = 0;
                }
            } else {
                shift = selectedShiftRef.current;
            }
            setData(newSetup);
            setBookings(managementData.bookings);
            buildTimelineData(managementData, searchDate, openingTimesForDay[shift]);
            setBookingManagementData(managementData);
            BookingService.BookingManagementData = managementData;
            bookingManagementDataRef.current = managementData;
            // BookingCacheService.UpsertBookingsForDate(date, managementData);
            setTimelineLoading(false);
            if (differentDate) getDateBands(
                searchDate,
            );
            if (setRefreshSpinner) setRefreshing(false);
        })
    }

    const buildTimelineData = (response: ExtendedBookingManagementResponse, searchDate: Date, openingTime: BaseBusinessOpeningTimesRange) => {
        timelineRows.current = [];
        timelineCounts.current = {};
        pushedUnallocatedRow.current = false;
        response.bookings.forEach(booking => {
            if (booking.tables.length === 0) {
                if (!pushedUnallocatedRow.current) {
                    timelineRows.current.push({ startOfSection: true, name: 'Unallocated tables', id: -1 })
                    pushedUnallocatedRow.current = true;
                }
                const row: TimelineRow = {
                    startOfSection: false,
                    sectionId: -1,
                    name: '-',
                    secondaryName: '-',
                    blocked: true,
                    id: -1,
                    items: {}
                }
                setBookingOnRow(response, booking, row, searchDate);
                timelineRows.current.push(row);
            }
        })
        Object.entries(response.areaAndTables).forEach(([areaId, area]) => {
            if (Object.keys(response.areaAndTables).length > 1 || pushedUnallocatedRow.current) timelineRows.current.push({ startOfSection: true, name: area.areaName, id: area.areaId, sectionId: area.areaId });
            const areaTables = response.tables.filter(x => x.areaId == area.areaId);
            const sortedTables = areaTables.sort((a, b) => a.tableName.localeCompare(b.tableName, undefined, { numeric: true }));
            sortedTables.forEach((table, tableIndex) => {
                const row: TimelineRow = {
                    startOfSection: false,
                    sectionId: area.areaId,
                    name: table.tableName,
                    blocked: table.isTableExtension,
                    secondaryName: table.seats.toString(),
                    id: table.id,
                    items: {}
                }
                response.bookings.forEach(booking => {
                    if (booking.tables.includes(table.id)) setBookingOnRow(response, booking, row, searchDate, sortedTables, tableIndex, table);
                });
                timelineRows.current.push(row);
            });
        });
    }

    const setBookingOnRow = (response: ExtendedBookingManagementResponse, booking: BaseActiveBooking, row: TimelineRow, searchDate: Date, sortedTables?: ExtendedTable[], tableIndex?: number, table?: ExtendedTable) => {
        if (booking.status !== BookingStatus.Cancelled && booking.status !== BookingStatus.NoShow) {
            let rowsToExpand = 1;
            let extraGuests = 0;
            if (sortedTables) {
                for (let i = tableIndex + 1; i < sortedTables.length; i++) {
                    if (booking.tables.includes(sortedTables[i].id)) {
                        rowsToExpand++;
                        const bookedTable = booking.bookedTables.find(x => x.setupTableId == sortedTables[i].id);
                        if (bookedTable) extraGuests += bookedTable.allocatedGuests;
                    } else {
                        break;
                    }
                }
            }

            if (!sortedTables || !booking.tables.includes(sortedTables[tableIndex - 1]?.id)) {
                let bookingName = '';
                const bookingColour = GetStatusColour(booking);
                const textColour = GetStatusTextColour(booking);
                const guestsToDisplay = table ? booking.bookedTables.find(x => x.setupTableId === table.id).allocatedGuests + extraGuests : booking.guests;
                if (!timelineCounts.current.hasOwnProperty(formatDate(booking.startDateTime, 'HH:mm'))) {
                    timelineCounts.current[formatDate(booking.startDateTime, 'HH:mm')] = 0;
                }
                timelineCounts.current[formatDate(booking.startDateTime, 'HH:mm')] += guestsToDisplay;
                if (booking.isHeld) {
                    bookingName = 'HELD';
                } else {
                    bookingName = `${booking.isWalkIn ? 'Walk in' : `${booking.client?.firstName} ${booking.client?.surname ? booking.client?.surname : ''}`}`;
                }
                const slotsToUse = booking.bookingChangeoverDurationInMinutes / TIMELINE_INTERVAL;
                row.items[createMomentFromValue(booking.startDateTime).format('HH:mm')] = {
                    rows: rowsToExpand,
                    name: <div style={{ color: textColour, userSelect: 'none' }}>
                        {booking.tables.length > 1 &&
                            <>
                                <MergeIcon name='arrows-to-line' duo />
                                &nbsp;
                            </>}
                        {(booking.hasFufilledPayments || booking.hasOustandingPayments) && <><MergeIcon name='credit-card' />&nbsp;</>}
                        {(!isNullOrWhitespace(booking.specialRequests) || !isNullOrWhitespace(booking.notes) || !isNullOrWhitespace(booking.dietaryRequirements)) && <><MergeIcon name='memo-pad' />&nbsp;</>}
                        {!!booking.experienceId && response.upcomingExperiencesAndEvents && response.upcomingExperiencesAndEvents.eventIdAndInfo[booking.experienceId]?.type === ExperienceType.Experience && <><MergeIcon name='sparkles' />&nbsp;</>}
                        {!!booking.experienceId && response.upcomingExperiencesAndEvents && response.upcomingExperiencesAndEvents.eventIdAndInfo[booking.experienceId]?.type === ExperienceType.Event && <><MergeIcon name='calendar' />&nbsp;</>}
                        {booking.specialOccasion != SpecialOccasion.NotSet && <><BookingIcon name={BookingSpecialOccasions()[booking.specialOccasion].icon} /> </>}{bookingName} {dateIsToday(searchDate) && (booking.status === BookingStatus.Seated || booking.status === BookingStatus.PartiallySeated) && createMomentFromValue(booking.endDateTime).isBefore(businessNowTime()) && <> - <OverrunningLabel>Overrunning</OverrunningLabel></>}
                        <TimelineBookingGuests style={{ color: textColour, backgroundColor: bookingColour }} slots={slotsToUse}>
                            {booking.isHeld ? '' : guestsToDisplay}{booking.tables.length > 1 && guestsToDisplay != booking.guests && <TimelineBookingExtraInfo>({booking.guests})</TimelineBookingExtraInfo>}
                        </TimelineBookingGuests>
                        {booking.bookingChangeoverDurationInMinutes > 0 && <TimelineBookingChangeover slots={slotsToUse} />}
                    </div>,
                    tooltip: getBookingTooltip(false, booking, bookingName, response?.upcomingExperiencesAndEvents?.eventIdAndInfo),
                    duration: booking.totalDurationIncludingChangeover,
                    onClick: () => openViewBooking(booking),
                    backgroundColour: bookingColour,
                    textColour: textColour,
                    canDrag: !booking.isHeld,
                    booking: booking,
                    id: booking.id
                };
            }
        }
    }

    const getDateBands = (searchDate: Date) => {
        const openingTimes = bookingManagementDataRef.current.openingTimes;
        const today = createMomentFromValue(searchDate).format('dddd');
        const todaysTimes = openingTimes?.filter(x => x.dayOfWeek.toString() === today) || [];
        let endTime = businessNowTime().endOf('day');
        if (todaysTimes[todaysTimes.length - 1].timeFromAfterTimeTo) {
            endTime = createMomentFromValue(endTime.format(DATEONLYFORMAT) + 'T' + createMomentFromValue(todaysTimes[todaysTimes.length - 1].timeTo).add(1, 'hour').format('HH:mm') + ':00').add(1, 'day')
        }
        const eventTimes = selectedExperienceRef.current && bookingManagementDataRef.current?.eventsForDay ? bookingManagementDataRef.current?.eventsForDay[selectedExperienceRef.current]?.experienceOrEventSlots || [] : undefined;
        const range = calculateTimes(
            businessNowTime().startOf('day'),
            endTime,
            searchDate,
            bookingManagementDataRef.current,
            todaysTimes,
            eventTimes
        );
        setTimes(range);
    }

    const onTimeDrop = (time: Date, rowId: number, previousRowId: number, itemId: number) => {
        const currentSelection = cloneDeep(bookingManagementData.bookings.find(x => x.id === itemId));
        const oldTime = createMomentFromValue(currentSelection.startDateTime).format('HH:mm');
        const newTime = createMomentFromValue(time).format('HH:mm');
        if (previousRowId == -1 && oldTime !== newTime) {
            setModalData({
                title: 'Are you sure you want to change the time of this booking?',
                small: true,
                slim: true,
                children: <Row>
                    <Column size={12}><strong>Previous time:</strong> {createMomentFromValue(currentSelection.startDateTime).format('HH:mm')}</Column>
                    <Column size={12}><strong>New time:</strong> {createMomentFromValue(time).format('HH:mm')}</Column>
                </Row>,
                actionBar: <CoreButton onClick={() => moveBooking(time, rowId, previousRowId, currentSelection)}>Confirm time change</CoreButton>
            })
        } else {
            moveBooking(time, rowId, previousRowId, currentSelection);
        }
    }

    const moveBooking = (time: Date, rowId: number, previousRowId: number, currentSelection: BaseActiveBooking) => {
        setTimelineLoading(true);
        setModalData(undefined)
        const newData = cloneDeep(data)
        currentSelection.startDateTime = createMomentFromValue(time).format(DATABASE_TIME_FORMAT);

        if (previousRowId > -1) {
            const table = currentSelection.bookedTables.find(x => x.setupTableId === previousRowId);
            table.setupTableId = rowId;
        } else {
            currentSelection.bookedTables.push({
                setupTableId: rowId,
                id: 0,
                allocatedGuests: currentSelection.guests,
                bookingId: currentSelection.id
            })
        }

        const amendmentRequest: AmendBookingRequest = {
            bookingReference: currentSelection.bookingReference,
            bookingId: currentSelection.id,
            guests: currentSelection.guests,
            experienceId: currentSelection.experienceId,
            startDateTime: currentSelection.startDateTime,
            desiredDurationInMinutesWithoutChangeover: currentSelection.bookingDurationInMinutes,
            tableIds: currentSelection.bookedTables.map(x => x.setupTableId)
        }
        ApiService.bookings.AmendSave__POST(amendmentRequest).then((response: BookingAmendmentResponse) => {
            if (response.success) {
                setData(newData);
                refreshData(date, false);
                NotificationService.Confirm('Booking successfully moved');
            } else {
                if (isNullOrWhitespace(response.errorMessage)) {
                    NotificationService.Error('Could not move booking.');
                } else {
                    NotificationService.Error(response.errorMessage);
                }
                setTimelineLoading(false)
            }
        }).catch(() => {
            NotificationService.Error('Could not move booking.');
            setTimelineLoading(false)
        })
    }

    const setNewBooking = (time, table: BaseBookingTableSetup, timeIsNow: boolean = false) => {
        setModalTable(table)
        selectedTimeIsNow.current = timeIsNow;
        const roundedTime = RoundTo15(createMomentFromValue(time).toDate(), TIMELINE_INTERVAL)
        const timeValue = createMomentFromValue(roundedTime).format('HH:mm');
        const dateToSet = `${businessNowTime().format('YYYY-MM-DD')}T${timeValue}:00`;
        setNewBookingDate(dateToSet);
        preventRefresh.current = true;
        setNewBookingOpen(true);
    }

    const closeModal = (refresh?: boolean) => {
        refreshData(date, false)
        if (newBookingDate) setNewBookingDate(undefined)
        preventRefresh.current = false;
        if (newBookingOpen) setNewBookingOpen(false)
        if (modalTable) setModalTable(undefined)
        if (viewBooking) setViewBooking(undefined)
        if (selectedTable) selectTable(undefined)
    }

    const onUpdateBooking = (showNotification: boolean) => {
        if (showNotification) {
            NotificationService.Confirm('Booking updated')
        }
        preventRefresh.current = false;
        refreshData(date, false)
        setViewBooking(undefined)
        setNewBookingDate(undefined)
        setModalTable(undefined)
        selectTable(undefined)
    }

    const openViewBooking = (booking: BaseActiveBooking) => {
        preventRefresh.current = true;
        setViewBooking(booking)
    }

    const updateSelectedDate = (newDate: Moment | Date, plusMinus: number = 0) => {
        if (plusMinus === 0 && createMomentFromValue(selectedDateRef.current).format(DATEONLYFORMAT) === createMomentFromValue(newDate).format(DATEONLYFORMAT)) {
            return;
        }

        if (!moment.isMoment(newDate)) {
            newDate = createMomentFromValue(newDate)
        }

        if (businessNowTime().format(DATEONLYFORMAT) === createMomentFromValue(newDate).format(DATEONLYFORMAT)) {
            newDate = businessNowTime();
        }

        if (plusMinus !== 0) {
            if (plusMinus > 0) {
                newDate = newDate.add(plusMinus, 'd')
            } else {
                newDate = newDate.subtract(Math.abs(plusMinus), 'd')
            }
        }
        if (!newDate.isSame(createMomentFromValue(date))) {
            const dateValue = newDate.toDate();
            setDate(dateValue)
            refreshData(dateValue, true)
        }
    }

    const openNewBookingForWaitlist = (item: WaitlistItem) => {
        if (!offline) {
            waitListItem.current = item;
            preventRefresh.current = true;
            setNewBookingOpen(true);
        }
    }

    const updateSelectedExperience = (experienceId?: number) => {
        const newExperienceId = experienceId == selectedExperience ? undefined : experienceId;
        const areasInView = getAreasForTimeline(newExperienceId);
        for (let i = 0; i < Object.entries(bookingManagementData.areaAndTables).length; i++) {
            const shouldShow = shouldShowArea(+Object.entries(bookingManagementData.areaAndTables)[i][0], areasInView.showOnlySectionsAndTables, areasInView.hideSectionsAndTables);
            if (shouldShow) {
                selectArea(Object.entries(bookingManagementData.areaAndTables)[i][1]);
                break;
            }
        }
        selectedExperienceRef.current = newExperienceId;
        setSelectedExperience(newExperienceId)
        getDateBands(date)
    }

    const getAreasForTimeline = (experienceId?: number): DisplayAreas => {
        const returnValue: DisplayAreas = {
            showOnlySectionsAndTables: undefined,
            hideSectionsAndTables: undefined
        }

        if (!bookingManagementData?.eventsForDay) return returnValue;
        if (!!experienceId) {
            returnValue.showOnlySectionsAndTables = bookingManagementData.eventsForDay[experienceId]?.areaIdsAndTables;
            if (bookingManagementData.bookings.find(x => x.bookedTables.length == 0)) {
                returnValue.showOnlySectionsAndTables = {
                    ...returnValue.showOnlySectionsAndTables,
                    [-1]: {
                        wholeAreaSelected: true
                    }
                }
            }
        } else {
            const allResults: { [key: string | number]: EventAreaSelection } = {};
            if (bookingManagementData.eventsForDay) {
                Object.keys(bookingManagementData.areaAndTables).forEach(areaId => {
                    if (
                        bookingManagementData.areaAndTables[areaId].experienceId &&
                        !bookingManagementData.experiencesForDay[bookingManagementData.areaAndTables[areaId].experienceId]
                    ) {
                        allResults[areaId] = {
                            wholeAreaSelected: true,
                            selectedTableIds: {}
                        }
                    }
                });
            }
            returnValue.hideSectionsAndTables = allResults;
        }
        return returnValue;
    }

    const activeBookings: BaseActiveBooking[] = [];
    if (tabIndex === 0) {
        bookings?.forEach(item => {
            if (item.status !== BookingStatus.Cancelled && item.status !== BookingStatus.Completed && item.status !== BookingStatus.NoShow) {
                const timeMatches = selectedShift == item.shiftSortOrder || item.experienceType == ExperienceType.Event; // event bookings dont have shifts
                if (timeMatches) activeBookings.push(item);
            }
        });
    }

    const hasOpeningTime = bookingManagementData?.openingTimes.length > 0 && bookingManagementData?.openingTimes.some(x => !x.closed);

    const modalDate = `${createMomentFromValue(date).format(DATEONLYFORMAT)}T${createMomentFromValue(newBookingDate || RoundTo15(new Date())).format('HH:mm')}:00`;

    const CustomInput = forwardRef(({ dateVal, onClick }: any, ref: any) => (
        <BaseCalendarInput onClick={onClick} ref={ref}>
            {createMomentFromValue(date).format('ddd, Do MMM yyyy')}
        </BaseCalendarInput>
    ));

    const areasInView = getAreasForTimeline(selectedExperience);

    const areaTabValid = (area: AreaAndTablesForTimeline) => {
        if (!area.experienceId) return true;
        if (selectedExperienceRef.current) {
            return bookingManagementDataRef.current.eventsForDay &&
                bookingManagementDataRef.current.eventsForDay[selectedExperienceRef.current].areaIdsAndTables[area.areaId]
        } else {
            return bookingManagementDataRef.current.experiencesForDay &&
                Object.keys(bookingManagementDataRef.current.experiencesForDay).find(x =>
                    bookingManagementDataRef.current.experiencesForDay[x].areaIdsAndTables[area.areaId]
                )
        }
    }

    const shiftLabels: string[] = (todaysExceptionTimes ? todaysExceptionTimes.exceptionTimes.map(x => x.shiftLabel) : todaysTimes?.map(x => x.shiftLabel)) || [];

    const bookingOverview = (): {
        bookings: number,
        guests: number,
        cancellations: number,
        noShows: number,
    } => {
        const confirmedBookings = (bookingManagementData?.bookings || []).filter(x => x.status !== BookingStatus.Cancelled && x.status!== BookingStatus.NoShow);

        return {
            bookings: confirmedBookings.length,
            guests: confirmedBookings.reduce((a, b) => a + b.guests, 0),
            cancellations: (bookingManagementData?.bookings || []).filter(x => x.status === BookingStatus.Cancelled).length,
            noShows: (bookingManagementData?.bookings || []).filter(x => x.status === BookingStatus.NoShow).length
        }
    }

    const bookingDetails = bookingOverview();
    let distanceFromTop: number = undefined;
    if (fullscreen) {
        const wrapperFromTop = scaleWrapper.current?.getBoundingClientRect()?.top;
        distanceFromTop = (wrapperFromTop || 650);
    }
    return (
        <ModalContext.Provider value={timelineWrapper.current}>
            <DashboardHeader icon='tasks' title='Bookings'></DashboardHeader>
            <InfoModule />
            {!offline && <BugButton onClick={() => SupportTicketService.NewTicket({
                id: undefined,
                parentBusinessId: businessData.parentBusinessId,
                businessId: businessData.id,
                dateRaisedUtc: undefined,
                priority: undefined,
                status: undefined,
                summary: 'Error on bookings screen',
                devLogs: JSON.stringify({
                    selectedDate: formatDate(date),
                    systemDateTime: formatDate(businessNowTime()),
                    bookingManagementData: bookingManagementData,
                    selectedTab: tabIndex
                })
            })} />}
            {businessData && !offline &&
                <StopSaleModule
                    openingTimes={todaysTimes || []}
                    bookingDate={createMomentFromValue(date).startOf('day').format(DATABASE_TIME_FORMAT)}
                    businessId={businessData.id}
                    exceptionTimes={todaysExceptionTimes || null}
                    closedToday={bookingManagementData?.closedToday}
                    events={bookingManagementData?.eventsForDay}
                />
            }
            {!data && <Loader />}
            {data && selectedArea && bookingManagementData?.tables.length > 0 && hasOpeningTime &&
                <>
                    <div style={{ marginBottom: '0.5rem' }}>
                        <StatusBadge colorPalette='secondary'>
                            {bookingDetails.guests} cover{bookingDetails.guests != 1 && 's'}
                            ,&nbsp;{bookingDetails.bookings} booking{bookingDetails.bookings != 1 && 's'}
                            <>,&nbsp;{bookingDetails.cancellations} cancellation{bookingDetails.cancellations != 1 && 's'}</>
                            {bookingDetails.noShows > 0 && <>,&nbsp;{bookingDetails.noShows} no show{bookingDetails.noShows != 1 && 's'}</>}
                        </StatusBadge>
                        {Object.keys(bookingManagementData.eventsForDay || {}).map(key => (
                            <StatusExperienceBadge experienceId={+key} colorPalette='cyan' validSlotsForDay={bookingManagementData?.eventsForDay[key]?.experienceOrEventSlots}><Icon name='calendar' />{bookingManagementData.eventsForDay[key].name}</StatusExperienceBadge>
                        ))}
                        {Object.keys(bookingManagementData.experiencesForDay || {}).map(key => (
                            <StatusExperienceBadge experienceId={+key} validSlotsForDay={bookingManagementData?.experiencesForDay[key]?.experienceOrEventSlots}><Icon name='sparkles' />{bookingManagementData.experiencesForDay[key].name}</StatusExperienceBadge>
                        ))}
                    </div>
                    <TimelineWrapper ref={timelineWrapper} fullscreen={fullscreen}>
                        <Toaster portalRef={timelineWrapper} useToaster={toaster} />
                        {showLock && <LockScreen onTouchStart={() => { setShowLock(true); lockRef.current = true; }}>{businessData?.parentBusiness?.name}</LockScreen>}
                        {newBookingOpen &&
                            <NewBookingModal
                                businessId={businessData.id}
                                bookingManagementData={bookingManagementData}
                                areas={bookingManagementData.areaAndTables}
                                availability={bookingManagementData.availability}
                                tables={cloneDeep(bookingManagementData.tables)}
                                table={modalTable}
                                autoSeat={selectedTimeIsNow.current}
                                dateTime={modalDate}
                                locationId={businessData.locationId}
                                waitlistItem={waitListItem.current}
                                onClose={closeModal} />
                        }
                        {modalData &&
                            <CoreModal hasCancel={!modalData.hideCancelButton} slimPanel={modalData.slim} small={modalData.small} onClose={() => setModalData(undefined)} actionBar={modalData.actionBar} title={modalData.title}>
                                {modalData.children}
                            </CoreModal>
                        }
                        {viewBooking &&
                            <BookingInfoModal
                                bookingManagementData={bookingManagementData}
                                tables={cloneDeep(bookingManagementData.tables)}
                                booking={viewBooking}
                                locationId={businessData.locationId}
                                onClose={closeModal}
                                onUpdate={onUpdateBooking}
                                updateBooking={(booking) => { openViewBooking(booking); setTimelineLoading(true); refreshData(date); }}
                            />
                        }
                        <MainControlsContainer>
                            <ControlsTwo>
                                <ControlLayoutTwo isIpad={fullscreen && isAppleSafari()}>
                                    <ControlButton id='table-layout' onClick={() => { setTabIndex(0); pushEvent('bookingTabClick', { 'tab': 'tables' }) }} margin primary={tabIndex === 0}>
                                        <Icon name='dining-table' /> Tables &nbsp;
                                    </ControlButton>
                                    <ControlButton onClick={() => {
                                        setTabIndex(1);
                                        pushEvent('bookingTabClick', { 'tab': 'timeline' })
                                    }} margin primary={tabIndex === 1}>
                                        <Icon name='stream' /> Timeline &nbsp;
                                    </ControlButton>
                                    <ControlButton onClick={() => { setTabIndex(2); pushEvent('bookingTabClick', { 'tab': 'list' }) }} margin primary={tabIndex === 2}>
                                        <Icon name='list' /> List &nbsp;
                                    </ControlButton>
                                    {/* <ControlButton onClick={() => { setTabIndex(3); pushEvent('bookingTabClick', { 'tab': 'waitlist' }) }} margin primary={tabIndex === 3}>
                                        <Icon name='hourglass-start' /> Waitlist &nbsp;
                                    </ControlButton> */}
                                    <ControlButtonBookMobile disabled={offline} onClick={() => { if (!offline) { setNewBookingOpen(true); preventRefresh.current = true; } }} primary>
                                        <Icon customMargin={0.3} name='plus' />&nbsp;Book&nbsp;
                                    </ControlButtonBookMobile>
                                </ControlLayoutTwo>
                                <ControlTwoDatePicker>
                                    <ControlStyledCalendar
                                        hasPlusMinus
                                        value={createMomentFromValue(date).format(DATEONLYFORMAT)}
                                        onChange={(value) => updateSelectedDate(value)}
                                        dateFormat='ddd, do MMM yyyy'
                                        customInput={<CustomInput />}
                                        calendarProps={{
                                            onKeyDown: (e) => {
                                                e.preventDefault();
                                            },
                                            withPortal: true
                                        }}
                                    />
                                </ControlTwoDatePicker>
                                <ControlTwoBook>
                                    <ControlButtonBook disabled={offline} onClick={() => { if (!offline) { setNewBookingOpen(true); preventRefresh.current = true; } }} primary>
                                        <Icon customMargin={0.3} name='plus' />&nbsp;Book&nbsp;
                                    </ControlButtonBook>
                                </ControlTwoBook>
                                <ControlLayoutTwo>
                                    <ControlTwo alwaysFlex>
                                        <ShiftNotes
                                            bookings={bookings}
                                            tables={bookingManagementData.tables}
                                            selectedDate={createMomentFromValue(date).format(DATEONLYFORMAT) + 'T00:00:00'}
                                            businessId={businessData.id}
                                            refreshData={() => refreshData(date, false)}
                                        />
                                    </ControlTwo>
                                    <ControlTwo alwaysFlex>
                                        <NotificationsModal disabled={!selectedIsToday} />
                                    </ControlTwo>
                                    <ControlTwo alwaysFlex>
                                        <ControlButtonPassive onClick={() => updateSelectedDate(businessNowTime().startOf('day'))}>
                                            <Icon name='clock' /> {businessNowTime().format(TIMEFORMAT)}
                                        </ControlButtonPassive>
                                    </ControlTwo>
                                    {/* <ControlTwo alwaysFlex>
                                        <PointOfSaleWrapper />
                                    </ControlTwo> */}
                                    <ControlTwo alwaysFlex>
                                        <ControlButton noFlex onClick={() => toggleFullScreen(!fullscreen, timelineWrapper.current)}>
                                            <Icon duo name={fullscreen ? 'compress-arrows-alt' : 'expand-arrows-alt'} />
                                        </ControlButton>
                                    </ControlTwo>
                                </ControlLayoutTwo>
                            </ControlsTwo>
                        </MainControlsContainer>
                        {!refreshing && pushedUnallocatedRow.current &&
                            <WarningMessage>You have unallocated tables. Please temporarily close online bookings for this day/shift to avoid potential overbooking.</WarningMessage>
                        }
                        {offline &&
                            <ErrorMessage>You are currently offline. The bookings on your screen are a snapshot of when you were last online.</ErrorMessage>
                        }
                        {offlineError &&
                            <ActionBox
                                icon='user-robot-xmarks'
                                title='Cannot get these bookings while offline'
                            >
                                Either you are offline or we cannot fetch bookings. Please try loading bookings for this date later.
                            </ActionBox>
                        }
                        {!offlineError && !refreshing && tabIndex === 1 &&
                            <>
                                <StandardPanel>
                                    {Object.keys(bookingManagementData.eventsForDay || {}).length > 0 &&
                                        <SubControlsContainer noPadding>
                                            <EventOptions>
                                                <Tag selected={!selectedExperience} onClick={() => updateSelectedExperience(undefined)}>Standard bookings</Tag>
                                                {Object.keys(bookingManagementData.eventsForDay).map(key => (
                                                    <Tag key={'experience-' + key} selected={selectedExperience == +key} onClick={() => updateSelectedExperience(+key)}><Icon name='calendar' /> {bookingManagementData.eventsForDay[key].name}</Tag>
                                                ))}
                                            </EventOptions>
                                        </SubControlsContainer>
                                    }
                                    <Timeline
                                        rowKeys={timelineRows.current}
                                        primaryIcon={<Icon name='hashtag' />}
                                        secondaryIcon={<Icon duo name='users' />}
                                        times={times}
                                        subHeaderInfo={timelineCounts.current}
                                        date={createMomentFromValue(date)}
                                        onTimeDrop={onTimeDrop}
                                        selectedExperience={selectedExperience}
                                        updating={timelineLoading}
                                        onTimeClick={(time, tableId, isNow) => setNewBooking(time, bookingManagementData.tables.find(table => table.id === tableId), isNow)}
                                        {...areasInView}
                                    />
                                </StandardPanel>
                            </>
                        }
                        {!offlineError && !refreshing && tabIndex === 2 &&
                            <>
                                <StandardPanel>
                                    {Object.keys(bookingManagementData.eventsForDay || {}).length > 0 &&
                                        <SubControlsContainer noPadding>
                                            <EventOptions>
                                                <Tag selected={!selectedExperience} onClick={() => updateSelectedExperience(undefined)}>Standard bookings</Tag>
                                                {Object.keys(bookingManagementData.eventsForDay).map(key => (
                                                    <Tag key={'experience-' + key} selected={selectedExperience == +key} onClick={() => updateSelectedExperience(+key)}><Icon name='calendar' /> {bookingManagementData.eventsForDay[key].name}</Tag>
                                                ))}
                                            </EventOptions>
                                        </SubControlsContainer>
                                    }
                                    <BookingList
                                        bookings={bookings}
                                        selectedExperience={selectedExperience}
                                        bookingManagementData={bookingManagementData}
                                        tables={bookingManagementData.tables}
                                        experiences={bookingManagementData.upcomingExperiencesAndEvents?.eventIdAndInfo}
                                        setViewBooking={openViewBooking}
                                        onUpdateBooking={onUpdateBooking}
                                    />
                                </StandardPanel>
                            </>
                        }
                        {!offlineError && !refreshing && tabIndex === 0 &&
                            <ListWrap fullscreen={fullscreen}>
                                {(Object.keys(bookingManagementData.areaAndTables).length > 1 || Object.keys(bookingManagementData.eventsForDay || {}).length > 0) &&
                                    <SubControlsContainer noPadding>
                                        {Object.keys(bookingManagementData.eventsForDay || {}).length > 0 &&
                                            <EventOptions>
                                                <Tag selected={!selectedExperience} onClick={() => updateSelectedExperience(undefined)}>Standard bookings</Tag>
                                                {Object.keys(bookingManagementData.eventsForDay).map(key => (
                                                    <Tag key={'experience-' + key} selected={selectedExperience == +key} onClick={() => updateSelectedExperience(+key)}><Icon name='calendar' /> {bookingManagementData.eventsForDay[key].name}</Tag>
                                                ))}
                                            </EventOptions>
                                        }
                                        {Object.keys(bookingManagementData.areaAndTables).length > 1 &&
                                            <AreaTabs reverse noMarginBottom>
                                                {Object.entries(bookingManagementData.areaAndTables).map(([areaId, area]) => (
                                                    (
                                                        !areasInView ||
                                                        shouldShowArea(+areaId, areasInView.showOnlySectionsAndTables, areasInView.hideSectionsAndTables)
                                                    ) && areaTabValid(area)
                                                ) ? (
                                                    <TabButton key={`areaTab-${area.areaId}`} active={selectedArea.areaId === area.areaId} onClick={() => { selectArea(area); sessionStorage.setItem('selectedArea', JSON.stringify(area)) }}>
                                                        {area.areaName}
                                                    </TabButton>
                                                ) : <></>)}
                                            </AreaTabs>
                                        }
                                    </SubControlsContainer>
                                }
                                {!bookingManagementData.isOpenNow && selectedIsToday && <InfoMessage>Business is currently closed</InfoMessage>}
                                {!selectedIsToday && <WarningMessage>Table view is only active for today's date</WarningMessage>}
                                <FlexRow style={scaling ? { opacity: 0 } : undefined}>
                                    <UpcomingPanel containerWidth={sideBarWidth} distanceFromTop={distanceFromTop} stageWidth={stageWidth} stageHeight={stageHeight}>
                                        <ShiftTabs>
                                            {!selectedExperience && shiftLabels && shiftLabels.length > 1 && shiftLabels.map((time, index) => (
                                                <ShiftTab
                                                    active={index + 1 === selectedShift}
                                                    key={'shiftTab-' + time}
                                                    onClick={() => {
                                                        setSelectedShift(index + 1);
                                                        selectedShiftRef.current = index + 1;
                                                    }}
                                                >
                                                    {time}
                                                </ShiftTab>
                                            ))}
                                        </ShiftTabs>
                                        <BookingListPanel
                                            setViewBooking={openViewBooking}
                                            bookings={activeBookings}
                                            selectedExperience={selectedExperience}
                                            areas={bookingManagementData.areaAndTables}
                                            experiences={bookingManagementData.upcomingExperiencesAndEvents?.eventIdAndInfo}
                                            tables={bookingManagementData.tables} />
                                    </UpcomingPanel>
                                    <StageScale ref={onStageRefChange} style={{ transformOrigin: 'top left', scale: `${stageScale}` }}>
                                        <MainStage
                                            tables={bookingManagementData.tables}
                                            shapes={bookingManagementData.shapes}
                                            setLayout={() => { }}
                                            setShapes={() => { }}
                                            setArea={() => { }}
                                            save={() => { }}
                                            saveShape={() => { }}
                                            selectTable={selectTable}
                                            selectedTables={[selectedTable]}
                                            selectShape={() => { }}
                                            selectedArea={{ name: selectedArea.areaName, id: selectedArea.areaId, scale: selectedArea.areaScale }}
                                            browserScale={stageScale}
                                            selectedShape={null}
                                            bookings={activeBookings}
                                            setNewBooking={setNewBooking}
                                            setViewBooking={openViewBooking}
                                            bookingManagementData={{ ...bookingManagementData, isOpenNow: selectedIsToday ? bookingManagementData.isOpenNow : false }}
                                            readonly
                                        />
                                    </StageScale>
                                </FlexRow>
                            </ListWrap>
                        }
                        {!offlineError && !refreshing && tabIndex === 3 &&
                            <StandardPanel>
                                <WaitList onGuestSeat={openNewBookingForWaitlist} />
                            </StandardPanel>
                        }
                        {refreshing && <Loader />}
                    </TimelineWrapper>
                </>
            }
            {data && (!selectedArea || bookingManagementData?.tables.length === 0 || !hasOpeningTime) &&
                <ActionBox
                    icon={!hasOpeningTime ? 'clock' : 'dining-table'}
                    url={`/dashboard/${businessData.parentBusinessId}/locations/${businessData.locationId}/${!hasOpeningTime ? 'opening-times' : 'table-setup'}`}
                    title='Additional configuration needed'
                >
                    Additional configuration is needed before you can make bookings.
                    <br />
                    {!hasOpeningTime ?
                        'Please visit the opening times setup page and configure your opening times. At least 1 day needs to be open.'
                        :
                        'Please visit the booking setup page and configure at least 1 table.'
                    }
                </ActionBox>
            }
        </ModalContext.Provider>
    );
};

export default BookingTimeline;

const ListWrap = styled.div<{ fullscreen?: boolean }>`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        ${props => props.fullscreen && `height: calc(100vh - 145px);`}
        position: relative;
        overflow: scroll;
        width: 100%;
        flex: 1;
        -webkit-overflow-scrolling: touch;
    }
`;

const TimelineBookingChangeover = styled.div<{ slots: number }>`
    pointer-events: none;
    touch-action: none;
    position: absolute;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    background: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 5px,
        rgba(0, 0, 0, 0) 5px,
        rgba(0, 0, 0, 0) 10px
      );
    width: ${props => props.slots * 64}px;
    height: 100%;
    top: 0;
`

const TimelineBookingGuests = styled.div<{ slots: number }>`
    pointer-events: none;
    touch-action: none;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    font-weight: bold;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 1rem;
    right: ${props => (props.slots || 0) * 64}px;
`

const TimelineBookingExtraInfo = styled.div`
    display: inline-block;
    opacity: 0.5;
    margin-left: 0.5rem;
`

const ControlsTwo = styled.div<{ noMargin?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    ${props => props.noMargin ? `` : `
        //margin-bottom: 1rem;
    `}
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        flex-direction: column-reverse;
    }
`

const ControlTwo = styled.div<{ alwaysFlex?: boolean; }>`
    flex: 1 1 auto;
    padding: 0 0.2rem;
    text-align: center;
    display: flex;

    @media (max-width: ${BREAKPOINTS.desktopLarge}px) {
        margin-bottom: 0.5rem;
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        ${props => props.alwaysFlex ? 'width: auto !important!;' : 'width: 100%; flex: 1 1 auto; flex-wrap: wrap;'}
    }
`

const ControlTwoDatePicker = styled(ControlTwo)`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        order: 1;

        .react-datepicker__input-container div, .plusMinusToggle, .plusMinusToggle span {
            height: 2rem !important;
            line-height: 2rem !important;
            font-size: 1rem;
        }
    }
`

const ControlTwoBook = styled(ControlTwo)`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: none;
    }
`

const ControlStyledCalendar = styled(StyledCalendar)`
    width: 100%;
    input {
        text-align: center !important;
    }

    .react-datepicker__day--today:not(.react-datepicker__day--selected) {
        background-color: ${props => props.theme.tertiary};
        color: ${props => props.theme.tertiaryContrast};
        border-radius: 0.3rem;
    }
`

const OverrunningLabel = styled.i`
    color: white;
    background-color: #c60b0b;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
`

export const ControlButton = styled.div<{ floatRight?: boolean; primary?: boolean; margin?: boolean; disabled?: boolean; noFlex?: boolean; }>`
    ${props => props.floatRight && 'float: right;'}
    ${props => props.margin && 'margin-left: 0.3rem;'}
    border: 1px solid ${props => props.theme.borderColor};
    padding: 0 0.5rem;
    text-align: center;
    border-radius: 3px;
    height: 3rem;
    min-width: 3rem;
    line-height: 3rem;
    cursor: pointer;
    transition: all ease 0.5s;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    ${props => props.disabled && `opacity: 0.5;`}

    ${props => props.primary && `
        background-color: ${props.theme.secondary};
        color: ${props.theme.secondaryContrast};

        &:hover {
            background-color: ${props.theme.primary};
            color: ${props.theme.primaryContrast};
        }
    `}

    ${props => props.noFlex ? 'flex: none;' : 'flex: 1 1 auto;'}

    &:hover {
        border: 1px solid #000;
    }
    @media (max-width: 1280px) {
        // max-width: 3rem;
        overflow: hidden;
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        line-height: 2rem;
        height: 2rem;
    }
`

export const ControlButtonBook = styled(ControlButton)`
    overflow: hidden;
    
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        max-width: 100%;
        width: 100%;
        margin-top: 0.5rem;
        display: none;
    }
`

export const ControlButtonBookMobile = styled(ControlButton)`
    overflow: hidden;
    display: none;
    
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        max-width: 100%;
        width: 100%;
        display: inline-block;
    }
`

const ControlLayoutTwo = styled(ControlTwo) <{ isIpad?: boolean }>`
    display: flex;
    text-align: center;
    ${props => props.isIpad && `
        @media (max-width: ${BREAKPOINTS.desktopLarge}px) {
            padding-left: 5rem;
        }
    `}
    
    @media (max-width: ${BREAKPOINTS.desktopLarge}px) {
        margin-bottom: 0rem;

        div {
            max-width: 100%;
        }
    }

    ${ControlButton} {
        margin-right: 0.2rem;

        &::last-child {
            margin-right: 0rem;
        }

        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            flex: 1;

            .icon {
                width: 3rem;
            }
        }
    }
`

const ControlButtonPassive = styled.div<{ floatRight?: boolean; margin?: boolean; }>`
    ${props => props.floatRight && 'float: right;'}
    ${props => props.margin && 'margin-left: 0.5rem;'}
    padding: 0;
    text-align: center;
    border-radius: 3px;
    height: 3rem;
    min-width: 3rem;
    line-height: 3rem;
    cursor: pointer;
    transition: all ease 0.5s;
    display: inline-block;
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        text-align: center;
        width: 100%;
        line-height: 2rem;
        height: 2rem;
    }

    @media (max-width: ${BREAKPOINTS.desktopLarge}px) {
        .icon {
            display: none;
        }
    }
`

const StageScale = styled.div`
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        height: 20rem;
    }
`

const TimelineWrapper = styled.div<{ fullscreen?: boolean }>`
    background-color: ${props => props.theme.dashboardElevatedBackground};
    min-height: 16rem;

    ${props => props.fullscreen && `
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        max-width: 100%;

        .table-wrap {
            max-height: 50vh;
            overflow: auto;
        }
    `}
`

const BaseCalendarInput = styled.div`
    height: 3rem;
    line-height: 3rem;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #d6d6d6;
    transition: all ease 0.5s;
    outline: none;

    &.error {
        border: 1px solid red;
    }

    &:focus {
        border-color: black;
    }
`

const FlexRow = styled.div`
    display: flex;
    position: relative;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        flex-direction: column-reverse;

        div {
            flex: 1 0 auto;
        }
    }
`;

const ShiftTabs = styled.div`
    display: flex;
`

const ShiftTab = styled.div<{ active?: boolean }>`
    flex: 1;
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;
    transition: all ease 0.3s;
    border-right: 1px solid ${props => props.theme.borderColor};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:last-child {
        border: none;
    }

    ${props => props.active ? `
        background-color: ${props.theme.primary};
        color: ${props.theme.primaryContrast};
    ` : `
        &:hover {
            background-color: ${props => props.theme.secondary};
            color: ${props => props.theme.secondaryContrast};
        }
    `}

`

const UpcomingPanel = styled(BoxShadowStyle) <{ containerWidth?: number; distanceFromTop?: number; stageWidth?: number; stageHeight?: number; }>`
    ${props => props.containerWidth && props.containerWidth > 0 ? `
        width: ${props.containerWidth}px;
        min-width: ${props.containerWidth}px;
        flex: none;
    ` : `
        flex: 0 0 auto;
        max-height: ${props.stageHeight || 650}px;
        ${props.stageWidth && `width: calc(100% - ${props.stageWidth}px);`}

        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            max-height: unset;
        }
    `}
    ${props => props.distanceFromTop && `
        height: calc(100vh - ${props.distanceFromTop}px);
        max-height: calc(100vh - ${props.distanceFromTop}px);
    `}
    overflow: auto;
    background-color: ${props => props.theme.dashboardElevatedBackground};
`

const ControlsContainer = styled(BoxShadowStyle) <{ noPadding?: boolean; }>`
    background-color: ${props => props.theme.dashboardElevatedBackground};
    ${props => props.noPadding ? '' : 'padding: 0.5rem;'}
`

const MainControlsContainer = styled(ControlsContainer)`
    position: sticky;
    top: 0;
    z-index: 4;
`

const SubControlsContainer = styled.div<{ noPadding?: boolean; }>`
    display: flex;
    ${props => props.noPadding ? '' : 'padding: 0.5rem;'}
`

const AreaTabs = styled(TabBar)`
    flex: auto;
`

const EventOptions = styled.div`
    padding: 0.5rem;
    padding-top: 0.7rem;
`

const BookingIcon = styled(Icon)`
    display: inline-block !important;
    color: inherit !important;
    opacity: 0.7;
`

const StatusBadge = styled(CoreBadge)`
    margin-right: 0.5rem;
    white-space: normal !important;
`

const StatusExperienceBadge = styled(ExperienceInfoBadge)`
    margin-right: 0.5rem;
`

const StandardPanel = styled(BoxShadowStyle)`
    background-color: ${props => props.theme.background};
    margin-bottom: 1rem;
    position: relative;
`

const MergeIcon = styled(BookingIcon)`
    margin: 0rem 0.1rem;
`

export const LockScreen = styled(Center)`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast};
    text-align: center;
    z-index: 99999;
    font-size: 3rem;
`

