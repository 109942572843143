import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { BookingDayOverviewResponse } from '../../../../../../api/api-definitions';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import moment, { Moment } from 'moment';
import { DATEONLYFORMAT, createMomentFromValue } from '../../../../../../utils/date-helpers';
import { H3 } from '../../../../../../components/Typography/Headings';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import styled from 'styled-components';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { BoxShadowStyle } from '../../../../../../theme';
import SevenDayOverview from '../../../../../../components/Reporting/SevenDayOverview';
import CoreButton from '../../../../../../components/Forms/Button';
import { StyledCalendar } from '../../../../../../theme/input.styles';
import { DayBox } from '../../Bookings/BookingSetup';
import CoreIcon from '../../../../../../components/Media/Icon';

interface ChartItem {
  x: string;
  y: number;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Bookings',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const lineOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Booking breakdown',
    },
  },
  responsive: true,
};

export const byCoverOptions = {
  plugins: {
    title: {
      display: true,
      text: 'Covers',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const ChartContainer = styled(BoxShadowStyle)`
    background-color: #FFF;
`
const getChartDataByCovers = (response: BookingDayOverviewResponse, days: {[key: string]: boolean}) => {
  const filteredDays: string[] = [];

  Object.keys(response.days).forEach(day => {
    if (days[createMomentFromValue(day).format("dddd").toLowerCase()]) filteredDays.push(day)
  });

  return {
    labels: Object.keys(response.days).map((day) => createMomentFromValue(day).format("dd DD MMM")),
    datasets: [
      {
        label: 'Attending',
        data: Object.keys(response.days).map((day) => response.days[day].guests),
        backgroundColor: 'rgba(100, 100, 235, 0.5)',
      },
      {
        label: 'Cancelled',
        data: Object.keys(response.days).map((day) => response.days[day].cancelledGuests),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'No showed',
        data: Object.keys(response.days).map((day) => response.days[day].noShowedGuests),
        backgroundColor: 'rgba(235, 18, 18, 0.9)',
      }
    ],
  }
}

const getChartData = (response: BookingDayOverviewResponse, days: {[key: string]: boolean}) => {
  const filteredDays: string[] = [];

  Object.keys(response.days).forEach(day => {
    if (days[createMomentFromValue(day).format("dddd").toLowerCase()]) filteredDays.push(day)
  });

  return {
    labels: filteredDays.map((day) => createMomentFromValue(day).format("dd DD MMM")),
    datasets: [
      {
        label: 'In-house',
        data: filteredDays.map((day) => response.days[day].inHouse - response.days[day].walkIns),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Walk ins',
        data: filteredDays.map((day) => response.days[day].walkIns),
        backgroundColor: 'rgba(100, 100, 235, 0.5)',
        borderColor: 'rgba(100, 100, 235, 0.5)',
      },
      {
        label: 'Online',
        data: filteredDays.map((day) => response.days[day].web),
        backgroundColor: 'rgba(23, 30, 132, 0.5)',
        borderColor: 'rgba(23, 30, 132, 0.5)',
      },
      {
        label: 'Cancellations',
        data: filteredDays.map((day) => response.days[day].cancelled),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'No shows',
        data: filteredDays.map((day) => response.days[day].noShowed),
        backgroundColor: 'rgba(235, 18, 18, 0.9)',
        borderColor: 'rgba(235, 18, 18, 0.9)',
      },
    ],
  }
}

const BookingDayReport = () => {
  const [businessLoaded, business] = useBusiness();
  const [response, setResponse] = useState<BookingDayOverviewResponse>();
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(14, 'days'));
  const [endDate, setEndDate] = useState<Moment>(moment());
  const [daysOfWeek, setDaysOfWeek] = useState<{[key: string]: boolean}>({
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true
  }); 
  const requested = useRef(false);

  useEffect(() => {
    if (business && !requested.current) {
      requested.current = true;
      getData(startDate, endDate)
    }
  }, [business]);

  const getData = (startDate: Moment, endDate: Moment) => {
    ApiService.bookingStatistics.OverviewBetweenDates__GET(
      business.id,
      startDate.format(DATEONLYFORMAT),
      endDate.format(DATEONLYFORMAT)
    ).then(setResponse);
  }

  const onNextClick = () => {
    const diff = endDate.diff(startDate, 'days')
    const newEndDate = endDate.clone().add(diff, 'days');
    setEndDate(newEndDate)
    const newStartDate = startDate.clone().add(diff, 'days');
    setStartDate(newStartDate)
    getData(newStartDate, newEndDate)
  }

  const onPreviousClick = () => {
    const diff = endDate.diff(startDate, 'days')
    const newEndDate = endDate.clone().subtract(diff, 'days');
    setEndDate(newEndDate)
    const newStartDate = startDate.clone().subtract(diff, 'days');
    setStartDate(newStartDate)
    getData(newStartDate, newEndDate)
  }

  const startDateChange = (date: Moment) => {
    if (date.isSame(startDate)) return;
    setStartDate(date)
    getData(date, endDate)
  }

  const endDateChange = (date: Moment) => {
    if (date.isSame(endDate)) return;
    setEndDate(date)
    getData(startDate, date)
  }

  return (
    <div>
      <br />
      <H3>Next 7 days</H3>
      <SevenDayOverview />
      {response &&
        <Row>
          <Column size={2}>
            <CoreButton full onClick={onPreviousClick}>Previous</CoreButton>
          </Column>
          <Column size={4}>
            <StyledCalendar value={startDate} onChange={e => startDateChange(createMomentFromValue(e))} />
          </Column>
          <Column size={4}>
            <StyledCalendar value={endDate} onChange={e => endDateChange(createMomentFromValue(e))} />
          </Column>
          <Column size={2}>
            <CoreButton full onClick={onNextClick}>Next</CoreButton>
          </Column>
          <Column size={8} tablet={12}>
            <label> Days of week</label>
            <br />
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, monday: !daysOfWeek.monday })} checked={daysOfWeek.monday}><CoreIcon name={daysOfWeek.monday ? 'check' : 'times'} /> Mon</DayBox>
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, tuesday: !daysOfWeek.tuesday })} checked={daysOfWeek.tuesday}><CoreIcon name={daysOfWeek.tuesday ? 'check' : 'times'} /> Tue</DayBox>
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, wednesday: !daysOfWeek.wednesday })} checked={daysOfWeek.wednesday}><CoreIcon name={daysOfWeek.wednesday ? 'check' : 'times'} /> Wed</DayBox>
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, thursday: !daysOfWeek.thursday })} checked={daysOfWeek.thursday}><CoreIcon name={daysOfWeek.thursday ? 'check' : 'times'} /> Thu</DayBox>
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, friday: !daysOfWeek.friday })} checked={daysOfWeek.friday}><CoreIcon name={daysOfWeek.friday ? 'check' : 'times'} /> Fri</DayBox>
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, saturday: !daysOfWeek.saturday })} checked={daysOfWeek.saturday}><CoreIcon name={daysOfWeek.saturday ? 'check' : 'times'} /> Sat</DayBox>
            <DayBox onClick={() => setDaysOfWeek({ ...daysOfWeek, sunday: !daysOfWeek.sunday })} checked={daysOfWeek.sunday}><CoreIcon name={daysOfWeek.sunday ? 'check' : 'times'} /> Sun</DayBox>
          </Column>
          <Column size={12} mobile={12}>
            <ChartContainer>
              <Bar options={options} data={getChartData(response, daysOfWeek)} />
            </ChartContainer>
          </Column>
          <Column size={12} mobile={12}>
            <ChartContainer>
              <Bar options={byCoverOptions} data={getChartDataByCovers(response, daysOfWeek)} />
            </ChartContainer>
          </Column>
          <Column size={12} mobile={12}>
            <ChartContainer>
              <Line options={lineOptions} data={getChartData(response, daysOfWeek)} />
            </ChartContainer>
          </Column>
        </Row>
      }
    </div>
  );
};

export default BookingDayReport;