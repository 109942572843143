import React, { useState } from 'react';
import styled from 'styled-components';
import { ApiBusinessBookingDetails, BaseDish } from '../../../../api/api-definitions';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { BasketState, FoodItemState } from '..';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import { cloneDeep } from 'lodash';
import { StyledNumberInput } from '../../../../theme/input.styles';
import BREAKPOINTS from '../../../../config/breakpoints';
import AddToBasketModal from './addToBasketModal';

interface ComponentProps {
  dish: BaseDish;
  business: ApiBusinessBookingDetails;
}

const BasketItem = ({ dish, business }: ComponentProps) => {
  const setBasketState = useSetRecoilState(BasketState);
  const [value, setValue] = useRecoilState(FoodItemState([dish.id, { quantity: 0 }]));
  const [edit, setEdit] = useState(false);

  const setQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = +e.target.value;
    if (amount < 0) return;
    setBasketState(basket => {
      const current = cloneDeep(basket);
      if (current[dish.id]) {
        current[dish.id].quantity = amount;
      } else {
        current[dish.id] = {
          quantity: amount
        }
      }
      if (current[dish.id].quantity == 0) {
        delete current[dish.id];
      }
      return current;
    })
    setValue({ quantity: amount })
  }

  const onEditConfirm = () => {

  }

  console.log(value)

  return (
    <>
      {edit &&
        <AddToBasketModal
          selectedItem={dish}
          business={business}
          onClose={() => setEdit(false)}
          currentValues={value}
        />
      }
      <BasketItemContainer onClick={() => setEdit(true)}>
        <BasketFlexRow>
          <BasketName>{dish.name}</BasketName>
          <BasketQty onClick={(e) => e.stopPropagation()}><StyledNumberInput value={value.quantity} hasPlusMinus min={0} onChange={setQuantity} /></BasketQty>
          <BasketPrice>{ConvertCurrency({ code: 'GBP', amount: dish.price * value.quantity })}</BasketPrice>
        </BasketFlexRow>
        <BasketExtras>
          <BasketExtraItem>
            - Extra pickle
          </BasketExtraItem>
          <BasketExtraItem>
            - Extra chilli
          </BasketExtraItem>
        </BasketExtras>
      </BasketItemContainer>
    </>
  );
};

const BasketExtras = styled.div`
  font-size: 0.9rem;
  color: rgb(74 74 74);
  font-style: italic;
  padding-left: 1rem;
  margin-top: 0.5rem;
`

const BasketExtraItem = styled.div`
  line-height: 1rem;
`

const BasketItemContainer = styled.div`
  border-bottom: 1px solid ${props => props.theme.borderColor};
  padding: 0.5rem;
  line-height: 2rem;
  cursor: pointer;

  &:last-child {
    border: none;
  }

  &:hover {
    background-color: ${props => props.theme.dashboardBackground};
  }
`

const BasketFlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;

`

const BasketQty = styled.div`
  width: 7rem;

  div, input {
    max-height: 2rem;
  }

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    order: 2;
    width: 50%;
  }
`

const BasketPrice = styled.div`
  width: 5rem;
  text-align: right;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    order: 3;
    width: 50%;
  }
`

const BasketName = styled.div`
  padding-right: 0.5rem;
  flex: 1;
  font-size: 0.9rem;
  font-weight: bold;

  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    order: 1;
    width: 100%;
    flex: none;
    padding: 0;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
  }
`

export default BasketItem;