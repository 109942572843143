import { useParams } from 'react-router';
import Icon from '../../../components/Media/Icon';
import { useEffect, useRef, useState } from 'react';
import Loader from '../../../components/Layout/Loader';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, businessNowTime, createMomentFromValue, formatDate, secondsToTime } from '../../../utils/date-helpers';
import moment, { Moment } from 'moment';
import { ApiService } from '../../../api/api-connectors';
import { ApiBookingTimesWidget, ApiBookingTimesRequest, ApiBusinessBookingDetails, ApiMakeBookingRequest, ApiPaymentRequest, BookingError, BookingTimeSlots, HeldBookingResponse, PublicAmendBookingRequest, SpecialOccasion, WebEventsAvailabilityResponse, ApiMakeBookingResponse, ExperienceInfo, TimeSlotWidget, DateAndTimeSlotsWidget, IntentError, BookingErrorType, BookingTypeSearchType } from '../../../api/api-definitions';
import { BookingSpecialOccasions, Constants } from '../../../constants';
import { getQueryParams, pushEvent } from '../../../utils/data-helpers';
import { DropdownItem } from '../../../components/Forms/Dropdown';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { NotificationService } from '../../../services/NotificationService';
import { BookingModuleContainer, InfoMessage, Title, ButtonBack, TransitionWrapper, YourDetails, InfoForm, Transition, BookingTabs, BookingTab, GlobalWidgetStyle, BackgroundImage, MainContentContainer, BookingStandaloneContainer, AltTitle, LogoImage, TitleText } from '../../booking.styles';
import ExperienceTab from './modules/ExperienceTab';
import ExperienceSelection from './modules/ExperienceSelection';
import BookingSummary from './modules/BookingSummary';
import PoweredBy from './modules/PoweredBy';
import BookingConfirmation from './modules/BookingConfirmation';
import PaymentForm from './modules/PaymentForm';
import CustomerDetails from './modules/CustomerDetails';
import SearchForm from './modules/SearchForm';
import { getMaximumPartyForEvent } from './utils';
import styled from 'styled-components';
import CoreBadge from '../../../components/CoreBadge';
import BREAKPOINTS from '../../../config/breakpoints';
import { LoggingService } from '../../../services/LoggingService';

export const mobileSize = 6;
export const tabletSize = 4;
export const desktopSize = 4;
const TIMEFORMAT = 'h:mm A';
const ERROR = 'Something went wrong, please try again later.';
const ERRORMESSAGE = 'Sorry, we were unable to complete your booking, please try again.';
export const AMENDERRORMESSAGE = 'Sorry, we were unable to amend your booking, please try again.';
const HOLDERRORMESSAGE = 'Sorry, we were unable to hold the selected time slot for your booking.';


export interface BookingFormData {
    guests: number;
    date: Moment;
    time: string;
    experienceId?: number;
}

export function getClosestFifteenMinutes(): string {
    const start = businessNowTime();
    const remainder = 15 - (start.minute() % 15);
    const time = moment(start).add(remainder, "minutes");
    if (time.isBefore(businessNowTime())) {
        time.add(15, 'minutes')
    }
    return time.format("HH:mm");
}

export function generatePeopleList(limit: number): DropdownItem[] {
    const list: DropdownItem[] = [];
    for (let i = 1; i <= limit; i++) {
        list.push({
            value: i.toString(),
            text: i === 1 ? '1 person' : `${i} people`,
        })
    }
    list.push({
        value: 'MAX',
        text: `${(limit + 1)}+ people`
    })
    return list;
}

interface Props {
    previewLocation?: string;
    previewBusinessData?: ApiBusinessBookingDetails;
    defaultDateTime?: Moment;
    bookingHash?: string;
    bookingReference?: string;
    previousSpecialRequests?: string;
    previousDietaryRequirements?: string;
    previousOccasion?: SpecialOccasion;
    previousGuests?: number;
    previousExperience?: string;
    onUpdateBooking?: () => void;
    standalone?: boolean;
}

const BookingModule = ({
    previewLocation,
    previewBusinessData,
    defaultDateTime,
    bookingHash,
    bookingReference,
    previousGuests,
    previousSpecialRequests,
    previousDietaryRequirements,
    previousOccasion,
    previousExperience,
    onUpdateBooking,
    standalone
}: Props) => {
    const routeParams: any = useParams();
    const params = getQueryParams();
    const [loading, setLoading] = useState(true)
    const [nextAvailLoading, setNextAvailLoading] = useState(false)
    const [nextAvailLoadingMore, setNextAvailLoadingMore] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [booked, setBooked] = useState(false)
    const [appError, setAppError] = useState(false)
    const [noBusiness, setNoBusiness] = useState(false)
    const [details, setDetails] = useState<ApiMakeBookingRequest>({
        specialRequests: previousSpecialRequests,
        dietaryRequirements: previousDietaryRequirements,
        specialOccasion: previousOccasion,
        telephoneCountryCode: 'GB'
    })
    const [selectedTimeDropdown, setSelectedTimeDropdown] = useState<string>(defaultDateTime ? '0001-01-01T' + defaultDateTime.format('HH:mm') + ':00' : undefined);
    const [selectedTime, setSelectedTime] = useState<string>();
    const [selectedOccasion, setSelectedOccasion] = useState<string>();
    const [selectedTimeForExperienceSelection, setSelectedTimeForExperienceSelection] = useState<string>();
    const [bookingError, setBookingError] = useState<string>();
    const [bookingRef, setBookingRef] = useState<string>();
    const [bookingResponse, setBookingResponse] = useState<ApiMakeBookingResponse>();
    const [selectedTab, selectTab] = useState<number>(params.event || previewBusinessData?.experiences || previewBusinessData?.specialEvents ? 1 : 0);
    const [selectedExperience, setSelectedExperience] = useState<string>();
    const [bookingErrors, setBookingErrors] = useState<BookingError[]>([])
    const [business, setBusiness] = useState<ApiBusinessBookingDetails>();
    const businessRef = useRef<ApiBusinessBookingDetails>();
    const [holdDetails, setHoldDetails] = useState<HeldBookingResponse>();
    const [eventsAvailabilityResponse, setEventsAvailabilityResponse] = useState<WebEventsAvailabilityResponse>()
    const [searchableExperience, setSearchableExperience] = useState<string>();
    const [searchableEvent, setSearchableEvent] = useState<string>();
    const specialEventDates = useRef<Date[]>([])
    const [eventGuests, setEventGuests] = useState<number>(2);
    const [timer, setTimer] = useState<Moment>();
    const [times, setTimes] = useState<DateAndTimeSlotsWidget>();
    const timeRef = useRef<DateAndTimeSlotsWidget>();
    const [nextAvail, setNextAvail] = useState<ApiBookingTimesWidget>()
    const [nextAvailEndOfResults, setNextAvailEndOfResults] = useState<boolean>()
    const [formData, setFormData] = useState<BookingFormData>({
        guests: 2,
        date: defaultDateTime || businessNowTime(),
        time: defaultDateTime ? defaultDateTime.format(TIMEFORMAT) : getClosestFifteenMinutes()
    })
    const lastDateSearch = useRef<string>();
    const [paymentIntent, setPaymentIntent] = useState<string>();
    const selectedTimeRef = useRef<string>();
    const [hasLoadedFirstData, setHasLoadedFirstData] = useState<boolean>(false);
    const maxPeople = useRef<number>();
    const availableTimes = useRef<BookingTimeSlots>();
    const [showExperienceSelection, setShowExperienceSelection] = useState<string[]>();
    const partyTooBig = formData.guests?.toString() == 'MAX' || (maxPeople.current ? +formData.guests > maxPeople.current : false);
    const eventGuidLowerCase = params.event ? params.event.toLowerCase() : '';

    useEffect(() => {
        if (previewLocation) {
            availableTimes.current = {
                timeSlots: {
                    Monday: true,
                    Tuesday: true,
                    Wednesday: true,
                    Thursday: true,
                    Friday: true,
                    Saturday: true,
                    Sunday: true,
                }
            }
            maxPeople.current = 2;
            setBusiness(previewBusinessData)
            setDetails({ ...details, telephoneCountryCode: previewBusinessData.countryCode })
            businessRef.current = previewBusinessData;
            setLoading(false)
            setLoaded(true)
            setTimes({
                date: formatDate(businessNowTime(), DATABASE_TIME_FORMAT),
                shiftLabelAndTimeSlots: {
                    'Lunch': {
                        slots: [
                            {
                                slot: '0001-01-01T14:00:00'
                            },
                            {
                                slot: '0001-01-01T14:15:00'
                            },
                            {
                                slot: '0001-01-01T14:30:00'
                            },
                            {
                                slot: '0001-01-01T14:45:00'
                            },
                            {
                                slot: '0001-01-01T15:00:00'
                            }
                        ]
                    },
                    'Dinner': {
                        slots: [
                            {
                                slot: '0001-01-01T18:00:00'
                            },
                            {
                                slot: '0001-01-01T18:15:00'
                            },
                            {
                                slot: '0001-01-01T18:30:00'
                            },
                            {
                                slot: '0001-01-01T18:45:00'
                            },
                            {
                                slot: '0001-01-01T19:00:00'
                            }
                        ]
                    }
                }
            })
            setHasLoadedFirstData(true)
        } else {
            if (isNullOrWhitespace(routeParams.location)) {
                pushEvent('bookingAppLoadError', { 'locationId': 'Not specified' })
                setAppError(true)
                return;
            }
            ApiService.weblocation.Getlocation__GET(routeParams.location, !!routeParams.ref).then(data => {
                if (isNullOrWhitespace(data?.name)) {
                    setNoBusiness(true)
                } else {
                    try {
                        if (data.specialEventDates) {
                            specialEventDates.current = Object.keys(data.specialEventDates).map(x => createMomentFromValue(x).toDate())
                        }
                        moment.tz.setDefault(data.timeZone);
                        if (data.timeSlotsPerDay?.timeSlots) {
                            availableTimes.current = data.timeSlotsPerDay;
                            maxPeople.current = data.maximumBookableParty;
                            const todaysSlots = data.timeSlotsPerDay.timeSlots[createMomentFromValue(formData.date).format('dddd')];
                            if (!defaultDateTime && !todaysSlots) {
                                setFormData({
                                    ...formData,
                                    date: createMomentFromValue(data.firstAvailableDate) || null
                                })
                            } else if (!defaultDateTime && !!data.firstAvailableDate) {
                                setFormData({
                                    ...formData,
                                    date: createMomentFromValue(data.firstAvailableDate) || formData.date
                                })
                            }
                            setBusiness(data)
                            setDetails({ ...details, telephoneCountryCode: data.countryCode })
                            businessRef.current = data;
                            document.body.style.backgroundColor = data.theme.backgroundColour;
                            document.body.style.color = data.theme.textColour;
                            var css = `#pageContent { --ion-background-color: ${data.theme.backgroundColour} !important; }`,
                                head = document.head || document.getElementsByTagName('head')[0],
                                style = document.createElement('style');

                            head.appendChild(style);
                            // @ts-ignore
                            style.type = 'text/css';
                            // @ts-ignore
                            if (style.styleSheet) {
                                // This is required for IE8 and below.
                                // @ts-ignore
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(document.createTextNode(css));
                            }
                            if (isNullOrWhitespace(params.setup_intent) && isNullOrWhitespace(params.payment_intent)) {
                                if (data.specialEvents) {
                                    if (eventGuidLowerCase) {
                                        let peopleToSearchFor = 2;
                                        if (data.specialEvents[eventGuidLowerCase]?.minimumPeople && data.specialEvents[eventGuidLowerCase].minimumPeople > 2) {
                                            peopleToSearchFor = data.specialEvents[eventGuidLowerCase].minimumPeople;
                                        }
                                        if (data.specialEvents[eventGuidLowerCase]?.maximumPeople && data.specialEvents[eventGuidLowerCase].maximumPeople < 2) {
                                            peopleToSearchFor = 1;
                                        }
                                        nextAvailSearch(eventGuidLowerCase, peopleToSearchFor)
                                    }
                                }
                                if (defaultDateTime) {
                                    dateChange(defaultDateTime, true, previousGuests);
                                } else {
                                    dateChange(createMomentFromValue(data.firstAvailableDate) || formData.date, true);
                                }
                                if (params.nextAvail == 'true') {
                                    nextAvailSearch(null, +params.guests, true)
                                }
                                setLoading(false)
                            } else {
                                const { previousFormData, previousDetails } = JSON.parse(sessionStorage.getItem('cd'));
                                setDetails(previousDetails);
                                setFormData(previousFormData);
                                const request: ApiMakeBookingRequest = {
                                    ...previousDetails,
                                    chargedCard: params.takeNow == 'true',
                                    cardIntent: params.setup_intent || params.payment_intent,
                                    selectedSlotInformation: {
                                        locationId: routeParams.location,
                                        holdReference: params.holdRef,
                                    },
                                }

                                ApiService.makebooking.Book__POST(request).then(bookingResponse => {
                                    window.history.replaceState(null, '', window.location.pathname)
                                    if (bookingResponse.success) {
                                        setBookingRef(bookingResponse.bookingReference);
                                        if (!selectedExperience) {
                                            setSelectedExperience(bookingResponse.experienceGuid)
                                        }
                                        setBookingError(undefined);
                                        setBookingResponse(bookingResponse);
                                        setBooked(true);
                                        setSelectedTime(bookingResponse.bookingDateAndTime);
                                        setSelectedOccasion(request.specialOccasion);
                                        setHasLoadedFirstData(true);
                                        setFormData({
                                            guests: bookingResponse.guests,
                                            date: createMomentFromValue(bookingResponse.bookingDateAndTime),
                                            time: ''
                                        })
                                        setLoading(false)
                                    } else {
                                        setLoading(false)
                                        setBookingError(ERRORMESSAGE)
                                    }

                                    if (bookingResponse.errors) {
                                        setBookingErrors(bookingResponse.errors)
                                    } else {
                                        setBookingErrors([])
                                    }
                                }).catch((e) => {
                                    setLoading(false)
                                    setBookingError(ERRORMESSAGE)
                                    LoggingService.Log(e, [
                                        {key: 'request', data: request  },
                                        {key: 'location', data: routeParams.location  },
                                    ])
                                })
                            }
                        } else {
                            pushEvent('bookingAppLoadError', { 'locationId': routeParams.location })
                            setAppError(true)
                        }
                    } catch (e) {
                        console.error(e)
                    }
                }
            }).catch((e) => {
                LoggingService.Log(e, [
                    {key: 'location', data: routeParams.location  }
                ])
                NotificationService.Error('Sorry, there has been an error loading the application.')
            })
        }
    }, [])

    useEffect(() => {
        if (hasLoadedFirstData) dateChange(formData.date)
    }, [formData.guests])

    const getApiBookingTimeRequest = (date: string | Moment | Date, guests?: number, experienceGuid?: string): ApiBookingTimesRequest => {
        return {
            requestedTime: formatDate(createMomentFromValue(date).startOf('day'), DATABASE_TIME_FORMAT),
            guests: guests || formData.guests,
            locationId: routeParams.location,
            bookingReference: isNullOrWhitespace(bookingReference) ? null : bookingReference,
            experienceGuid: experienceGuid == 'standard' ? null : experienceGuid,
            bookingTypeSearchType: !!experienceGuid ? BookingTypeSearchType.Specific : BookingTypeSearchType.All
        }
    }

    const buildCustomFields = (details: ApiMakeBookingRequest, business: ApiBusinessBookingDetails) => {
        let customFieldList: string[] = [];

        if (business) {
            const customFields: { [key: string]: any; } = details;
            Object.keys(customFields).forEach(key => {
                if (key.indexOf('Custom-') > -1) {
                    const field = business.customFields[+key.split('-')[1]];
                    if (field) {
                        customFieldList.push(`${field.id}~~~${customFields[key]}`);
                    }
                }
            });
        }
        return customFieldList;
    }

    const nextAvailSearch = (experienceGuid: string = null, guestsOverride: number = null, isEventSearch: boolean = false, nextPage: boolean = false) => {
        const actualExperienceId = experienceGuid == 'standard' ? null : experienceGuid;
        if (isNullOrWhitespace(previewLocation)) {
            if (partyTooBig) {
                setTimes({
                    date: '',
                    shiftLabelAndTimeSlots: {}
                })
            } else {
                let preventSearch = false;
                if (actualExperienceId && (guestsOverride || eventGuests)) {
                    preventSearch = ((guestsOverride || eventGuests) > getMaximumPartyForEvent(actualExperienceId, businessRef.current) || (guestsOverride || eventGuests) < (businessRef.current?.specialEvents[actualExperienceId]?.minimumPeople || 1));
                }
                if (guestsOverride) setEventGuests(guestsOverride);
                if (actualExperienceId && selectedTab == 1) setSearchableEvent(actualExperienceId);
                setBookingRef(undefined)
                setBookingError(undefined)
                releaseHold()
                if (preventSearch) {
                    setNextAvail({ availability: [] })
                    setLoaded(true)
                } else {
                    if (!nextPage) setNextAvailLoading(true);
                    if (nextPage) setNextAvailLoadingMore(true);
                    let dateToSearch = isEventSearch ? moment() : formData.date;
                    if (nextPage) {
                        dateToSearch = moment(nextAvail.availability[nextAvail.availability.length - 1].date).add(1, 'day')
                    }
                    const request = getApiBookingTimeRequest(dateToSearch, guestsOverride, experienceGuid);
                    ApiService.makebooking.GetNextAvailable__POST(request).then((data) => {
                        if (nextPage) {
                            setNextAvailEndOfResults(data.availability.length < 5)
                            setNextAvail({ ...nextAvail, availability: [...nextAvail.availability, ...data.availability] });
                        } else {
                            setNextAvailEndOfResults(false)
                            setNextAvail(data);
                        }
                        if (!nextPage) setLoaded(true);
                        if (!nextPage) setNextAvailLoading(false);
                        if (nextPage) setNextAvailLoadingMore(false);
                    }).catch((e) => {
                        LoggingService.Log(e, [
                            {key: 'request', data: request  },
                            {key: 'location', data: routeParams.location  },
                        ])
                        if (!nextPage) setLoaded(false);
                        if (!nextPage) setNextAvailLoading(false);
                        setBookingError(ERROR)
                    })
                }
            }
        }
    }

    const releaseHold = () => {
        const holdRef = sessionStorage.getItem('fhr');
        if (!isNullOrWhitespace(holdRef)) {
            ApiService.makebooking.ReleaseHold__POST(holdRef).finally(() => {
                sessionStorage.removeItem('fhr')
                setHoldDetails(undefined)
            }).catch((e) => {
                LoggingService.Log(e, [
                    {key: 'request', data: holdRef  },
                    {key: 'location', data: routeParams.location  },
                ])
            })
        }
    }

    const amendBooking = () => {
        setLoading(true);

        if (!details.specialOccasion) {
            details.specialOccasion = SpecialOccasion.NotSet;
        }

        const request: PublicAmendBookingRequest = {
            newSpecialRequests: details.specialRequests,
            newDietaryRequirements: details.dietaryRequirements,
            newSpecialOccasion: details.specialOccasion,
            locationId: routeParams.location,
            holdReference: holdDetails.holdReference,
            bookingReference: bookingReference,
            hash: bookingHash
        }
        ApiService.makebooking.AmendBooking__POST(request).then(bookingResponse => {
            if (bookingResponse.success) {
                setLoading(false);
                onUpdateBooking();
            } else {
                setLoading(false)
                if (isNullOrWhitespace(bookingResponse.info)) {
                    setBookingError(AMENDERRORMESSAGE)
                } else {
                    setBookingError(bookingResponse.info)
                }
            }
        }).catch((e) => {
            setLoading(false)
            setBookingError(AMENDERRORMESSAGE)
            LoggingService.Log(e, [
                {key: 'request', data: request  },
                {key: 'location', data: routeParams.location  },
            ])
        })
    }

    const confirmHold = (date: Moment = null, time: string = null, guests?: number) => {
        setBookingError(undefined);
        setBookingErrors(undefined);
        if (isNullOrWhitespace(previewLocation)) {
            setLoading(true)
            setBookingError(undefined)
            setBookingRef(undefined)
            releaseHold()
            const experienceToUse = selectedExperience ? selectedExperience : searchableEvent;
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(date || formData.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + (time || selectedTimeDropdown).split('T')[1],
                guests: selectedTab == 1 ? eventGuests : guests || formData.guests,
                locationId: routeParams.location,
                bookingReference: bookingReference,
                experienceGuid: experienceToUse
            }
            if (searchableEvent) setSelectedExperience(searchableEvent)
            ApiService.makebooking.Hold__POST(request).then((holdResponse) => {
                if (holdResponse.success) {
                    setSelectedTime((time || selectedTimeDropdown))
                    selectedTimeRef.current = (time || selectedTimeDropdown);
                    sessionStorage.setItem('fhr', holdResponse.holdReference);
                    setTimer(moment().add(5, 'minutes'))
                    setHoldDetails(holdResponse)
                } else {
                    if (isNullOrWhitespace(holdResponse.error?.message)) {
                        setBookingError('Sorry, we were not able to hold the requested time. Please try again.')
                    } else {
                        setBookingError(holdResponse.error.message)
                    }
                    dateChange(date || formData.date)
                }
                setLoading(false)
                setLoaded(true)
            }).catch((e) => {
                setLoading(false)
                setLoaded(true)
                setBookingError(HOLDERRORMESSAGE)
                LoggingService.Log(e, [
                    {key: 'request', data: request  },
                    {key: 'location', data: routeParams.location  },
                ])
            })
        }
    }

    const hold = (date: Moment = null, time: string = null, slot?: TimeSlotWidget, guests?: number) => {
        setBookingError(undefined);
        let fullSelectedTime: TimeSlotWidget = null;
        const timesToUse = params.hold == 'true' ? timeRef.current : times;
        if (!slot) {
            Object.keys(timesToUse.shiftLabelAndTimeSlots).forEach(x => {
                const searchTime = timesToUse.shiftLabelAndTimeSlots[x].slots.find(x => x.slot === (time || selectedTimeDropdown))
                if (searchTime) fullSelectedTime = searchTime;
            })
        }
        if ((business?.specialEvents || business?.experiences) && !searchableEvent) {
            setLoading(true)
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(date || formData.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + (time || selectedTimeDropdown).split('T')[1],
                guests: selectedTab == 1 ? eventGuests : guests || formData.guests,
                locationId: routeParams.location,
                bookingReference: bookingReference,
            }
            ApiService.makebooking.GetEventAvailability__POST(request).then((response) => {
                if (!response.availableIds || Object.keys(response.availableIds).length == 0) {
                    confirmHold(date, time, guests)
                } else {
                    if (response.availableIds[previousExperience]) {
                        setSelectedExperience(previousExperience)
                    } else if (slot?.eventGuids && slot.eventGuids.length == 1) {
                        setSelectedExperience(slot.eventGuids[0])
                    }
                    setEventsAvailabilityResponse(response)
                    setLoading(false)
                    setShowExperienceSelection(slot ? [] : fullSelectedTime.eventGuids)
                    if (searchableExperience && response.availableIds[searchableExperience]) {
                        setSelectedExperience(searchableExperience)
                    }
                    if (searchableEvent && response.availableIds[searchableEvent]) {
                        setSelectedExperience(searchableEvent)
                    }
                    setSelectedTimeForExperienceSelection((time || selectedTimeDropdown))
                }
            }).catch((e) => {
                setLoading(false)
                setBookingError(ERROR)
                LoggingService.Log(e, [
                    {key: 'request', data: request  },
                    {key: 'location', data: routeParams.location  },
                ])
            })
        } else {
            confirmHold(date, time)
        }
    }

    const takeClientDetails = () => {
        if (holdDetails?.depositRequired) {
            processPaymentIntent()
        } else {
            if (!!bookingHash) {
                amendBooking();
            } else {
                book()
            }
        }
    }

    const processPaymentIntent = () => {
        sessionStorage.setItem('cd', JSON.stringify({ previousFormData: formData, previousDetails: details }))
        setLoading(true);
        const experienceToUse = selectedExperience ? selectedExperience : searchableEvent;
        let customFieldList: string[] = buildCustomFields(details, business);
        const request: ApiPaymentRequest = {
            holdClientInfoRequest: {
                ...details,
                customFields: customFieldList.join('|||'),
                holdReference: sessionStorage.getItem('fhr'),
                bookingHash
            },
            apiBookingTimesRequest: {
                requestedTime: moment(formData.date).format('YYYY-MM-DD') + 'T' + selectedTime.split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location,
                experienceGuid: experienceToUse,
                bookingReference
            }
        }
        const apiCall = holdDetails?.takeDepositNow ?
            ApiService.makebooking.GetClientPaymentIntent__POST(request) :
            ApiService.makebooking.GetClientSetupIntent__POST(request);
        apiCall.then((holdResponse) => {
            if (holdResponse.success) {
                setPaymentIntent(holdResponse.info)
            } else {
                setLoading(false)
                if (holdResponse.errorType != IntentError.Unknown && !!holdResponse.info) {
                    setBookingErrors([{
                        type: BookingErrorType.InvalidPhoneNumber,
                        message: holdResponse.info
                    }])
                } else {
                    setBookingError(ERROR)
                }
            }
        }).catch((e) => {
            setLoading(false)
            setBookingError(ERROR)
            LoggingService.Log(e, [
                {key: 'request', data: request  },
                {key: 'location', data: routeParams.location  },
            ])
        })
    }

    const book = () => {
        setLoading(true);
        let customFieldList: string[] = buildCustomFields(details, business);
        const request: ApiMakeBookingRequest = {
            ...details,
            customFields: customFieldList.join('|||'),
            selectedSlotInformation: {
                requestedTime: moment(formData.date).format('YYYY-MM-DD') + 'T' + selectedTime.split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location,
                holdReference: sessionStorage.getItem('fhr'),
            },
        }

        ApiService.makebooking.Book__POST(request).then(bookingResponse => {
            if (bookingResponse.success) {
                setBookingRef(bookingResponse.bookingReference);
                setBookingResponse(bookingResponse);
                setBookingError(undefined);
                setBooked(true);
                setSelectedOccasion(request.specialOccasion)
            } else {
                setLoading(false)
                setBookingError(ERRORMESSAGE)
            }

            if (bookingResponse.errors) {
                setBookingErrors(bookingResponse.errors)
            } else {
                setBookingErrors([])
            }
        }).catch((e) => {
            setLoading(false)
            setBookingError(ERRORMESSAGE)
            LoggingService.Log(e, [
                {key: 'request', data: request  },
                {key: 'location', data: routeParams.location  },
            ])
        })
    }

    const experienceTypeShouldShow = (): boolean => {
        return (!!business?.specialEvents && Object.keys(business?.specialEvents)?.length > 0) ||
            (!!business?.experiences && Object.keys(business?.experiences)?.length > 0);
    }

    const getSelectedExperienceName = (): string => {
        if (!!selectedExperience && business.experiences[selectedExperience]) {
            return business.experiences[selectedExperience].name
        } else if (!!selectedExperience && business.specialEvents[selectedExperience]) {
            return business.specialEvents[selectedExperience].name;
        } else {
            return isNullOrWhitespace(business?.standardBookingName) ? 'Standard booking' : business?.standardBookingName;
        }
    }

    const getSelectedExperienceLocation = (): string => {
        if (!!selectedExperience && business.specialEvents[selectedExperience]) {
            return business.specialEvents[selectedExperience].location;
        } else if (!!selectedExperience && business.experiences[selectedExperience]) {
            return business.experiences[selectedExperience].location
        } else {
            return null;
        }
    }

    const timeSlotDescription = (time: TimeSlotWidget): React.ReactNode => {
        const descriptive: boolean = true; // preserve previous functionality in case we revert back
        if (descriptive) {
            if (!time.eventGuids?.length && !time.experienceGuids?.length) return '';

            let description: React.ReactNode = ''
            const maxCharactersForName = time.standardUnavailable ? 24 : 20;
            const experienceTagName = time.experienceGuids?.length === 1 && (time.eventGuids?.length === 0 || time.standardUnavailable) && business.experiences[time.experienceGuids[0]].shortName.length <= maxCharactersForName ?
                `${business.experiences[time.experienceGuids[0]].shortName}${time.standardUnavailable && time.eventGuids?.length === 0 ? ' only' : ''}` :
                `Experience${time.experienceGuids?.length > 1 ? 's' : ''}${time.standardUnavailable && time.eventGuids?.length === 0 ? ' only' : ''}`;

            const eventTagName = time.eventGuids?.length === 1 && (time.experienceGuids?.length === 0 || time.standardUnavailable) && business.specialEvents[time.eventGuids[0]].shortName.length <= maxCharactersForName ?
                `${business.specialEvents[time.eventGuids[0]].shortName}` :
                `${!time.standardUnavailable && time.experienceGuids?.length > 0 ? `Event` : `Special event`}${time.eventGuids?.length > 1 ? 's' : ''}${time.standardUnavailable && time.experienceGuids?.length === 0 ? 'only' : ''}`

            if (!time.standardUnavailable && time.eventGuids?.length > 0 && time.experienceGuids?.length > 0) {
                description = <BadgeContainer><DropdownBadge colorPalette='messenger'>Standard</DropdownBadge> <DropdownBadge colorPalette='cyan'>{experienceTagName}</DropdownBadge> <DropdownBadge colorPalette='teal'>{eventTagName}</DropdownBadge></BadgeContainer>
            } else if (time.eventGuids?.length > 0 && time.experienceGuids?.length > 0) {
                description = <BadgeContainer><DropdownBadge colorPalette='cyan'>{experienceTagName}</DropdownBadge> <DropdownBadge colorPalette='teal'>{eventTagName}</DropdownBadge></BadgeContainer>
            } else if (!time.standardUnavailable && time.eventGuids?.length > 0 && !time.eventOnly) {
                description = <BadgeContainer><DropdownBadge colorPalette='messenger'>Standard</DropdownBadge> <DropdownBadge colorPalette='teal'>{eventTagName}</DropdownBadge></BadgeContainer>
            } else if (time.eventGuids?.length > 0 && time.eventOnly) {
                description = <BadgeContainer><DropdownBadge colorPalette='teal'>{eventTagName}</DropdownBadge></BadgeContainer>
            } else if (!time.standardUnavailable && time.experienceGuids?.length > 0) {
                description = <BadgeContainer><DropdownBadge colorPalette='messenger'>Standard</DropdownBadge> <DropdownBadge colorPalette='cyan'>{experienceTagName}</DropdownBadge></BadgeContainer>
            } else if (time.standardUnavailable && time.experienceGuids?.length > 0) {
                description = <BadgeContainer><DropdownBadge colorPalette='cyan'>{experienceTagName}</DropdownBadge></BadgeContainer>
            }

            return description
        } else {
            return (time.eventOnly ? ' (event only)' : '')
        }
    }

    const dateChange = (date: Date | Moment, firstLoad?: boolean, guests?: number, experienceGuid?: string, noExperience?: boolean) => {
        const formattedTime = '0001-01-01T' + formatDate(date, 'HH:mm') + ':00';
        if (!firstLoad && formatDate(date, DATEONLYFORMAT) == formatDate(formData.date, DATEONLYFORMAT) && guests == formData.guests && searchableExperience === experienceGuid) return;
        const formattedLastSearch = formatDate(date, DATEONLYFORMAT) + '-' + (guests || formData.guests) + '-' + (noExperience ? '0' : (experienceGuid || searchableExperience) ? (experienceGuid || searchableExperience) : '0');
        if (!previewLocation && (guests || formData.guests) && (guests || formData.guests)?.toString() !== 'MAX' && lastDateSearch.current != formattedLastSearch) {
            const request = getApiBookingTimeRequest(date, (guests || formData.guests), noExperience ? undefined : (experienceGuid || searchableExperience))
            lastDateSearch.current = formattedLastSearch;
            ApiService.makebooking.GetForDate__POST(request).then((response) => {
                setTimes(response);
                timeRef.current = response;
                setFormData({ ...formData, date: createMomentFromValue(date), guests: guests || formData.guests })
                if (!hasLoadedFirstData) setHasLoadedFirstData(true);
                if (!firstLoad && !bookingReference) {
                    setSelectedTimeDropdown('');
                } else if (guests) {
                    setSelectedTimeDropdown(formattedTime);
                }
                if (params.hold == 'true') {
                    hold(createMomentFromValue(params.searchDate), params.searchTime, null, +params.searchGuests)
                }
            }).catch((e) => {
                LoggingService.Log(e, [
                    {key: 'request', data: request  },
                    {key: 'location', data: routeParams.location  },
                ])
            })
        }
    }

    const onUpdateSearchableExperience = (experienceGuid?: string) => {
        if (nextAvail) {
            nextAvailSearch(experienceGuid)
        } else {
            dateChange(formData.date, false, formData.guests, experienceGuid, !experienceGuid);
        }
        setSearchableExperience(experienceGuid);
    }

    const updateSelectedExperience = (experienceGuid?: string) => {
        setBookingError(undefined);
        setSelectedExperience(experienceGuid)
    }

    const changeTab = (index: number) => {
        if (searchableEvent) setSearchableEvent(undefined)
        if (nextAvail) setNextAvail(undefined)
        selectTab(index)
    }


    if (appError) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <InfoMessage widgetTheme={business?.theme}>
                Sorry, we encountered an error and are unable to load the booking application. Please reload and try again.
            </InfoMessage>
        </BookingModuleContainer>
    )

    if (noBusiness) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <InfoMessage widgetTheme={business?.theme}>
                Sorry, we could not find the specified business.
            </InfoMessage>
        </BookingModuleContainer>
    )

    if (!hasLoadedFirstData) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <Loader />
        </BookingModuleContainer>
    )

    const specialOccasionOptions: DropdownItem[] = [];

    if (!isNullOrWhitespace(business?.specialOccasions)) {
        const occasionSplit = business?.specialOccasions.split('|');
        Object.keys(BookingSpecialOccasions()).forEach(occasion => {
            if (occasionSplit.indexOf(occasion) > -1) specialOccasionOptions.push({ value: occasion, text: <span><Icon name={BookingSpecialOccasions()[occasion].icon} regular />{BookingSpecialOccasions()[occasion].label}</span> });
        });
    }

    let hasTimeSlots = false;
    let nextAvailHasMultipleShifts = nextAvail?.availability?.find(date => Object.keys(date.shiftLabelAndTimeSlots).length > 1)
    let timeOptions: DropdownItem[] = [];
    const eventBreaksGuestRules = searchableEvent && (eventGuests > getMaximumPartyForEvent(searchableEvent, businessRef.current) || eventGuests < (business.specialEvents[searchableEvent]?.minimumPeople || 1));

    if (times) Object.keys(times.shiftLabelAndTimeSlots).forEach((label, index) => {
        if (times.shiftLabelAndTimeSlots[label].slots.length > 0) hasTimeSlots = true;
        times.shiftLabelAndTimeSlots[label].slots.forEach(time => {
            const value = moment(time.slot).format(TIMEFORMAT) + (time.eventGuids?.length > 0 ? '*' : '');
            timeOptions.push({
                value: time.slot,
                selectedDisplayText: value,
                text: <>{value} {timeSlotDescription(time)}</>,
                group: Object.keys(times.shiftLabelAndTimeSlots).length > 1 ? label : undefined
            })
        });
    });

    const ContainerElement = standalone ? BookingStandaloneContainer : MainContentContainer;

    const hasTabs = ((business?.specialEvents && Object.keys(business?.specialEvents).length > 0) || (business?.experiences && Object.keys(business?.experiences).length > 0));

    const showBannerImage = isNullOrWhitespace(bookingHash) && !isNullOrWhitespace(business?.bannerImageUrl) && standalone && !selectedTime;

    return (
        <>
            <GlobalWidgetStyle widgetTheme={business?.theme} />
            {standalone && <>
                <Title widgetTheme={business?.theme} hasImage={!isNullOrWhitespace(business.headerLogoUrl)}>
                    {isNullOrWhitespace(business.headerLogoUrl) ? business.name : <LogoImage src={Constants.baseImageUrl + business.headerLogoUrl} alt={business.name} />}
                </Title>
                {!isNullOrWhitespace(business.headerLogoUrl) && <TitleText widgetTheme={business?.theme}>{business.name}</TitleText>}

            </>}
            <ContainerElement>
                {showBannerImage &&
                    <BackgroundImage imageUrl={business?.bannerImageUrl} />
                }
                <BookingModuleContainer
                    widgetTheme={business?.theme}
                    standalone={standalone}
                    firstPage={showBannerImage}
                    noTabs
                    inlineDatePicker={business?.showTimesAsBlocks}
                >
                    {!business && <Loader />}
                    {business &&
                        <>
                            {!standalone &&
                                <>
                                    <AltTitle>{business.name}</AltTitle>
                                    {hasTabs && <hr />}
                                </>
                            }
                            {!booked && !loading && !!times && ((!!selectedTime && loaded) || showExperienceSelection) && !!!bookingRef &&
                                <ButtonBack
                                    style={{ marginTop: '0.5rem' }}
                                    widgetTheme={business?.theme}
                                    type='button'
                                    onClick={() => { setSelectedTime(undefined); setSelectedOccasion(undefined); setSelectedExperience(undefined); setShowExperienceSelection(undefined); selectedTimeRef.current = undefined; setBookingError(undefined); setBookingErrors([]); releaseHold(); }}>
                                    <Icon name='arrow-left' duo doNotStyleDuo /> Back</ButtonBack>
                            }
                            {!bookingError && !loading && loaded && times && selectedTime && !bookingRef && timer &&
                                <Timer time={timer} business={business} />
                            }
                            {!booked && isNullOrWhitespace(paymentIntent) &&
                                <TransitionWrapper standalone={standalone}>
                                    <Transition active={!standalone && !!selectedTime}>
                                        {(!standalone || (!loading && !selectedTime)) &&
                                            <YourDetails style={selectedTime && !showExperienceSelection ? { maxHeight: '2rem', overflowY: 'hidden' } : undefined}>
                                                {!showExperienceSelection &&
                                                    <InfoForm>
                                                        {hasTabs &&
                                                            <BookingTabs>
                                                                <BookingTab widgetTheme={business?.theme} selected={selectedTab == 0} onClick={() => changeTab(0)}><Icon name='calendar' duo doNotStyleDuo /> Book</BookingTab>
                                                                <BookingTab widgetTheme={business?.theme} selected={selectedTab == 1} onClick={() => changeTab(1)}><Icon name='sparkles' duo doNotStyleDuo /> Explore</BookingTab>
                                                            </BookingTabs>
                                                        }
                                                        {selectedTab == 1 &&
                                                            <ExperienceTab
                                                                nextAvail={nextAvail}
                                                                nextAvailLoading={nextAvailLoading}
                                                                nextAvailLoadingMore={nextAvailLoadingMore}
                                                                nextAvailEndOfResults={nextAvailEndOfResults}
                                                                business={business}
                                                                eventGuests={eventGuests}
                                                                previewLocation={previewLocation}
                                                                loading={loading}
                                                                searchableEvent={searchableEvent}
                                                                eventBreaksGuestRules={eventBreaksGuestRules}
                                                                nextAvailHasMultipleShifts={nextAvailHasMultipleShifts}
                                                                formData={formData}
                                                                generatePeopleList={generatePeopleList}
                                                                setEventGuests={setEventGuests}
                                                                nextAvailSearch={nextAvailSearch}
                                                                setFormData={setFormData}
                                                                setSelectedTimeDropdown={setSelectedTimeDropdown}
                                                                hold={hold}
                                                                setNextAvail={setNextAvail}
                                                                changeTab={changeTab}
                                                                showExploreTab={hasTabs}
                                                            />
                                                        }
                                                        <div style={selectedTab == 1 ? { opacity: 0, height: 0, overflow: 'hidden' } : undefined}>
                                                            <SearchForm
                                                                business={business}
                                                                loading={loading}
                                                                formData={formData}
                                                                nextAvail={nextAvail}
                                                                partyTooBig={partyTooBig}
                                                                times={times}
                                                                searchableExperience={searchableExperience}
                                                                nextAvailEndOfResults={nextAvailEndOfResults}
                                                                nextAvailLoading={nextAvailLoading}
                                                                nextAvailLoadingMore={nextAvailLoadingMore}
                                                                bookingReference={bookingReference}
                                                                specialEventDates={specialEventDates}
                                                                nextAvailHasMultipleShifts={nextAvailHasMultipleShifts}
                                                                hasTimeSlots={hasTimeSlots}
                                                                selectedTimeDropdown={selectedTimeDropdown}
                                                                bookingError={bookingError}
                                                                bookingRef={bookingRef}
                                                                timeOptions={timeOptions}
                                                                previewLocation={previewLocation}
                                                                availableTimes={availableTimes}
                                                                setFormData={setFormData}
                                                                generatePeopleList={generatePeopleList}
                                                                onUpdateSearchableExperience={onUpdateSearchableExperience}
                                                                setNextAvail={setNextAvail}
                                                                setSelectedTimeDropdown={setSelectedTimeDropdown}
                                                                hold={hold}
                                                                nextAvailSearch={nextAvailSearch}
                                                                dateChange={dateChange}
                                                            />
                                                        </div>
                                                    </InfoForm>
                                                }
                                                {showExperienceSelection &&
                                                    <ExperienceSelection
                                                        business={business}
                                                        formData={formData}
                                                        loading={loading}
                                                        selectedTimeForExperienceSelection={selectedTimeForExperienceSelection}
                                                        eventsAvailabilityResponse={eventsAvailabilityResponse}
                                                        selectedExperience={selectedExperience}
                                                        bookingError={bookingError}
                                                        updateSelectedExperience={updateSelectedExperience}
                                                        confirmHold={confirmHold}
                                                    />
                                                }
                                            </YourDetails>
                                        }
                                        {loading && (standalone || selectedTime) &&
                                            <YourDetails>
                                                <Loader />
                                            </YourDetails>
                                        }

                                        {!loading && loaded && times && (!standalone || selectedTime) &&
                                            <YourDetails>
                                                <BookingSummary
                                                    business={business}
                                                    formData={formData}
                                                    selectedTime={selectedTime}
                                                    selectedExperience={selectedExperience}
                                                    experienceTypeShouldShow={experienceTypeShouldShow}
                                                    getSelectedExperienceName={getSelectedExperienceName}
                                                    getSelectedExperienceLocation={getSelectedExperienceLocation}
                                                />
                                                <CustomerDetails
                                                    business={business}
                                                    bookingErrors={bookingErrors}
                                                    bookingHash={bookingHash}
                                                    selectedTime={selectedTime}
                                                    specialOccasionOptions={specialOccasionOptions}
                                                    details={details}
                                                    holdDetails={holdDetails}
                                                    bookingError={bookingError}
                                                    setDetails={setDetails}
                                                    takeClientDetails={takeClientDetails}
                                                    nameToDisplay={getSelectedExperienceName()}
                                                />
                                                <div style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
                                                    **By selecting “{isNullOrWhitespace(business.reserveButtonTextStepTwo) ? 'Complete booking' : business.reserveButtonTextStepTwo}” you are agreeing to the terms and conditions of the {Constants.businessName} User Agreement and Privacy Policy.
                                                </div>
                                            </YourDetails>
                                        }
                                    </Transition>
                                </TransitionWrapper>
                            }
                            {!isNullOrWhitespace(paymentIntent) &&
                                <PaymentForm
                                    business={business}
                                    holdDetails={holdDetails}
                                    paymentIntent={paymentIntent}
                                    nameToDisplay={getSelectedExperienceName()}
                                />
                            }
                            {(booked) &&
                                <BookingConfirmation
                                    business={business}
                                    bookingResponse={bookingResponse}
                                    formData={formData}
                                    selectedTime={selectedTime}
                                    selectedExperience={selectedExperience}
                                    selectedOccasion={selectedOccasion}
                                    experienceTypeShouldShow={experienceTypeShouldShow}
                                    getSelectedExperienceName={getSelectedExperienceName}
                                    getSelectedExperienceLocation={getSelectedExperienceLocation}
                                />
                            }
                            {!bookingHash &&
                                <PoweredBy business={business} />
                            }
                        </>
                    }
                </BookingModuleContainer>
            </ContainerElement>
        </>
    );
};


const Timer = ({ time, business }: { time: Moment, business: any }) => {
    const timeRef = useRef(time)
    const [secondsRemaining, setSecondsRemaining] = useState(timeRef.current.diff(moment(), 'seconds'))
    const timeLeft = secondsToTime(secondsRemaining);

    if (secondsRemaining > 0) setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
    }, 1000);

    if (secondsRemaining < 1) return (
        <>
            <InfoMessage widgetTheme={business?.theme}>
                Your held slot has timed out. You can still try and book but we cannot guarantee your booking.
            </InfoMessage>
            <br />
        </>
    )

    return (
        <>
            <InfoMessage widgetTheme={business?.theme}>
                Booking is held for <strong>{timeLeft.m}:{(timeLeft.s < 10 ? '0' : '') + timeLeft.s} minutes</strong>
            </InfoMessage>
            <br />
        </>
    );
};

const DropdownBadge = styled(CoreBadge)`
    font-size: 0.6rem;
    margin-right: 0.5rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        margin-right: 0.1rem;
    }
`

const BadgeContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
`

export default BookingModule;

