import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import CoreButton from '../../Forms/Button';
import { WarningMessage, ErrorMessage } from '../../Forms/Messaging';
import BREAKPOINTS from '../../../config/breakpoints';
import {
    Portal,
    Button
} from '@chakra-ui/react';
import {
    DrawerContent,
    DrawerFooter,
    DrawerBody,
    DrawerRoot,
    DrawerHeader,
    DrawerActionTrigger,
    DrawerBackdrop,
    DrawerCloseTrigger,
    DrawerTitle,
    DrawerTrigger
} from '../../ui/drawer';

import {
    DialogActionTrigger,
    DialogBackdrop,
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from "../../ui/dialog"

interface ComponentProps {
    children?: any;
    isOpen?: boolean;
    title?: React.ReactNode;
    noTitleMarginBottom?: boolean;
    actionBar?: React.ReactNode;
    warning?: React.ReactNode;
    error?: React.ReactNode;
    full?: boolean;
    onClose: () => void;
    hasCancel?: boolean;
    small?: boolean;
    cancelButton?: React.ReactNode;
    mediumPanel?: boolean;
    headerTextColor?: string;
    headerBackgroundColor?: string;
    slimPanel?: boolean;
    customWidth?: string;
    noFooterFlex?: boolean;
    contentPadding?: string;
    tall?: boolean;
    noClose?: boolean;
    cancelText?: string;
    contentRef?: React.MutableRefObject<HTMLDivElement>;
}

const ModalWarning = styled(WarningMessage)`
    flex: 1;
    padding: 0.3rem 0.5rem !important;
    margin-top: 0.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    float: left;
    width: auto !important;
    text-align: start !important;
`

const ModalError = styled(ErrorMessage)`
    flex: 1;
    padding: 0.3rem 0.5rem !important;
    margin-top: 0.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    float: left;
    width: auto !important;
    text-align: start !important;
`

const ModalWarningBar = styled.div`
    flex: 1;
`

export const ModalContext = createContext<HTMLDivElement>(null);
export const ModalContentContext = createContext<React.MutableRefObject<HTMLDivElement>>(null);

const customSidebarWidth = '80vw';

const CoreModal = ({
    title,
    actionBar,
    noTitleMarginBottom,
    small,
    tall,
    full,
    contentRef,
    warning,
    error,
    slimPanel,
    noClose,
    mediumPanel,
    headerTextColor,
    headerBackgroundColor,
    noFooterFlex,
    cancelButton,
    customWidth,
    contentPadding,
    children = <></>,
    onClose,
    isOpen = true,
    hasCancel = true,
    cancelText = 'Cancel'
}: ComponentProps) => {
    const context = useContext(ModalContext);
    const portalRef = useRef<HTMLDivElement>(context)
    const drawerRef = useRef<HTMLDivElement>()
    const theme = useTheme();

    if (!isOpen) return null;

    const isMobile = window.innerWidth <= BREAKPOINTS.desktop;

    const getDrawerWidth = () => {
        if (full || isMobile) return '100vw';
        if (slimPanel) return '40vw';
        if (mediumPanel) return '60vw';
        return customSidebarWidth;
    }

    const drawerWidth = getDrawerWidth();

    const handleClose = (details: any) => {
        onClose();
    }

    if (!small || isMobile) return (
        <Portal container={portalRef.current ? portalRef : undefined}>
            <ModalContentContext.Provider value={contentRef || drawerRef}>
                <DrawerRoot defaultOpen onOpenChange={handleClose}>
                    <DrawerBackdrop />
                    <DrawerContent
                        portalled={false}
                        ref={drawerRef}
                        w={drawerWidth}
                        maxW={drawerWidth}
                        backgroundColor={theme.dashboardElevatedBackground}
                    >
                        {!noClose && <DrawerCloseTrigger onClick={onClose} color={headerTextColor} />}
                        <DrawerHeader borderBottom='1px solid #d5d5d5' backgroundColor={headerBackgroundColor} color={headerTextColor}>
                            <ModalTitle textColor={headerTextColor}>{title}</ModalTitle>
                        </DrawerHeader>
                        <DrawerBody
                            paddingTop={noTitleMarginBottom ? 0 : undefined}
                            ref={contentRef}
                            padding={contentPadding}
                        >
                            {children}
                        </DrawerBody>
                        <DrawerFooter borderTop='1px solid #d5d5d5'>
                            {hasCancel && <>
                                {cancelButton ? cancelButton : <CoreButton outline onClick={onClose} floatLeft>{cancelText}</CoreButton>}
                                {!(error || warning) && <Spacer />}
                            </>}
                            {(error || warning) &&
                                <ModalWarningBar>
                                    {error && <ModalError>{error}</ModalError>}
                                    {warning && <ModalWarning>{warning}</ModalWarning>}
                                </ModalWarningBar>
                            }
                            {actionBar}
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerRoot>
            </ModalContentContext.Provider>
        </Portal>
    )

    let modalWidth: "full" | "xl" | "sm" | "md" | "lg" | "xs" | "cover" = 'xl';
    if (isMobile) {
        modalWidth = 'full'
    }  else if (slimPanel) {
        modalWidth = 'sm'
    } else if (mediumPanel) {
        modalWidth = 'xl'
    } else if (full) {
        modalWidth = 'full'
    }

    let minWidth = (slimPanel || mediumPanel) ? undefined : '80vw';
    let maxWidth: string = undefined;

    if (customWidth) {
        minWidth = customWidth;
        maxWidth = customWidth;
    }

    return (
        <Portal container={portalRef.current ? portalRef : undefined}>
            <ModalContentContext.Provider value={contentRef || drawerRef}>
                <DialogRoot defaultOpen size={modalWidth} scrollBehavior='inside' onOpenChange={handleClose} role={noClose ? 'alertdialog' : 'dialog'}>
                    <DialogContent
                        noClose={noClose}
                        portalRef={portalRef}
                        backgroundColor={theme.dashboardElevatedBackground}
                        ref={drawerRef}
                        minW={minWidth}
                        maxW={maxWidth}
                        minH={tall ? '80vh' : undefined}
                    >
                        <DialogHeader borderBottom='1px solid #d5d5d5'>
                            <ModalTitle>
                                {title}
                            </ModalTitle>
                        </DialogHeader>
                        <DialogBody
                            paddingTop={noTitleMarginBottom ? 0 : undefined}
                            ref={contentRef}
                            padding={contentPadding}
                        >
                            {children}
                        </DialogBody>
                        <DialogFooter display={noFooterFlex ? 'block' : undefined} borderTop={children && children.length > 0 ? '1px solid #d5d5d5' : undefined}>
                            {hasCancel &&
                                <DialogActionTrigger asChild>
                                    {cancelButton ? cancelButton : <CoreButton outline floatLeft>{cancelText}</CoreButton>}
                                </DialogActionTrigger>
                            }
                            <ModalWarningBar>
                                {error && <ModalError>{error}</ModalError>}
                                {warning && <ModalWarning>{warning}</ModalWarning>}
                            </ModalWarningBar>
                            {actionBar}
                        </DialogFooter>
                        {!noClose && <DialogCloseTrigger />}
                    </DialogContent>
                </DialogRoot>
            </ModalContentContext.Provider>
        </Portal>
    )
};

const ModalTitle = styled.div<{textColor?: string}>`
    font-size: 1.2rem;
    color: ${(props) => props.textColor || props.theme.primary};
`

const Spacer = styled.span`
    flex: 1;
`

export default CoreModal;