import { DiscoverResult, ErrorResponse, ISdkManagedPaymentIntent, Reader, Terminal, loadStripeTerminal } from '@stripe/terminal-js';
import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../../../../../api/api-connectors';
import { useBusiness } from '../../../../../../hooks/useBusiness';
import styled from 'styled-components';
import { BoxShadowStyleWithHover } from '../../../../../../theme';
import Icon from '../../../../../../components/Media/Icon';
import CoreModal from '../../../../../../components/Layout/CoreModal';
import { Column, Row } from '../../../../../../components/Layout/Grid';
import { StyledCurrencyInput, StyledTextInput } from '../../../../../../theme/input.styles';
import { Badge } from '@chakra-ui/react';
import CoreButton from '../../../../../../components/Forms/Button';
import ActionBox from '../../../../../../components/Forms/Messaging';
import { NotificationService } from '../../../../../../services/NotificationService';
import Loader from '../../../../../../components/Layout/Loader';
import { BaseActiveBooking } from '../../../../../../api/api-definitions';
import { SuccessBox, SuccessIcon } from '.';

interface ComponentProps {
    amount?: number;
    booking: BaseActiveBooking;
    onClose: () => void;
}

const TerminalPayment = ({ amount, booking, onClose }: ComponentProps) => {
    const [businessLoaded, business] = useBusiness();
    const terminal = useRef<Terminal>();
    const [readers, setReaders] = useState<Reader[]>();
    const [connectedReader, setConnectedReader] = useState<Reader>();
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setupStripeTerminal();

        return () => {
            disconnectReader();
        }
    }, [])

    const setupStripeTerminal = async () => {
        const StripeTerminal = await loadStripeTerminal();
        const response = StripeTerminal.create({
            onFetchConnectionToken: fetchConnectionToken,
            onUnexpectedReaderDisconnect: unexpectedDisconnect,
        });
        terminal.current = response;
        // const config = { simulated: true };
        const config = { location: business.stripeReaderLocationId };
        terminal.current.discoverReaders(config).then(discoverResult => {

            if ((discoverResult as ErrorResponse).error) {
                console.log('Failed to discover: ', (discoverResult as ErrorResponse).error);
            } else if ((discoverResult as DiscoverResult).discoveredReaders.length === 0) {
                console.log('No available readers.');
            } else {
                let discoveredReaders = (discoverResult as DiscoverResult).discoveredReaders;
                console.log(discoveredReaders)
                setReaders(discoveredReaders)
            }
        });
    }

    const fetchConnectionToken = async (): Promise<string> => {
        return ApiService.checkout.CreateConnectionToken__GET(business.id).then((response) => {
            return response.info;
        });
    }

    const unexpectedDisconnect = () => {

    }

    const connectToReader = async (reader: Reader) => {
        setLoading(true)
        const connectResult = await terminal.current.connectReader(reader);
        setLoading(false)
        setConnectedReader(reader);
        if ((connectResult as ErrorResponse).error) {
            console.log('Failed to connect: ', (connectResult as ErrorResponse).error);
        } else {
            ApiService.checkout.CreateCardCharge__POST({
                businessId: business.id,
                amount: amount
            }).then(async (response) => {
                // terminal.current.setSimulatorConfiguration({ testCardNumber: '4242424242424242' });
                const result = await terminal.current.collectPaymentMethod(response.clientSecret)
                if ((result as ErrorResponse).error) {
                    // Placeholder for handling result.error
                    NotificationService.Error('Could not process payment')
                } else {
                    const paymentResult: any = await terminal.current.processPayment((result as {
                        paymentIntent: ISdkManagedPaymentIntent;
                    }).paymentIntent)

                    if ((paymentResult as ErrorResponse).error) {
                        NotificationService.Error('Error processing payment')
                        disconnectReader()
                    } else if (paymentResult.paymentIntent) {
                        const paymentIntentId = paymentResult.paymentIntent.id;
                        if (paymentResult.paymentIntent.status == 'requires_confirmation') {
                            ApiService.checkout.ConfirmPaymentIntent__GET(business.id, paymentIntentId).then(() => {
                                completePayment(paymentIntentId)
                                disconnectReader()
                            })
                        } else if (paymentResult.paymentIntent.status == 'requires_capture') {
                            ApiService.checkout.CapturePaymentIntent__GET(business.id, paymentIntentId).then(() => {
                                completePayment(paymentIntentId)
                                disconnectReader()
                            })
                        } else if (paymentResult.paymentIntent.status == 'succeeded') {
                            completePayment(paymentIntentId)
                            disconnectReader()
                        } else {
                            NotificationService.Error('Could not process payment');
                            disconnectReader()
                        }
                    }
                };
            })
        }
    }

    const disconnectReader = () => {
        terminal.current.disconnectReader();
        setConnectedReader(undefined)
    }

    const completePayment = (code: string) => {
        ApiService.bookingPayment.Add__PUT({
            businessId: booking.businessId,
            clientId: booking.clientId,
            bookingId: booking.id,
            id: 0,
            intentId: code,
            setupIntent: false,
            amount,
            currency: business.currencyCode,
            fufilled: true,
            terminalPayment: true
        }).then(() => {
            setConfirmed(true);
        })
    }

    return (
        <>
            {(loading || !readers) && <Loader />}
            {!confirmed && !loading &&
                <Row>
                    {connectedReader &&
                        <ActionBox icon='cash-register' title={connectedReader.label}>
                            <Loader />
                            Waiting for reader payment
                            <br />
                            <br />
                            <CoreButton onClick={disconnectReader}>Cancel payment</CoreButton>
                        </ActionBox>
                    }
                    {!connectedReader && readers &&
                        <>
                            <Column size={12}>
                                Please select a card reader:
                            </Column>
                            {readers.map((reader) => (
                                <>
                                    <Column size={5} onClick={() => connectToReader(reader)}>
                                        <CardSelectionContainer>
                                            <CardReaderIcon>
                                                <Icon name='credit-card-front' />
                                            </CardReaderIcon>
                                            <MachineName>{reader.label}</MachineName>
                                            <CardReaderStatus>
                                                {reader.status == 'online' ? <Badge colorPalette='green'>Online</Badge> : <Badge colorPalette='red'>Offline</Badge>}
                                            </CardReaderStatus>
                                        </CardSelectionContainer>
                                    </Column>
                                    <Column size={2} onClick={() => connectToReader(reader)}>
                                        <ConnectButton full>Send to reader</ConnectButton>
                                    </Column>
                                </>
                            ))}
                        </>
                    }
                </Row>
            }
            {confirmed &&
                <SuccessBox>
                    <SuccessIcon name='check' />
                    <br />
                    <br />
                    <br />
                    Payment confirmed
                    <br />
                    <br />
                    <CoreButton onClick={onClose}>Back to booking</CoreButton>
                </SuccessBox>
            }
        </>
    );
};

const MachineName = styled.div`
    padding: 0 1rem;
    line-height: 3rem;
`;

const ConnectButton = styled(CoreButton)`
    height: 4rem;
    margin: 0;
`

const CardSelectionContainer = styled(BoxShadowStyleWithHover)`
    padding: 0.5rem;
    display: flex;
`

const CardReaderStatus = styled.div`
    padding-top: 0.6rem;
`

const CardReaderIcon = styled.div`
    width: 3rem;
    line-height: 3rem;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.primaryContrast};
    border-radius: 0.2rem;
    text-align: center;
    font-size: 1.5rem;
`

export default TerminalPayment;