import React, { ReactNode } from "react";
import { View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { CaptionAlignEnum } from "./enums";

import { mergeStyles, renderContent, ViewProps } from '../utils';

export interface CaptionProps extends ViewProps {
  align?: CaptionAlignEnum;
  children: ReactNode;
}

export class Caption extends React.PureComponent<CaptionProps> {
  render() {
    const { props } = this;
    const captionStyle: Style = {
      fontWeight: 'bold',
      textAlign: 'center'
    };

    return (
      <View {...props} style={mergeStyles(captionStyle, props.style)}>
        {renderContent(props.children)}
      </View>
    );
  }
}
