import React from "react";

import { Cell, CellProps } from "./Cell";

export interface DataCellProps extends CellProps {
  content: (data: any) => React.ReactNode | JSX.Element | string | number;
  data?: any[];
}

export class DataCell extends React.PureComponent<DataCellProps> {
  render() {
    const { props } = this;
    const { data } = props;
    const cellProps = { ...props };

    delete cellProps.content;
    delete cellProps.data;
    if (!data) {
      console.warn(`Data missing for row ${props.rowIndex + 1}, column ${props.cellIndex + 1}`);
    }

    return data
      ? <Cell {...cellProps}>{props.content(data)}</Cell>
      : <Cell {...cellProps} style={{ color: '#f00', fontSize: 4 }}>data missing</Cell>;
  }
}
