import React from "react";
import { Image } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { IconSizeEnum } from "./enums";

import { DebugProps, mergeStyles, StyleProps } from "../utils";

export interface IconProps extends Omit<DebugProps, 'debugChildren'>, StyleProps {
  size?: IconSizeEnum;
  src: string;
}

class Icon extends React.PureComponent<IconProps> {
  render() {
    const { props } = this;
    const { size = IconSizeEnum.SMALL } = props;
    const iconStyle: Style = {
      maxWidth: size * 12,
      maxHeight: size * 12,
      margin: size * 2
    };

    return (
      <Image cache debug={props.debug} src={props.src} style={mergeStyles(iconStyle, props.style)} />
    );
  }
}

export default Icon;
export { Icon };
