import React, { ReactNode } from "react";
import { View } from '@react-pdf/renderer';

import { Style } from "@react-pdf/types";

import { HAlignEnum } from '../enums';
import { LocationEnum } from "./enums";

import { rowCellMapper } from "./helpers";

import { Row, RowProps } from "./Row";

import { mergeStyles } from "../utils";

export interface HeaderProps extends
  Omit<RowProps, 'cellCount' | 'cellIndex' | 'data' | 'rowCount' | 'rowIndex' | 'width'> {
  /* repeat header should table body span more than one page */
  repeat?: boolean;
}

export class Header extends React.PureComponent<HeaderProps> {
  render() {
    const { props } = this;
    const headerStyle: Style = {};

    return props.children
      ? (<View debug={props.debug} fixed={props.repeat} style={mergeStyles(headerStyle, props.style)}>
        <Row fontWeight={'bold'} {...props} location={LocationEnum.HEADER} rowCount={1} rowIndex={0}>
          {rowCellMapper({ props: { align: HAlignEnum.CENTER, ...props } }) as ReactNode}
        </Row>
      </View>)
      : null;
  }
}
