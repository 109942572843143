import React, { useState } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { InfoMessage } from '../../components/Forms/Messaging';
import { BaseActiveBooking, BaseBookingPayment, BaseBusiness, BaseMenu } from '../../api/api-definitions';
import MenuSelector from '../shared/PageModules/menuSelector';
import { Body, Cell, DataCell, HAlignEnum, Header, Table, VAlignEnum } from '../shared';
import { Row } from '../shared/Table/Row';
import { useBusiness } from '../../hooks/useBusiness';
import { ConvertCurrency } from '../../utils/currency-helper';
import { Constants } from '../../constants';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import { DEFAULT_DATE_FORMAT, formatDate } from '../../utils/date-helpers';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        paddingBottom: 20,
        paddingTop: 20
    },
    wrapper: {
        paddingHorizontal: 10,
    },
    title: {
        padding: 10,
        width: '100%',
        textAlign: 'center',
        fontSize: 20
    },
    address: {
        padding: 10,
        width: '100%',
        textAlign: 'center',
        fontSize: 10
    }
});

interface DocumentProps {
    items: ReceiptItem[];
    booking: BaseActiveBooking;
    receiptNumber: number;
    date: string;
    gratuity?: number;
}

interface ReceiptItem {
    quantity: number;
    label: string;
    amount: number;
    extras: ReceiptItem[];
}

const Receipt = ({ items, booking, gratuity = 0, receiptNumber, date }: DocumentProps) => {
    const [loaded, business] = useBusiness();
    const total = items.reduce((sum, item) => sum + item.amount, 0);

    return (
        <div>
            <PDFViewer style={{ width: '100%', height: '50rem' }}>
                <Document>
                    <Page size={{ width: '302' }} style={styles.page}>
                        <View style={{ paddingHorizontal: 30, fontSize: 12 }}>
                            {!isNullOrWhitespace(business.bookingConfigurationSettings.receiptLogoUrl) ? <Image src={Constants.baseImageUrl + business.bookingConfigurationSettings.receiptLogoUrl} style={{ width: 180, marginLeft: 35 }} /> : <></>}
                            <View style={styles.title}>
                                <Text>{business.name}</Text>
                            </View>
                            <View style={styles.address}>
                                <Text>{business.locationAddress.formattedAddress}</Text>
                                {!isNullOrWhitespace(business?.bookingConfigurationSettings.vatNumber) ?
                                    <Text style={{ marginTop: 5 }}>VAT No: {business?.bookingConfigurationSettings.vatNumber}</Text>
                                 : <></>}
                                 <Text style={{ marginTop: 5 }}>Booking ref: {booking.bookingReference}</Text>
                            </View>
                            
                            <View style={{ borderBottom: '1px solid #000', marginVertical: 10 }}></View>
                            <View style={{ paddingRight: 20, fontSize: 10 }}>
                                <Table cellPadding={4} cols={['10%', '80%', '10%']} data={items}>
                                    <Body vAlign={VAlignEnum.TOP}>
                                        <DataCell content={d => d.quantity} />
                                        <DataCell content={d => d.label} style={{ justifyContent: 'flex-start' }} />
                                        <DataCell content={d => d.amount.toFixed(2)} />
                                    </Body>
                                </Table>
                            </View>
                            <View style={{ borderBottom: '1px solid #000', marginVertical: 10 }}></View>
                            <View style={{ paddingRight: 20 }}>
                                <Table cellPadding={4} cols={['90%', '10%']} data={[
                                    {label: 'SUBTOTAL:', amount: total, fontSize: 10 },
                                    ...(gratuity ? [{label: 'Service charge 12.5%:', amount: gratuity, fontSize: 10 }] : []),
                                    {label: 'TOTAL:', amount: total + (gratuity ?? 0), fontSize: 13 },
                                ]}>
                                    <Body vAlign={VAlignEnum.TOP}>
                                        <DataCell content={d => <Text style={{ fontSize: d.fontSize }}>{d.label}</Text>} style={{ justifyContent: 'flex-start' }} />
                                        <DataCell content={d => <Text style={{ fontSize: d.fontSize }}>{ConvertCurrency({ amount: d.amount, code: business.currencyCode })}</Text>} />
                                    </Body>
                                </Table>
                            </View>
                            <View style={{ fontSize: 10, marginTop: 20 }}>
                                <Table cellPadding={4} cols={['33%', '33%', '33%', '33%']} data={[{}]}>
                                    <Header>
                                        <Cell align={HAlignEnum.CENTER}>Sales - VAT</Cell>
                                        <Cell align={HAlignEnum.CENTER}>VAT Rate</Cell>
                                        <Cell align={HAlignEnum.CENTER}>VAT Amt</Cell>
                                        <Cell align={HAlignEnum.CENTER}>Total</Cell>
                                    </Header>
                                    <Body>
                                        <Cell align={HAlignEnum.CENTER}>{((total) - ((total) * 0.2)).toFixed(2)}</Cell>
                                        <Cell align={HAlignEnum.CENTER}>20%</Cell>
                                        <Cell align={HAlignEnum.CENTER}>{((total) * 0.2).toFixed(2)}</Cell>
                                        <Cell align={HAlignEnum.CENTER}>{(total).toFixed(2)}</Cell>
                                    </Body>
                                </Table>
                            </View>
                            <View style={{ borderBottom: '1px solid #000', marginVertical: 10 }}></View>
                            {gratuity &&
                                <Text style={{ fontSize: 10, marginTop: 10, textAlign: 'center' }}>*An optional service charge has been added to your bill.</Text>
                            }
                            <Text style={{ fontSize: 10, marginTop: 10, textAlign: 'center' }}>Thank you for visiting {business.name}!</Text>
                            <Text style={{ marginTop: 5, fontSize: 10, textAlign: 'center', marginBottom: 20 }}>{formatDate(date, DEFAULT_DATE_FORMAT)} - #{receiptNumber}</Text>
                        </View>
                    </Page>
                </Document>
            </PDFViewer>
        </div>
    )
};

export default Receipt;