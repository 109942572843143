import React from "react";
import { View } from "@react-pdf/renderer";

import { renderContent, ViewProps } from "../utils";

export interface GridItemProps extends ViewProps {}

export class GridItem extends React.PureComponent<GridItemProps> {
  render() {
    const { props } = this;

    return (
      <View {...props} style={props.style} wrap={false}>
        {renderContent(props.children)}
      </View>
    );
  };
}
