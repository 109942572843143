import React, { ReactNode } from "react";
import { View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { TableFrameStyles } from "./constants";

import { HAlignEnum, VAlignEnum } from '../enums';
import { FrameEnum, LocationEnum, RulesEnum } from "./enums";

import { DebugProps, mergeStyles, renderContent, StyleProps } from "../utils";

function fixBorders(props, rules: Style) {
  if ('borderWidth' in props.style) {
    const { borderWidth } = props.style;

    Object.keys(rules).forEach(key => {
      if (rules[key] === 1) {
        rules[key] = borderWidth;
      }
    });
  }
}

function getAllRules(props: CellProps) {
  const { cellIndex, location, rowCount, rowIndex } = props;
  const rules: Array<Style> = [];

  if (location === LocationEnum.FOOTER) {
    rules.push(TableFrameStyles[FrameEnum.ABOVE]);
  } else {
    if (rowIndex < (rowCount - 1)) {
      rules.push(TableFrameStyles[FrameEnum.BELOW]);
    }
  }

  if (cellIndex > 0) {
    rules.push(TableFrameStyles[FrameEnum.LHS]);
  }

  return rules;
}

function getColRules(props: CellProps) {
  const { cellIndex } = props;
  const rules: Array<Style> = [];

  if (cellIndex > 0) {
    rules.push(TableFrameStyles[FrameEnum.LHS]);
  }

  return rules;
}

function getGroupRules(props: CellProps) {
  const { location } = props;
  const rules: Array<Style> = [];

  if (location === LocationEnum.FOOTER) {
    rules.push(TableFrameStyles[FrameEnum.ABOVE]);
  }

  if (location === LocationEnum.HEADER) {
    rules.push(TableFrameStyles[FrameEnum.BELOW]);
  }

  return rules;
}

function getRowRules(props: CellProps) {
  const { location, rowCount, rowIndex } = props;
  const rules: Array<Style> = [];

  if (location === LocationEnum.FOOTER && rowIndex === 0) {
    rules.push(TableFrameStyles[FrameEnum.ABOVE]);
  }

  if (location === LocationEnum.HEADER && rowIndex === (rowCount - 1)) {
    rules.push(TableFrameStyles[FrameEnum.BELOW]);
  }

  if (rowIndex < (rowCount - 1)) {
    rules.push(TableFrameStyles[FrameEnum.BELOW]);
  }

  return rules;
}

function getRules(props: CellProps) {
  const { rules } = props;
  let rule: Array<Style> = [];

  if (rules === RulesEnum.ALL) {
    rule = getAllRules(props);
  }

  if (rules === RulesEnum.COLS) {
    rule = getColRules(props);
  }

  if (rules === RulesEnum.GROUPS) {
    rule = getGroupRules(props);
  }

  if (rules === RulesEnum.ROWS) {
    rule = getRowRules(props);
  }

  return mergeStyles(rule);
}

export interface CellProps extends DebugProps, StyleProps {
  align?: HAlignEnum;
  backgroundColor?: Style['backgroundColor'];
  borderColor?: string;
  borderStyle?: Style['borderStyle'],
  borderWidth?: Style['borderWidth'],
  cellCount?: number;
  cellIndex?: number;
  cellPadding?: Style['padding'];
  color?: Style['color'];
  fontSize?: Style['fontSize'];
  fontWeight?: Style['fontWeight'];
  location?: string;
  rowCount?: number;
  rowIndex?: number;
  rules?: RulesEnum;
  vAlign?: VAlignEnum;
  width?: Style['width'];
  children?: ReactNode;
}

export class Cell extends React.PureComponent<CellProps> {
  render() {
    const { props } = this;
    const rules = getRules(props);

    fixBorders(props, rules);

    const cellStyle: Style = {
      flexBasis: props.width,
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: props.vAlign,
      padding: props.cellPadding,
      borderColor: props.borderColor,
      borderStyle: props.borderStyle,
      backgroundColor: props.backgroundColor,
      color: props.color,
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      textAlign: props.align,
      ...rules
    };
    const propsStyle = { ...mergeStyles(props.style) };

    Object.keys(propsStyle).forEach(key => {
      if (key.startsWith('border')) {
        delete propsStyle[key];
      }
    });

    return (
      <View debug={props.debug} style={mergeStyles(cellStyle, propsStyle)}>
        {renderContent(props.children)}
      </View>
    );
  }
}
