export enum ListTypeEnum {
  NONE = 'none',
  ORDERED = 'ordered',
  UNORDERED = 'unordered'
}

export enum MarkerLocationEnum {
  INSIDE = 'inside',
  OUTSIDE = 'outside'
}

export enum MarkerTypeEnum {
  DECIMAL = 'decimal',
  LOWERALPHA = 'loweralpha',
  LOWERROMAN = 'lowerroman',
  UPPERALPHA = 'upperalpha',
  UPPERROMAN = 'upperroman'
}
