import React from 'react';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Column } from '../../../../components/Layout/Grid';
import { ApiBusinessBookingDetails, BaseDish, ThemeOptions } from '../../../../api/api-definitions';
import TagList from '../../../../components/Cta/TagList';
import { Constants } from '../../../../constants';
import { ConvertCurrency } from '../../../../utils/currency-helper';
import { isNullOrWhitespace } from '../../../../utils/text-helpers';
import { BaseBoxShadowStyleWithHover, BoxShadowStyleWithHover } from '../../../../theme';
import { StyledNumberInput } from '../../../../theme/input.styles';
import { BasketState, FoodItemState } from '..';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { cloneDeep } from 'lodash';

interface ComponentProps {
    dish: BaseDish;
    actionBar?: React.ReactNode;
    currencyCode?: string;
    onClick?: () => void;
    size?: number;
    business: ApiBusinessBookingDetails;
}

const FoodItemHorizontal = ({
    dish,
    onClick,
    actionBar,
    currencyCode,
    business,
    size = 4
}: ComponentProps) => {
    const setBasketState = useSetRecoilState(BasketState);
    const [value, setValue] = useRecoilState(FoodItemState([dish.id, { quantity: 0 }]));
    const imageName = isNullOrWhitespace(dish.imageUrl) ? `url('')` : `url('${Constants.baseImageUrl}${dish.imageUrl}')`;

    const setQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const amount = +e.target.value;
        if (amount < 0) return;
        setBasketState(basket => {
            const current = cloneDeep(basket);
            if (current[dish.id]) {
                current[dish.id].quantity = amount;
            } else {
                current[dish.id] = {
                    quantity: amount
                }
            }
            if (current[dish.id].quantity == 0) {
                delete current[dish.id];
            }
            return current;
        })
        setValue({ quantity: amount })
    }

    const selected = value.quantity > 0;
    const quantity = value;

    return (
        <FoodResultContainer size={size} tablet={6} mobile={12} onClick={onClick} selected={selected} widgetTheme={business?.theme}>
            <ResultInner selected={selected} widgetTheme={business?.theme}>
                {!isNullOrWhitespace(dish.imageUrl) &&
                    <LeftImageContainer>
                        <LeftImage
                            style={{ backgroundImage: imageName }}
                            placeholder={<div></div>}
                            threshold={200}
                        />
                    </LeftImageContainer>
                }
                <Content>
                    <NameItem>
                        {dish.name}
                    </NameItem>
                    <DescriptionItem dangerouslySetInnerHTML={{__html: isNullOrWhitespace(dish.description) ? 'This item has no description.' : dish.description.replaceAll('\\n', '<br />')}} />
                    <ActionBar>
                        {!isNullOrWhitespace(currencyCode) && !isNullOrWhitespace(dish.price) && dish.price > 0 &&
                            <InfoBox widgetTheme={business?.theme}>
                                {!isNullOrWhitespace(dish.calorieCount) &&
                                    <FlexTextLeft>
                                        {dish.calorieCount} kcal
                                    </FlexTextLeft>
                                }
                                <FlexTextRight>
                                    {/* {ConvertCurrency({
                                        amount: dish.price,
                                        code: currencyCode
                                    })} */}
                                    {dish.price}
                                </FlexTextRight>
                            </InfoBox>
                        }
                        {dish.dishTags && <TagList tags={dish.dishTags.map((tag) => tag.tagDescription)} />}
                        {actionBar}
                    </ActionBar>
                    {/* <ActionBar onClick={e => e.stopPropagation()}>
                        <AddToBasketQuantity>
                            <StyledNumberInput value={quantity} hasPlusMinus min={0} onChange={setQuantity} />
                        </AddToBasketQuantity>
                    </ActionBar> */}
                </Content>
            </ResultInner>
        </FoodResultContainer>
    );
};

const LeftImageContainer = styled.div`
    height: 150px;
`

const LeftImage = styled(LazyLoadImage)`
    width: calc(100% - 1rem);
    height: 150px;
    flex-shrink: 0;
    margin-bottom: 1rem;
    border-radius: 0.2rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #FAFAFA;
    margin: 0.5rem;
`;

const Content = styled.div`
    flex: 1;
    padding: 1rem calc(1rem - 6px);
    line-height: 1.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

const NameItem = styled.div`
    font-size: 1rem;
    padding-right: 0.7rem;
    padding-left: 0;
    font-weight: bold;
    letter-spacing: 0.5px;
`;

const DescriptionItem = styled.div`
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    white-space: pre-wrap;
    overflow: hidden;
    color: grey;
`;


const FoodResultContainer = styled(Column) <{ selected?: boolean; widgetTheme: ThemeOptions }>`
    ${BaseBoxShadowStyleWithHover}
    border: 1px solid ${props => props.theme.boxShadowBorder};
    background-color: white;
    border-radius: 0.2rem;
`;

const ResultInner = styled.div<{ selected?: boolean; widgetTheme: ThemeOptions }>`
    ${props => props.selected ? `
        border-left: 6px solid ${props.widgetTheme?.primaryColour || props.theme.secondary};
    ` : `
        border-left: 6px solid white;
    `}
    border-right: 6px solid white;
    display: flex;
    flex-direction: column;
`

const InfoBox = styled.div<{ widgetTheme: ThemeOptions }>`
    display: flex;
    font-size: 0.8rem;
    font-weight: bold;
    border-top: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.secondary};
`

const FlexTextRight = styled.div`
    flex: 1 1 auto;
    text-align: right;
`

const FlexTextLeft = styled.div`
    flex: 1 1 auto;
    text-align: left;
`

const ActionBar = styled.div`
    margin-top: auto;
`

const AddToBasketQuantity = styled.div`
  flex: none;
  width: 100%;
  margin-top: 0.4rem;
`

export default FoodItemHorizontal;