import React, { useEffect, useRef, useState } from 'react';
import { getQueryParams, pushEvent } from '../../../utils/data-helpers';
import { ApiService } from '../../../api/api-connectors';
import { useParams } from 'react-router';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import moment from 'moment';
import { createMomentFromValue } from '../../../utils/date-helpers';
import { ApiBusinessBookingDetails, OrderMenu, OrderMenuResponse } from '../../../api/api-definitions';
import { captureException } from '@sentry/react';
import { NotificationService } from '../../../services/NotificationService';
import { BookingModuleContainer, InfoMessage } from '../../booking.styles';
import Loader from '../../../components/Layout/Loader';
import styled, { createGlobalStyle } from 'styled-components';
import { cloneDeep } from 'lodash';
import AddToBasketModal from './modules/addToBasketModal';
import Basket, { IBasket } from './modules/basket';
import { BoxShadowStyle } from '../../../theme';
import PageContainer from '../../../components/Layout/PageContainer';
import MenuItemList from './modules/menuItemList';
import HeaderBar from './modules/headerBar';
import { atom, atomFamily, useRecoilState } from 'recoil';
import BREAKPOINTS from '../../../config/breakpoints';

interface ComponentProps {
}

export type IBasketItemValues = {
  values: { [key: string]: IBasketItem }
}

export type IBasketItem = {
  quantity: number;
  options?: any[];
  specialRequests?: string;
};

export const BasketState = atom<IBasket>({
  key: 'basketState',
  default: {},
});

export const FoodItemState = atomFamily<IBasketItemValues, [id: number, defaultValue?: IBasketItemValues]>({
  key: 'foodItem',
  default: ([, defaultValue]) => defaultValue ?? { values: {} }
});

const OrderModule = ({ }: ComponentProps) => {
  //const [basket, setBasket] = useRecoilState(BasketState);
  const routeParams: any = useParams();
  const params = getQueryParams();
  const [loading, setLoading] = useState(true)
  const [appError, setAppError] = useState(false)
  const [noBusiness, setNoBusiness] = useState(false)
  const [business, setBusiness] = useState<ApiBusinessBookingDetails>();
  const businessRef = useRef<ApiBusinessBookingDetails>();
  const [hasLoadedFirstData, setHasLoadedFirstData] = useState<boolean>(false);
  const [data, setData] = useState<OrderMenuResponse>();
  const [activeItemId, setActiveItemId] = useState<number>();

  useEffect(() => {
    ApiService.weblocation.Getlocation__GET(routeParams.location, !!routeParams.ref).then(data => {
      if (isNullOrWhitespace(data?.name)) {
        setNoBusiness(true)
      } else {
        moment.tz.setDefault(data.timeZone);
        if (data.timeSlotsPerDay?.timeSlots) {
          const todaysSlots = data.timeSlotsPerDay.timeSlots[createMomentFromValue(moment()).format('dddd')];
          //data.theme.primaryColour = '#699691';
          setBusiness(data)
          businessRef.current = data;
          document.body.style.backgroundColor = data.theme.backgroundColour;
          document.body.style.color = data.theme.textColour;
          var css = `body { 
            background-color: ${data.theme.backgroundColour || '#EFEFEF'} !important;
            /* ===== Scrollbar CSS ===== */
            /* Firefox */
            *, body {
                scrollbar-width: thin !important;
                scrollbar-color: ${data.theme.primaryColour || '#000'} ${data.theme.backgroundColour || '#FFF'} !important;
            }

            /* Chrome, Edge, and Safari */
            *::-webkit-scrollbar, body::-webkit-scrollbar {
                width: thin !important;
                z-index: 10 !important;
            }

            *::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
                background: ${data.theme.primaryColour || '#000'} !important;
            }

            *::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
                background-color: ${data.theme.primaryColour || '#000'} !important;
                border-radius: 5px !important;
            }
          }`,
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

          head.appendChild(style);
          // @ts-ignore
          style.type = 'text/css';
          // @ts-ignore
          if (style.styleSheet) {
            // This is required for IE8 and below.
            // @ts-ignore
            style.styleSheet.cssText = css;
          } else {
            style.appendChild(document.createTextNode(css));
          }
          setHasLoadedFirstData(true)
          if (isNullOrWhitespace(params.setup_intent) && isNullOrWhitespace(params.payment_intent)) {

            setLoading(false)
          } else {
            // pay done
          }
        } else {
          pushEvent('orderAppLoadError', { 'locationId': routeParams.location })
          setAppError(true)
        }
      }
    }).catch((e) => {
      captureException(e);
      NotificationService.Error('Sorry, there has been an error loading the application.')
    })
    ApiService.webordermenu.GetMenu__GET(routeParams.location).then((response) => {
      setData(response)
    })
  }, [])

  if (appError) return (
    <BookingModuleContainer widgetTheme={business?.theme}>
      <InfoMessage widgetTheme={business?.theme}>
        Sorry, we encountered an error and are unable to load the booking application. Please reload and try again.
      </InfoMessage>
    </BookingModuleContainer>
  )

  if (noBusiness) return (
    <BookingModuleContainer widgetTheme={business?.theme}>
      <InfoMessage widgetTheme={business?.theme}>
        Sorry, we could not find the specified business.
      </InfoMessage>
    </BookingModuleContainer>
  )

  if (!hasLoadedFirstData) return (
    <BookingModuleContainer widgetTheme={business?.theme}>
      <Loader />
    </BookingModuleContainer>
  )

  const selectedItem = data && activeItemId ? data.dishes[activeItemId] : undefined;

  return (
    <>
      <PageStyle />
      {activeItemId &&
        <AddToBasketModal
          selectedItem={selectedItem}
          onClose={() => setActiveItemId(undefined)}
          business={business}
        />
      }
      <HeaderBar
        sections={data?.menus[0].sections}
        business={business}
        data={data}
      />
      <PageContainer>
        {data?.menus && data.menus.length > 0 &&
          <MenuItemList
            data={data}
            menu={data.menus[0]}
            setActive={setActiveItemId}
            business={business}
          />
        }
      </PageContainer>
    </>
  )
};


const PageStyle = createGlobalStyle`
  .chakra-input__left-addon {
        border-color: #aeaeae !important;
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
      button, input {
        height: 3rem !important;
        font-size: 1rem !important;
      }

      .chakra-input__right-element button, .chakra-input__left-element button {
        height: 2.5rem !important;
        top: 0.25rem;
      }
    }
`

export default OrderModule;