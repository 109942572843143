import React, { ReactNode } from "react";

import { HAlignEnum, VAlignEnum } from '../enums';
import { LocationEnum, RulesEnum } from "./enums";

import { DataCell, DataCellProps } from "./DataCell";

function getCell(rowCell: React.ReactElement, cellIndex: number) {
  const props = this;
  const { cols = [], location = LocationEnum.BODY } = props;
  const { props: rowCellProps } = rowCell;

  const cellProps: Omit<DataCellProps, 'content'> = {
    align: rowCellProps.align ?? props.align ?? HAlignEnum.LEFT,
    backgroundColor: rowCellProps.backgroudColor ?? props.backgroundColor ?? 'transparent',
    borderColor: rowCellProps.borderColor ?? props.borderColor,
    borderStyle: rowCellProps.borderStyle ?? props.borderStyle,
    cellCount: props.length,
    cellIndex,
    cellPadding: rowCellProps.cellPadding ?? props.cellPadding ?? 0,
    color: rowCellProps.color ?? props.color,
    debug: rowCellProps.debug ?? false,
    fontSize: rowCellProps.fontSize ?? props.fontSize,
    fontWeight: rowCellProps.fontWeight ?? props.fontWeight,
    location,
    rowCount: props.rowCount ?? 1,
    rowIndex: props.rowIndex ?? 0,
    rules: rowCellProps.rules ?? props.rules ?? RulesEnum.NONE,
    style: rowCellProps.style ?? props.style ?? {},
    vAlign: rowCellProps.vAlign ?? props.vAlign ?? VAlignEnum.MIDDLE,
    width: cols[cellIndex] ?? 0
  };

  if (rowCell.type === DataCell) {
    cellProps.data = props.data;
  }

  return React.cloneElement(rowCell, {
    key: `${location}_column_${cellIndex}`,
    ...cellProps
  });
}

export function rowCellMapper(config: any): ReactNode {
  const { props } = config;
  const rowCells: Array<any> = React.Children.toArray(props.children);

  return rowCells.map(getCell.bind(props));
}
