import React, { ReactNode } from "react";
import { Text } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

export type DebugProps = {
  debug?: boolean;
  debugChildren?: boolean;
};

export type StyleProps = {
  style?: Style | Array<Style>;
};

export interface ViewProps extends DebugProps, StyleProps {
  break?: boolean;
  fixed?: boolean;
  wrap?: boolean;
  children?: ReactNode;
}

export const DEFAULTVIEWPROPS: ViewProps = {
  break: false,
  debug: false,
  debugChildren: false,
  fixed: false,
  style: null,
  wrap: false
};

function isPrimitive(val: unknown) {
  const type = typeof val;

  return type === 'boolean' || type === 'number' || type === 'string';
}

export function mergeStyles(baseStyles: StyleProps['style'] = {}, extendStyles: StyleProps['style'] = []): Style {
  const styles: Style = {};
  const base = Array.isArray(baseStyles) ? baseStyles : [baseStyles];

  base.reduce((obj, entry) => Object.assign(obj, entry), styles);

  const extended = Array.isArray(extendStyles) ? extendStyles : [extendStyles];

  extended.reduce((obj, entry) => Object.assign(obj, entry), styles);

  return styles;
}

function mapRenderContent(child: any, index: number) {
  const debug = this;
  const key = `child_${index}_${Math.floor(Math.random() * 1e3)}`;

  if (isPrimitive(child)) {
    return (<Text key={key} debug={debug} style={child.style}>{child.toString()}</Text>);
  }

  return child.content
    ? (<Text key={key} debug={debug} style={child.style}>{renderContent(child.content)}</Text>)
    : (<React.Fragment key={key}>{renderContent(child, debug)}</React.Fragment>);
}

export function renderContent(children: any, debug?: boolean) {
  if (isPrimitive(children)) {
    return (<Text key={`child_${Math.floor(Math.random() * 1e3)}`} debug={debug}>{children.toString()}</Text>);
  }

  if (Array.isArray(children)) {
    const valid = children.filter(Boolean);

    if (valid.every(isPrimitive)) {
      return (
        <Text debug={debug}>
          {valid.reduce((value, child) => value + child.toString(), '')}
        </Text>
      );
    }

    return valid.map(mapRenderContent.bind(debug));
  }

  return children;
}

export function setDefaultProps({ defaultProps, element, props }) {
  return Object.keys(defaultProps).reduce((obj, key) => {
    const value = element.props && element.props[key];

    obj[key] = value ?? props[key] ?? defaultProps[key];;

    return obj;
  }, {});
}
