import React, { ReactNode } from "react";
import { View } from "@react-pdf/renderer";

import { Style } from "@react-pdf/types";

import { DataCellProps } from "./DataCell";

import { ZebraColours } from "./enums";

import { rowCellMapper } from "./helpers";
import { mergeStyles } from "../utils";

export interface RowProps extends ZebraProps,
  Omit<DataCellProps, 'content' | 'width'> {
  cols?: Array<string | number>;
  data?: any;
  children?: ReactNode;
}

export interface ZebraProps {
  zebra?: boolean;
  zebraEven?: string | ZebraColours;
  zebraOdd?: string | ZebraColours;
}

export class Row extends React.PureComponent<RowProps> {
  render() {
    const { props } = this;
    const rowStyle: Style = {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'stretch'
    };

    if (props.zebra) [
      rowStyle.backgroundColor = props.rowIndex % 2 === 0
        ? props.zebraEven ?? ZebraColours.EVEN
        : props.zebraOdd ?? ZebraColours.ODD
    ];

    const cells: ReactNode = rowCellMapper({ props }) as ReactNode;

    return (
      <View debug={props.debug} style={mergeStyles(rowStyle, props.style)} wrap={false}>
        {cells}
      </View>
    );
  }
}
